import React from 'react';
import UserProfileModelFormatter from '@uz/unitz-models/UserProfileModel/formatter';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import _ from 'lodash';

export const MessageAuthor = ({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const author = _.get(message, 'author');
  if (!author) return null;
  return (
    <div className="font-semibold text-sm text-main">
      {UserProfileModelFormatter.displayName()({ profile: author })}
    </div>
  );
};

export default MessageAuthor;
