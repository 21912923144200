import _ from 'lodash';
import Glightbox from '@uz/unitz-components-web/Glightbox';

import { filesizeLabel } from '@vl/mod-utils/file';
import * as mineTypes from '@vl/mod-utils/mimetype';

const BlockFileBlot = {
  createHTML(value) {
    const src = _.get(value, 'src');
    const name = _.get(value, 'name');
    const size = _.get(value, 'size');
    const type = _.get(value, 'type');
    const containerStyle =
      'width: 100%; background: #FFFFFF;border: 1px solid #BFCDD9; border-radius: 8px; padding: 8px; display: flex;';
    let fileHtmlTag;
    let viewerSrc = src;
    let thumbName = `thumbs/${type}.png`;
    let newSlide;
    if (_.get(mineTypes.googleViewer, ['types', type])) {
      viewerSrc = `https://docs.google.com/viewer?url=${encodeURIComponent(src)}&embedded=true`;
      fileHtmlTag = `<a class="glightbox" data-width="90%" data-height="100vh" href="${viewerSrc}" alt=${name} style="${containerStyle}">`;
      thumbName = _.get(mineTypes.googleViewer, ['types', type, 'thumb']);
      newSlide = {
        href: viewerSrc,
        type: 'iframe',
        height: '100vh',
      };
    } else if (_.get(mineTypes.msViewer, ['types', type])) {
      // viewerSrc = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(src)}`;
      const fileurl = encodeURIComponent(`${src}?convert=pdf`);
      viewerSrc = `https://unitz-asset.web.app/pdf/web/viewer.html?v=2021122829&file=${fileurl}`;
      fileHtmlTag = `<a class="glightbox" data-width="90%" data-height="100vh" href="${viewerSrc}" alt=${name} style="${containerStyle}">`;
      thumbName = _.get(mineTypes.msViewer, ['types', type, 'thumb']);
      newSlide = {
        href: viewerSrc,
        type: 'iframe',
        height: '100vh',
      };
    } else if (_.get(mineTypes.unitzViewer, ['types', type])) {
      viewerSrc = `https://unitz-asset.web.app/pdf/web/viewer.html?v=2021122829&file=${encodeURIComponent(src)}`;
      fileHtmlTag = `<a class="glightbox" data-width="90%" data-height="100vh" href="${viewerSrc}" alt=${name} style="${containerStyle}">`;
      thumbName = _.get(mineTypes.msViewer, ['types', type, 'thumb']);
      newSlide = {
        href: viewerSrc,
        type: 'iframe',
        height: '100vh',
      };
    } else if (_.get(mineTypes.videoViewer, ['types', type])) {
      viewerSrc = `${src}`;
      fileHtmlTag = `<a class="glightbox" data-width="90%" data-height="100vh" data-type="video" data-source="local" href="${viewerSrc}" alt=${name} style="${containerStyle}">`;
      thumbName = _.get(mineTypes.videoViewer, ['types', type, 'thumb']);
      console.log('viewerSrcviewerSrc', viewerSrc);
      newSlide = {
        href: viewerSrc,
        type: 'video',
        source: 'local',
        height: '100vh',
      };
    } else if (_.get(mineTypes.audioViewer, ['types', type])) {
      viewerSrc = `${src}`;
      fileHtmlTag = `<a class="glightbox" data-width="90%" data-height="100vh" data-type="video" data-source="local" href="${viewerSrc}" alt=${name} style="${containerStyle}">`;
      thumbName = _.get(mineTypes.videoViewer, ['types', type, 'thumb']);
      console.log('viewerSrcviewerSrc', viewerSrc);
      newSlide = {
        href: viewerSrc,
        type: 'video',
        source: 'local',
        height: '100vh',
      };
    } else {
      fileHtmlTag = `<a download href="${src}" alt=${name} style="${containerStyle}" target="_blank" onclick="GLightBox.download(event, '${src}', '${name}')">`;
    }

    const thumbHtml = `<div style="background-image: url(https://unitz-asset.web.app/${thumbName}); width: 32px; height: 32px; border: 1px solid gray; border-radius: 4px; background: gray;"></div>`;
    const filenameHtml = `<div style="font-size: 14px; color: #263340;">${name}</div>`;
    const sizeHtml = `<div style="font-size: 12px; color: #577590;">${filesizeLabel(size)}</div>`;
    const downloadBtn = `<button type="submit" onclick="GLightBox.download(event, '${src}', '${name}')">Download</button>`;
    const downLoadHtml = `<div style="font-size: 12px; color: #577590; margin: 0 1rem;">${downloadBtn}</div>`;
    const fileinfoHtml = `<div style="display: flex;">${sizeHtml}${downLoadHtml}</div>`;
    const fileWrapperHtml = `<div style="display: flex; flex-direction: column; padding: 0 8px;">${filenameHtml}${fileinfoHtml}</div>`;
    // render viewer by type
    const html = `${fileHtmlTag}${thumbHtml}${fileWrapperHtml}</a>`;

    setTimeout(() => {
      const instance = Glightbox.getInstance();
      if (instance && newSlide) {
        instance.insertSlide(newSlide);
      }
      Glightbox.apply();
    }, 100);
    return html;
  },

  renderCustomWith(customOp) {
    const { value } = customOp.insert;
    if (!value) return '';
    const id = _.get(value, 'id');
    return `<div id="${id}" style="position: relative;">${BlockFileBlot.createHTML(value)}</div>`;
  },

  register(registerRender) {
    registerRender(BlockFileBlot.blotName, BlockFileBlot.renderCustomWith);
  },
};

BlockFileBlot.blotName = 'block-file';
BlockFileBlot.className = 'block-file';
BlockFileBlot.tagName = 'div';

export default BlockFileBlot;
