const config = (fabric) => {
  fabric.Eraser = fabric.util.createClass(fabric.Rect, {
    type: 'eraser',
    isToolbar: true,
    isToolbarActive: false,
    isDrawingMode: false,
    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        isToolbar: this.get('isToolbar'),
        isToolbarActive: this.get('isToolbarActive'),
        isDrawingMode: this.get('isDrawingMode'),
        owner_id: false,
      });
    },
  });

  fabric.Eraser.fromObject = function(object, callback) {
    callback(new fabric.Rect(object));
  };
};

export default config;
