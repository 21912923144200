import React from 'react';
import _ from 'lodash';
import cx from 'classnames';

import MessageModelRenderer from '@uz/unitz-models/MessageModel/renderer';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import MessageHeader from '@uz/unitz-tool-components/Message/Components/MessageHeader';
import MessageTimer from '@uz/unitz-tool-components/Message/Components/MessageTimer';
import MessageAuthor from '@uz/unitz-tool-components/Message/Components/MessageAuthor';
import MessageStatus from '@uz/unitz-tool-components/Message/Components/MessageStatus';
import MessageReaction from '@uz/unitz-tool-components/Message/Components/MessageReaction';
import MessageReply from '@uz/unitz-tool-components/Message/Components/MessageReply';
import MessageActions from '@uz/unitz-tool-components/Message/Components/MessageActions';
import MessageStartDay from '@uz/unitz-tool-components/Message/Components/MessageStartDay';
import { Popover } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomMessage = styled.div`
  div,
  p {
    color: ${gstyles.colors.main};
  }
`;

export const MessageItem = React.memo(({ message_id, lastItemRef }) => {
  const { message, isStartOfDay } = MessageStore.use(message_id);
  let containerRef = React.useRef();
  if (lastItemRef) {
    containerRef = lastItemRef;
  }
  React.useEffect(() => {
    MessageStore.setItemSize(message_id, containerRef.current.clientHeight);
  }, [containerRef.current]);
  const [isEditing] = MessageStore.useMessageState(message_id, 'editing', false);
  const [isReplying] = MessageStore.useMessageState(message_id, 'replying', false);
  if (_.get(message, 'target_type') === 'reply') return null;
  const hasAction = !!_.get(message, 'created_at');
  return (
    <Popover
      content={hasAction ? <MessageActions message_id={message_id} /> : null}
      arrowContent={null}
      align={{
        points: ['br', 'tr'], // align dropdown bottom-left to top-left of input element
        offset: [-24, 32], // align offset
        overflow: {
          adjustX: 0,
          adjustY: 0, // do not auto flip in y-axis
        },
      }}
      trigger={['hover']}
      overlayClassName="message-item-tooltip"
      mouseEnterDelay={0.4}
      getPopupContainer={() => {
        if (containerRef.current) return containerRef.current;
        return document.body;
      }}
    >
      <div
        ref={containerRef}
        className={cx('px-4 py-2 hover:bg-brandb-lightest relative', { 'bg-blue-100': isEditing || isReplying })}
      >
        {isStartOfDay && <MessageStartDay message_id={message_id} />}
        <div className="table-cell align-top">
          <MessageHeader message_id={message_id} />
        </div>
        <div className="table-cell pl-3 align-top">
          <div className="flex items-center">
            <MessageAuthor message_id={message_id} />
            <MessageTimer message_id={message_id} />
          </div>
          <div>
            {_.get(message, 'deleted') ? (
              <MessageStatus.Removed />
            ) : (
              <>
                <CustomMessage>{MessageModelRenderer.renderMessage()(message)}</CustomMessage>
                <MessageStatus.Edited message_id={message_id} />
              </>
            )}
          </div>
          <div>
            <MessageReaction message_id={message_id} />
            <MessageReply message_id={message_id} />
          </div>
        </div>
      </div>
    </Popover>
  );
});

export default MessageItem;
