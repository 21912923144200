import styled from 'styled-components';
import Button from '@uz/unitz-components-web/Button';

const ActionButton = styled(Button)`
  &.ant-btn {
    padding: 5px;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    &[disabled] {
      background: none;
      opacity: 0.5;
      &:hover {
        background: none;
      }
    }
  }
`;

export default ActionButton;
