import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { getREF } from '@uz/unitz-providers/RefProvider';

const messageMap = new Map();
const messageSizeMap = new Map();
const startDayIndexes = {};

const DAY_FORMAT = 'YYYY-MM-DD';

const MessageStore = {
  listeners: {},
  on: (message_id, cb) => {
    _.update(MessageStore.listeners, [message_id], (items) => {
      const rtn = items || [];
      rtn.push(cb);
      return rtn;
    });
    return () => {
      const listeners = _.get(MessageStore.listeners, [message_id]);
      const foundIndex = listeners.indexOf(cb);
      if (foundIndex >= 0) {
        listeners.splice(foundIndex, 1);
      }
    };
  },
  emit: (message_id) => {
    const listeners = _.get(MessageStore.listeners, [message_id]);
    if (listeners) {
      for (const listener of listeners) {
        listener && listener();
      }
    }
  },
  use: (message_id) => {
    const ref = React.useRef({});
    const [v, $v] = React.useState(0);

    _.assign(ref.current, { v, $v });
    const message = messageMap.get(message_id);
    // subscribe for the change
    React.useEffect(() => {
      ref.current.disposer = MessageStore.on(`${message_id}`, () => {
        ref.current.$v && ref.current.$v(ref.current.v + 1);
      });
      return () => {
        ref.current.disposer && ref.current.disposer();
        ref.current = {};
      };
    }, [message_id]);

    // check start of day
    const startOfDayId = _.get(startDayIndexes, [
      moment(_.get(message, 'created_at'))
        .startOf('day')
        .format(DAY_FORMAT),
      'id',
    ]);

    // check canEdit
    const canEdit = React.useMemo(() => {
      const authModel = getREF().getRef('authModel');
      if (authModel) {
        return authModel.getUserId() === _.get(message, 'author.user_id');
      }
      return false;
    }, [message_id]);

    const canReply = true;
    const canReaction = true;
    const canPin = true;
    const canSetTopic = true;
    const canSetQuestion = true;

    const isStartOfDay = startOfDayId === message_id;
    return {
      message,
      isStartOfDay,
      canEdit,
      canReply,
      canReaction,
      canPin,
      canSetTopic,
      canSetQuestion,
    };
  },

  set: (message_id, message) => {
    const oldMessage = messageMap.get(message_id);
    if (!_.isEqual(oldMessage, message)) {
      MessageStore.emit(message_id);
    }
    messageMap.set(message_id, message);
    // update start day message id
    const { created_at, target_type } = message;
    if (target_type !== 'reply') {
      const $created_at = moment(created_at);
      const startOfDay = $created_at
        .clone()
        .startOf('day')
        .format(DAY_FORMAT);
      _.update(startDayIndexes, [startOfDay], (item) => {
        if (item) {
          if (moment(_.get(item, 'created_at')).isAfter($created_at)) {
            return message;
          }
          return item;
        }
        return message;
      });
    }
    // cache replies messages
    const replies = _.get(message, 'replies', []);
    for (const replyMsg of replies) {
      const replyMsgId = _.get(replyMsg, 'id');
      MessageStore.set(replyMsgId, replyMsg);
    }
    return message;
  },

  get: (message_id) => {
    return messageMap.get(message_id);
  },

  has: (message_id) => {
    return messageMap.has(message_id);
  },

  messageState: {},
  useMessageState: (message_id, stateName, initState) => {
    const ref = React.useRef({});
    initState = _.get(MessageStore.messageState, [stateName, message_id, 0], initState) || initState;
    const [v, $v] = React.useState(initState);
    const stateUpdater = React.useCallback((newState) => {
      _.set(MessageStore.messageState, [stateName, message_id, 0], newState);
      ref.current.$v && ref.current.$v(newState);
    }, []);

    _.assign(ref.current, { v, $v });

    const state = React.useMemo(() => {
      _.update(MessageStore.messageState, [stateName, message_id], (curState) => {
        curState = curState || [
          ref.current.v,
          (newVal) => {
            _.map(_.get(MessageStore.messageState, [stateName, message_id, 2]), (cb) => cb(newVal));
          },
          [],
        ];
        curState[2].push(stateUpdater);
        return curState;
      });
      return _.get(MessageStore.messageState, [stateName, message_id]);
    }, []);
    React.useEffect(() => {
      return () => {
        const foundIndex = _.indexOf(state[2], stateUpdater);
        if (foundIndex >= 0) {
          state[2].splice(foundIndex, 1);
        }
        ref.current = {};
      };
    }, []);
    return [
      _.get(MessageStore.messageState, [stateName, message_id, 0]),
      _.get(MessageStore.messageState, [stateName, message_id, 1]),
    ];
  },

  setItemSize: (message_id, size) => {
    messageSizeMap.set(message_id, size);
  },
  getItemSize: (message_id, def) => {
    if (messageSizeMap.has(message_id)) {
      return messageSizeMap.get(message_id);
    }
    return def;
  },
  hasItemSize: (message_id) => {
    return messageSizeMap.has(message_id);
  }
};

export default MessageStore;
