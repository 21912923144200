const MarkBlot = {
  createHTML(value) {
    const message_mark_style = `padding: 4px 6px; border-radius: 28px; background: #e6ebf0; margin-right: 8px; display: inline-block; cursor: pointer; color: #577590;`;
    return `<mark class="${MarkBlot.className}" title="${value}" style="${message_mark_style}">${value}</mark>`;
  },

  renderCustomWith(customOp) {
    const { value } = customOp.insert;
    if (!value) return '';
    return MarkBlot.createHTML(value);
  },

  register(registerRender) {
    registerRender(MarkBlot.blotName, MarkBlot.renderCustomWith);
  },
};

MarkBlot.blotName = 'mark';
MarkBlot.className = 'message-mark';
MarkBlot.tagName = 'mark';

export default MarkBlot;
