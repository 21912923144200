import React from 'react';
import { bindings, hook } from '@vl/redata';

import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

const bindData = bindings({
  roomMessagePage: {
    rules: [
      [
        'data',
        {
          data: {
            messageControlModel: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const [visible, $visible] = useLocalStorage.partial(
                '@UZ::LayoutRoomCall::ContentStart',
                {
                  visible: false,
                },
                'visible'
              );

              const [fullInput, $fullInput] = React.useState(false);
              const [contents, $contents] = React.useState('');
              const room_id = _.get(routeParams, 'id');
              const [target_id, $target_id] = React.useState(room_id);
              const ref = React.useRef({});
              _.assign(ref.current, {
                visible,
                $visible,
                fullInput,
                $fullInput,
                contents,
                $contents,
                target_id,
                $target_id,
                room_id,
              });

              const model = {
                contents,
                visible,
                target_type: 'course_room',
                target_id,
                room_id,
                fullInput,
                changeRoom: (room_id) => {
                  ref.current.$target_id(room_id);
                },
                getRoomData: (room_id) => {
                  room_id = room_id || model.target_id;
                  const roomData = _.find(
                    [
                      ...ctx.get('courseRoomData.course.passed_rooms', []),
                      ...ctx.get('courseRoomData.course.upcomming_rooms', []),
                    ],
                    { id: room_id }
                  );
                  return roomData;
                },
                toggle: () => {
                  ref.current.$visible(!ref.current.visible);
                },
                show: () => {
                  ref.current.$visible(true);
                },
                hide: () => {
                  ref.current.$visible(false);
                },
                toggleFullInput: () => {
                  const messageInputRef = ctx.apply('REF.getRef', 'messageInputRef');
                  let _contents = '';
                  if (messageInputRef) {
                    const quill = messageInputRef.current.getQuillRef();
                    if (quill) {
                      _contents = quill.getContents();
                    }
                  }
                  ref.current.$contents(_contents);
                  ref.current.$fullInput(!ref.current.fullInput);
                },
              };
              ctx.apply('REF.setRef', 'messageControlModelRef', model);
              return model;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
