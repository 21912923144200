import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

import GetReviewTag from 'unitz-gql/app_review_tag/GetReviewTag.gql';

graphme.GqlBuilder.loadDocument({
  GetReviewTag,
});

class AppReviewTagModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'AppReviewTagModel',
    schema: {
      id: String,
      score: Number,
    },

    key: 'id',

    baseQuery: 'GetReviewTag',

    GQL_ACTIONS: {
      FIND: 'app_review_tag',
      GET: 'app_review_tag_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static async addReview(object) {
    const query = hasuraClient.gql`
      mutation addReview($object: app_review_insert_input! ) {
        review: insert_app_review_one(object: $object, on_conflict: {constraint: app_review_pkey, update_columns: score}) {
          id
          score
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        object,
      });

      const data = _.get(rtn, 'review.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ AppReviewTagModel });

export default AppReviewTagModel;
