import React from 'react';
import _ from 'lodash';
import useForceUpdate from '@vl/hooks/useForceUpdate';

const STORE_MAP = new Map();

const useReactiveStore = (name) => {
  if (!STORE_MAP.has(name)) {
    const store = (() => {
      const state = {};
      const listeners = new Set();
      const store = {
        set: (...args) => {
          if (args.length === 1) {
            _.merge(state, args[0]);
          } else {
            _.set(state, ...args);
          }
          // side effect on state change
          store.emit('changed');
        },
        get: (...args) => {
          if (args.length === 0) {
            return state;
          }
          return _.get(state, ...args);
        },
        reduce: (fn) => {
          const value = fn(store);
          const ref = React.useRef({});
          const forceUpdate = useForceUpdate(13);
          _.assign(ref.current, { value });
          if (ref.current.disposer) {
            ref.current.disposer();
            ref.current.disposer = null;
          }
          // auto bind listener
          if (!ref.current.disposer) {
            ref.current.disposer = store.on('changed', () => {
              const newValue = fn(store);
              const oldValue = ref.current.value;
              if (!_.isEqual(newValue, oldValue)) {
                ref.current.value = newValue;
                forceUpdate();
              }
            });
          }
          React.useEffect(() => {
            return () => {
              ref.current.disposer();
            };
          }, []);
          return ref.current.value;
        },
        emit: (evt) => {
          for (const listener of Array.from(listeners)) {
            listener(evt, store);
          }
        },
        on: (evt, listener) => {
          listeners.add(listener);
          return () => {
            listeners.delete(listener);
          };
        },
      };
      return store;
    })();
    STORE_MAP.set(name, store);
  }
  const store = STORE_MAP.get(name);

  return [store];
};

export default useReactiveStore;
