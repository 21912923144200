import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import Combokeys from 'combokeys';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 2px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const target = {};
    return target;
  });
  const ref = React.useRef({ disposers: [] });

  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return false;
  }, []);

  React.useEffect(() => {
    disposerCleaner(
      (() => {
        const combokeys = new Combokeys(window);

        combokeys.bind('esc', () => {
          if (canvas.isMoveMode) {
            canvas.toggleMoveMode(false);
            canvas.fire('mode:changed', {
              mode: 'move',
              value: !!canvas.isMoveMode,
              source: 'move',
            });

            forceUpdate();
          }
          return false;
        });

        return () => {
          combokeys.detach();
        };
      })()
    );
    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        const { mode } = event;
        if (mode !== 'move' && canvas.isMoveMode) {
          // canvas.defaultCursor = 'default';
          canvas.isMoveMode = false;
        }
        if (canvas.isOverlay && mode !== 'move') {
          canvas.isMoveMode = true;
          canvas.setMoveMode(canvas.isMoveMode);
        }
        forceUpdate();
      })
    );
  }, []);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  React.useEffect(() => {
    if (canvas.isMoveMode) {
      canvas.defaultCursor = 'default';
      // gstyles.Icons.getCursor({
      //   name: 'cursor',
      //   fill: gstyles.colors.black50,
      // }).then((cursor) => {
      //   const textCursor = `url(${cursor}) 8 8, pointer`;
      //   canvas.defaultCursor = textCursor;
      // });
    }
  }, [canvas.isMoveMode]);

  return (
    <MenuItem
      type={canvas.isMoveMode ? 'primary' : 'text'}
      getPopupContainer={(node) => node && node.parentElement}
      visible={ref.current.visible}
      trigger={['click']}
      onClick={() => {
        canvas.toggleMoveMode(!canvas.isMoveMode);
        canvas.fire('mode:changed', {
          mode: 'move',
          value: !!canvas.isMoveMode,
          source: 'move',
        });
        if (canvas.isOverlay && !canvas.isMoveMode) {
          canvas.discardActiveObject().renderAll();
        }
      }}
      overlay={() => {
        return <div className="flex flex-row bg-white w-full"></div>;
      }}
      buttonsRender={([leftButton, rightButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.moveTooltip')}</span>
            </div>
          }
          key="leftButton"
        >
          {leftButton}
        </Tooltip>,
        rightButton,
      ]}
      icon={<div className="flex justify-center"></div>}
      placement="topLeft"
    >
      <span>{gstyles.icons({ name: 'cursor', size: 20, fill: gstyles.colors.white500 })}</span>
    </MenuItem>
  );
};

export default displayName(Index);
