import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import {
  isBrowser, addScrollListener, removeScrollListener
} from './domUtils';

export function hasScrollBottom(target, initState) {
  const [isScrollBottom, $isScrollBottom] = useState(initState);
  const [isScrollTop, $isScrollTop] = useState(initState);
  const targetToUse = target;

  const ref = useRef({});
  _.assign(ref.current, { $isScrollBottom, $isScrollTop });
  useEffect(() => {
    if (isBrowser() && targetToUse) {
      const handleScroll = () => {
        if (Math.abs(targetToUse.scrollHeight - targetToUse.scrollTop - targetToUse.clientHeight) <= 2) {
          ref.current.$isScrollBottom && ref.current.$isScrollBottom(true);
        } else {
          ref.current.$isScrollBottom && ref.current.$isScrollBottom(false);
        }
        if (targetToUse.scrollTop === 0) {
          ref.current.$isScrollTop && ref.current.$isScrollTop(true);
        } else {
          ref.current.$isScrollTop && ref.current.$isScrollTop(false);
        }
      };

      addScrollListener(handleScroll, targetToUse);
      return () => {
        removeScrollListener(handleScroll, targetToUse);
        ref.current = {};
      };
    }
  }, [targetToUse]);

  return {
    isScrollBottom,
    isScrollTop,
  };
}

export default hasScrollBottom;
