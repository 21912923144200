const config = (fabric) => {
  fabric.ToolbarText = fabric.util.createClass(fabric.IText, {
    type: 'toolbar-text',
    isToolbar: true,
    isToolbarActive: false,
    isTextMode: false,
    fill: '#E05932',
    stroke: '#E05932',
    strokeWidth: 1,
    charSpacing: 10,
    fontSize: 28,
    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        isToolbar: this.get('isToolbar'),
        isToolbarActive: this.get('isToolbarActive'),
        isTextMode: this.get('isTextMode'),
      });
    },
  });

  fabric.ToolbarText.fromObject = function(object, callback) {
    callback(new fabric.ToolbarText('', object));
  };
};

export default config;
