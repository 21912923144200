import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import _ from 'lodash';
import { useRoom } from '@uz/unitz-components-web/RoomWebComponents/OTSession';
import UserAvatar from '@uz/unitz-components-web/UserAvatar';
import UserProfileModelFormatter from '@uz/unitz-models/UserProfileModel/formatter';
import ActionButton from '@uz/unitz-components-web/RoomWebComponents/StyledActionButton';
import ControlButton from '@uz/unitz-components-web/RoomWebComponents/StyledControlButton';

import { Dropdown, Badge, Menu } from 'antd';

const CustomBadge = styled.div`
  .ant-badge-count {
    box-shadow: none;
    .ant-scroll-number-only {
      .ant-scroll-number-only-unit {
        color: ${gstyles.colors.white500};
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
`;

const AdvisorItem = ({ item }) => {
  return (
    <div className="px-2 flex items-center space-x-2">
      <ActionButton className="bg-gray-400" name="overlay" type="primary" loading={false}>
        {gstyles.icons({
          name: item?.audioTracks?.size ? 'micro' : 'micro-off',
          fill: item?.audioTracks?.size ? gstyles.colors.main : gstyles.colors.white500,
          size: 14,
        })}
      </ActionButton>
      <ActionButton className="bg-gray-400" name="overlay" type="primary" loading={false}>
        {gstyles.icons({
          name: item?.videoTracks?.size ? 'videocam' : 'videocam-off',
          fill: item?.videoTracks?.size ? gstyles.colors.main : gstyles.colors.white500,
          size: 14,
        })}
      </ActionButton>
      <UserAvatar
        query_str={_.get(item, 'identity')}
        size={26}
        showName={(user) => <div className="text-gray-50 mx-2">{UserProfileModelFormatter.displayName(ctx)(user)}</div>}
      />
    </div>
  );
};

const UserItem = ({ item }) => {
  return (
    <div className="px-2 flex items-center">
      <UserAvatar
        query_str={_.get(item, 'identity')}
        size={24}
        showName={(user) => <div className="text-gray-50 mx-2">{UserProfileModelFormatter.displayName(ctx)(user)}</div>}
      />
    </div>
  );
};

const View8 = () => {
  const ref = React.useRef({});
  const [ver, $ver] = React.useState(0);
  const [dropDownState, $dropDownState] = React.useState(false);
  const room = useRoom();
  _.assign(ref.current, { ver, $ver });
  const refreshMember = () => {
    ref.current.$ver && ref.current.$ver(ref.current.ver + 1);
  };

  React.useEffect(() => {
    room?.on('participantConnected', () => {
      refreshMember();
    });
    room?.on('participantDisconnected', () => {
      refreshMember();
    });
    room?.on('participantReconnected', () => {
      refreshMember();
    });
  }, [room]);

  const participants = _.get(room, 'participants');

  if (!participants) {
    return (
      <DIV>
        <ControlButton
          disable
          className="opacity-10"
          icon={gstyles.icons({
            name: 'participant',
            fill: gstyles.colors.white500,
            size: 24,
            className: 'mb-2',
          })}
          text={ctx.apply('i18n.t', 'VideoCallPage.SectionAction.participants')}
        />
      </DIV>
    );
  }

  const items = [];
  if (room.localParticipant) {
    items.push(room.localParticipant);
  }
  for (const [, item] of room?.participants) {
    items.push(item);
  }

  const [advisors, users] = _.partition(items, (item) => {
    return `${_.get(item, 'identity')}`.indexOf('advisor_id=') >= 0;
  });
  
  return (
    <DIV>
      <Dropdown
        getPopupContainer={() => document.querySelector('#room-call-content-body')}
        destroyPopupOnHide
        overlayStyle={{ marginLeft: 16, zIndex: !!dropDownState && 1000000000 }}
        onVisibleChange={(visible) => {
          $dropDownState(visible);
        }}
        overlay={
          <Menu className="w-80" theme="dark" defaultOpenKeys={['advisor', 'user']}>
            {_.get(advisors, 'length') && (
              <>
                <Menu.ItemGroup
                  key="advisor"
                  title={
                    <div className="text-white">{ctx.apply('i18n.t', 'RoomChat.Hosts', { num: advisors.length })}</div>
                  }
                  className="text-white"
                >
                  {_.map(advisors, (item) => {
                    return (
                      <Menu.Item key={item?.identity}>
                        <AdvisorItem item={item} />
                      </Menu.Item>
                    );
                  })}
                </Menu.ItemGroup>
                {/* <Menu.Divider /> */}
              </>
            )}
            {_.get(users, 'length') && (
              <>
                <Menu.ItemGroup
                  key="user"
                  title={
                    <div className="text-white">
                      {ctx.apply('i18n.t', 'RoomChat.Participants', { num: users.length })}
                    </div>
                  }
                  className="text-white"
                >
                  {_.map(users, (item) => {
                    return (
                      <Menu.Item key={item?.identity}>
                        <AdvisorItem item={item} />
                      </Menu.Item>
                    );
                  })}
                </Menu.ItemGroup>
                {/* <Menu.Divider /> */}
              </>
            )}
          </Menu>
        }
        placement="topLeft"
        trigger={['click']}
      >
        <CustomBadge>
          <Badge
            count={participants.size + 1}
            style={{ backgroundColor: gstyles.colors.brandb.DEFAULT, color: `${gstyles.colors.white500} !important` }}
          >
            <ControlButton
              disable={ctx.apply('videoCallModel.getCallState') !== 'videoCallOnProgress'}
              className={cx({ 'opacity-10': !ctx.get('tokbox.sessionId') })}
              icon={gstyles.icons({
                name: 'participant',
                fill: gstyles.colors.white500,
                size: 32,
              })}
              text={ctx.apply('i18n.t', 'VideoCallPage.SectionAction.participants')}
            />
          </Badge>
        </CustomBadge>
      </Dropdown>
    </DIV>
  );
};

export default displayName(View8);
