import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';
import GundbClient from '@vl/mod-clients/gundb';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import gstyles from '@vl/gstyles';
import { getLeadId } from '@uz/unitz-tool-components/Collab/Events/Lead';

export const Cursor = ({ targetId, selector, sessionId, canvas }) => {
  const ref = React.useRef({});
  const containerElementById = targetId || 'targetId';
  const disposerCleaner = useDisposerCleaner();
  _.assign(ref.current, { canvas });

  const trackKey = (userId) => {
    _.update(ref.current, `trackMap.${userId}`, (node) => {
      if (node) return node;
      const docRef = GundbClient.getClient().getEventStream(`cursor_${targetId}_${sessionId}_${userId}`);
      if (docRef) {
        docRef.onEvent((item) => {
          const cursor = ref.current.getCursor && ref.current.getCursor(userId);
          if (cursor) {
            const coords = {
              left: parseInt(item.left, 10) - 2,
              top: parseInt(item.top, 10) - 2,
            };
            let nameItem = cursor.item(1);
            if (!nameItem) {
              const { fabric } = window;
              const pointerItem = cursor.item(0);
              const fill = _.get(pointerItem, 'fill');
              nameItem = new fabric.Text(item.name, {
                fontSize: 16,
                left: 16,
                top: 16,
                fill,
                owner_id: false,
              });
              gstyles.Icons.getSvg({
                name: 'cursor',
                fill,
                size: 24,
              }).then((svg) => {
                fabric.loadSVGFromString(svg, (objects, options) => {
                  const obj = fabric.util.groupSVGElements(objects, options);
                  if (!cursor.item(1)) {
                    obj.set({
                      owner_id: false,
                      fill,
                      top: 0,
                      left: 0,
                    });
                    cursor.add(nameItem, obj);
                    cursor.addWithUpdate();
                  }
                });
              });
            }
            if (nameItem && nameItem.text !== item.name) {
              nameItem.set({
                text: item.name,
              });
            }
            cursor.set(coords).setCoords();
            cursor.bringToFront();
            ref.current.canvas && ref.current.canvas.requestRenderAll();
          }
        });
      }
      return () => {
        // docRef.off();
        // delete cusor
      };
    });
  };

  React.useEffect(() => {
    const { ctx } = ref.current;
    if (!ctx) return () => {};
    const userId = ctx.apply('authModel.getUserId');
    UserIdentity.getDisplayName(userId).then((val) => {
      ref.current.userDisplayName = val;
    });
    const docRef = GundbClient.getClient().getEventStream(`cursor_${targetId}_${sessionId}_${userId}`);

    ref.current.getContainerEle = () => {
      let containerEle = document.getElementById(containerElementById);
      if (selector && containerEle) {
        containerEle = containerEle.querySelector(selector);
      }
      if (containerEle && !containerEle.style.position) {
        containerEle.style.position = 'relative';
      }
      return containerEle;
    };

    ref.current.getCursor = (user_id) => {
      const { fabric } = window;
      if (!canvas || !fabric) return null;
      const containerEle = canvas.htmlEle;
      if (!!user_id && !_.has(ref.current, `cursors.${user_id}`)) {
        if (containerEle) {
          const fill = user_id === getLeadId() ? gstyles.colors.brandb.DEFAULT : gstyles.colors.branda.DEFAULT;
          const pointer = new fabric.Cursor({
            fill,
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            owner_id: false,
          });
          let cursor = new fabric.Group([pointer], {
            selectable: false,
            evented: false,
            hasControls: false,
            hasBorders: false,
            owner_id: false,
            fill,
          });
          canvas.add(cursor);
          _.set(ref.current, `cursors.${user_id}`, cursor);
        }
      }
      return _.get(ref.current, `cursors.${user_id}`);
    };

    if (canvas) {
      disposerCleaner(
        canvas.$on('mouse:move', (evt) => {
          const event = evt.e;
          const left = _.get(evt, 'absolutePointer.x');
          const top = _.get(evt, 'absolutePointer.y');

          const { offsetX = 0, offsetY = 0 } = event;
          const rect = event.target.getBoundingClientRect();
          const percentX = (offsetX / rect.width) * 100;
          const percentY = (offsetY / rect.height) * 100;
          if (left <= 0 || top <= 0) return;
          docRef.putEvent({
            id: userId,
            name: ref.current.userDisplayName,
            left,
            top,
            offsetX,
            offsetY,
            percentX,
            percentY,
          });
        })
      );
    }

    // setup listener
    const sessionRef = GundbClient.getClient().getDoc(`sessions_${sessionId}`);
    sessionRef.map().on((item) => {
      const id = _.get(item, 'id');
      if (id === userId) return;
      trackKey(id);
    });
    if (userId) {
      sessionRef.get(userId).put({ id: userId, value: userId });
    }

    return () => {
      // sessionRef.off();
      // _.map(ref.current.trackMap, (disposer) => {
      //   disposer && disposer();
      // });
      // ref.current = {};
    };
  }, [canvas, !!ref.current.ctx]);

  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
      <div className=""></div>
    </DIV>
  );
};

export default Cursor;
