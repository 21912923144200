import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row, Alert } from 'antd';

import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import Loadable from '@loadable/component';
import _ from 'lodash';
import cx from 'classnames';

import UploadContainer from '../Components/UploadContainer';

const ReactQuill = Loadable(() => import('./Quill/ReactQuill'));

const NEW_LINE_STR = '<p><br></p>';
const cleanMessage = (value) => {
  let rtn = `${value}`;
  while (_.endsWith(rtn, NEW_LINE_STR)) {
    rtn = rtn.substr(0, rtn.length - NEW_LINE_STR.length);
  }
  return rtn;
};

const CustomInput = styled.div`
  textarea,
  input,
  .ant-picker,
  .ant-radio-group {
    border-radius: 8px;
    border-color: ${gstyles.colors.divider};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.divider};
      }
    }
  }
  .ant-radio-group {
    overflow: hidden;
    .ant-radio-button-wrapper {
      width: 50%;
      border-color: ${gstyles.colors.divider};
      text-align: center;
      color: ${gstyles.colors.main};
      font-weight: 400;
      &.ant-radio-button-wrapper-checked {
        color: ${gstyles.colors.white500};
        font-weight: bold;
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  span {
    .ant-upload {
      border-radius: 8px;
      border-color: ${gstyles.colors.divider};
      background-color: ${gstyles.colors.background2};
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const StyledEditor = styled.div`
  .ql-editor {
    .block-image {
      margin-bottom: 4px;
      margin-right: 4px;
    }
  }
  &.compact_input {
    .ql-editor {
      max-height: 320px;
      overflow-y: auto;
    }
  }
  &.full_input {
    height: 100%;
    .ql-editor {
      overflow-y: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      background-size: 100% 1.4em;
      background-image: -webkit-linear-gradient(0deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
        -webkit-linear-gradient(#eee 0.05em, transparent 0.05em);
      background-image: -moz-linear-gradient(0deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
        -moz-linear-gradient(#eee 0.05em, transparent 0.05em);
      background-image: -ms-linear-gradient(0deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
        -ms-linear-gradient(#eee 0.05em, transparent 0.05em);
      background-image: -o-linear-gradient(0deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
        -o-linear-gradient(#eee 0.05em, transparent 0.05em);
      background-image: linear-gradient(0deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
        linear-gradient(#eee 0.05em, transparent 0.05em);
      -pie-background: linear-gradient(0deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px) 0 0 / 100%
          1.2em,
        linear-gradient(#eee 0.05em, transparent 0.05em) 0 0 / 100% 1.2em #fff;
      behavior: url(/PIE.htc);
    }
    > form {
      display: flex;
      flex-direction: column;
      height: 100%;
      .input-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .quill {
        flex: 1;
      }
    }
  }
`;

const View8 = ({ onChange, toolbarOptions, offToolbar, offUploadContainer }) => (
  <DIV className="component">
    <StyledEditor
      className={cx({
        full_input: ctx.get('messageControlModel.fullInput'),
        compact_input: !ctx.get('messageControlModel.fullInput'),
      })}
    >
      <FormProvider form={ctx.get('form')}>
        <Form {...layout} className="">
          <Row>
            <Col className="formStatus" span={24}>
              {!!ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              {!!ctx.get('form.status.success') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.success.message')} type="success" />
                </div>
              )}
            </Col>
          </Row>
          <CustomInput className="relative input-wrapper">
            {!offUploadContainer && <UploadContainer />}
            <ReactQuill
              theme="snow"
              placeholder="fasdsd"
              toolbarOptions={toolbarOptions}
              offToolbar={offToolbar}
              onChange={onChange}
            />
          </CustomInput>
          <div className="flex justify-between px-2">
            <div>{cleanMessage(ctx.get('form.values.message')) && '...'}</div>
            {!ctx.get('messageControlModel.fullInput') && <div>{ctx.apply('i18n.t', 'Message.hints.EnterToSend')}</div>}
          </div>
        </Form>
      </FormProvider>
    </StyledEditor>
  </DIV>
);

export default displayName(View8);
