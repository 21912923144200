import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Button } from 'antd';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import displayName from '@vl/redata/displayName.macro';

const StyledSelect = styled.div`
  .ant-btn.ant-btn-sm {
    background-color: ${gstyles.colors.sub};
    border: none;
    border-radius: 0 8px 8px 0;
  }
`;

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });
  const forceUpdate = useForceUpdate();

  const applicableTarget = (() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    const type = _.get(ref.current.target, 'type');
    if (['activeSelection'].includes(type) && _.get(ref.current.target, '_objects.length')) return true;
    if (['i-text', 'path', 'StickyNote', 'Picture'].includes(type)) {
      return true;
    }
  })();

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Button
        size="small"
        onClick={async () => {
          const activeObject = target;
          // group remove
          if (_.get(activeObject, '_objects')) {
            for (const obj of activeObject._objects) {
              canvas.remove(obj);
            }
          }
          if (activeObject) {
            canvas.remove(activeObject);
          }
          canvas.discardActiveObject();
          _onChange && _onChange();
          await new Promise((res) => setTimeout(res, 300));
          forceUpdate();
        }}
        {...rest}
      >
        {gstyles.icons({ name: 'remove-circle', size: 20, fill: gstyles.colors.white500 })}
      </Button>
    </StyledSelect>
  );
};

export default displayName(Index);
