exports.msViewer = {
  types: {
    'application/msword': {
      ext: 'doc',
      thumb: 'thumbs/doc.png',
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      ext: 'docx',
      thumb: 'thumbs/doc.png',
    },
    'application/vnd.ms-fontobject': {
      ext: 'eot',
      thumb: 'thumbs/doc.png',
    },
    'application/vnd.oasis.opendocument.presentation': {
      ext: 'odp',
      thumb: 'thumbs/doc.png',
    },
    'application/vnd.oasis.opendocument.spreadsheet': {
      ext: 'ods',
      thumb: 'thumbs/doc.png',
    },
    'application/vnd.ms-powerpoint': {
      ext: 'ppt',
      thumb: 'thumbs/doc.png',
    },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      ext: 'pptx',
      thumb: 'thumbs/ppt.png',
    },
    'application/vnd.ms-excel': {
      ext: 'xls',
      thumb: 'thumbs/xls.png',
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      ext: 'xlsx',
      thumb: 'thumbs/xlsx.png',
    },
  },
};

exports.googleViewer = {
  types: {
    // 'application/pdf': {
    //   ext: 'pdf',
    //   thumb: 'thumbs/pdf.png',
    // },
  },
};

exports.unitzViewer = {
  types: {
    'application/pdf': {
      ext: 'pdf',
      thumb: 'thumbs/pdf.png',
    },
  },
};

exports.videoViewer = {
  types: {
    'video/webm': {
      ext: 'webm',
      thumb: 'thumbs/webm.png',
    },
  },
};

exports.audioViewer = {
  types: {
    'audio/wav': {
      ext: 'wav',
      thumb: 'thumbs/wav.png',
    },
    'audio/mpeg': {
      ext: 'mp3',
      thumb: 'thumbs/mp3.png',
    },
    'audio/mp3': {
      ext: 'mp3',
      thumb: 'thumbs/mp3.png',
    },
    'audio/webm': {
      ext: 'weba',
      thumb: 'thumbs/webm.png',
    },
  },
};

exports.getThumbnailUrl = (type) => {
  return `/files/mime-icons/${`${type}`.replace(new RegExp('/', 'g'), '-')}.png`;
};
