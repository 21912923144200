import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import VideoCallFinish from '@uz/unitz-components-web/VideoCall/components/VideoCallFinish';
import VideoCallMissed from '@uz/unitz-components-web/VideoCall/components/VideoCallMissed';
import VideoCallRejected from '@uz/unitz-components-web/VideoCall/components/VideoCallRejected';
import VideoCallEarlyEnd from '@uz/unitz-components-web/VideoCall/components/VideoCallEarlyEnd';
import RatingFlatform from '@uz/unitz-pages/RatingFlatform';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen h-fill-screen bg-main">
        <DIV className="completedSuccess">
          <VideoCallFinish />
        </DIV>
        <DIV className="completedMissed">
          <VideoCallMissed />
        </DIV>
        <DIV className="completedRejected">
          <VideoCallRejected />
        </DIV>
        <DIV className="completedRating">
          <RatingFlatform />
        </DIV>
        <DIV className="completedEarlyEnd">
          <VideoCallEarlyEnd />
        </DIV>
      </div>
    </DIV>
  );
};

export default displayName(Index);
