import _ from 'lodash';

export const useEventTriggers = (eventHandlers) => {
  return {
    emitEvent: (name, ...args) => {
      if (_.isFunction(_.get(eventHandlers, name))) {
        eventHandlers[name](...args);
      }
    },
  };
};

export default useEventTriggers;
