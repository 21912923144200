const config = (fabric) => {
  fabric.Marker = fabric.util.createClass(fabric.Path, {
    type: 'marker',
    isToolbar: true,
    isToolbarActive: false,
    isDrawingMode: false,
    fill: null,
    stroke: '#E05932',
    strokeWidth: 4,
    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        isToolbar: this.get('isToolbar'),
        isToolbarActive: this.get('isToolbarActive'),
        isDrawingMode: this.get('isDrawingMode'),
      });
    },
  });

  fabric.Marker.fromObject = function(object, callback) {
    callback(new fabric.Marker('', object));
  };
};

export default config;
