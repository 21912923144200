import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

import { Button } from '@uz/unitz-components-web/Button';
import LoadingContent from '@uz/unitz-components-web/LoadingContent';
import RoomSelect from '@uz/unitz-components-web/CourseRoom/Components/Select';
import gstyles from '@vl/gstyles';

const MessageHeader = () => {
  return (
    <DIV>
      <div className="flex-1 flex justify-between items-center py-2 pl-4">
        <LoadingContent loading={() => !ctx.apply('messageControlModel.getRoomData')}>
          <RoomSelect
            items={_.orderBy(
              [
                ...ctx.get('courseRoomData.course.upcomming_rooms', []),
                ...ctx.get('courseRoomData.course.passed_rooms', []),
              ],
              ['start_at'],
              ['asc']
            )}
            suffixIcon={gstyles.icons({
              name: 'arrow-down3',
              size: 24,
              className: '-mt-2',
              fill: gstyles.colors.main,
            })}
            onChange={(roomId) => {
              ctx.apply('messageControlModel.changeRoom', roomId);
            }}
            defaultValue={_.get(ctx.apply('messageControlModel.getRoomData'), 'id')}
          />
        </LoadingContent>
        <Button
          size="small"
          type="text"
          onClick={() => {
            const messageControlModelRef = ctx.apply('REF.getRef', 'messageControlModelRef');
            if (messageControlModelRef) {
              messageControlModelRef.toggle();
            }
          }}
        >
          {gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.main })}
        </Button>
      </div>
    </DIV>
  );
};

export default MessageHeader;
