const config = (fabric) => {
  function toObjectIText(toObject) {
    return function fn() {
      return fabric.util.object.extend(toObject.call(this), {
        text: this.text,
        fontStyle: this.fontStyle,
        fontSize: this.fontSize,
        fontFamily: this.fontFamily,
      });
    };
  }

  fabric.IText.prototype.toObject = toObjectIText(fabric.IText.prototype.toObject);
};

export default config;
