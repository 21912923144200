import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import CallCompleted from '@uz/unitz-pages/CallCompleted';
import RoomAttend from '@uz/unitz-tool-pages/RoomAttend';
// import ZoomVideoWeb from '@uz/zoom-video-web';
import { UnAuthRedirect, GuestRedirect } from '@uz/unitz-tool-components/AppRedirect';
import { ctx } from '@vl/redata';


const Index = () => {
  return (
    <DIV>
      {/* <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}> */}
      <GuestRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <DIV className="serviceSessionPage">
          <DIV className="serviceStep">
            <RoomAttend />
            {/* <ZoomVideoWeb /> */}
          </DIV>
          <DIV className="completedStep">
            <CallCompleted />
          </DIV>
        </DIV>
      </GuestRedirect>
      {/* </UnAuthRedirect> */}
    </DIV>
  );
};

export default displayName(Index);
