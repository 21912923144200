import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Input, Rate } from 'antd';
import { Button } from '@uz/unitz-components-web/Button';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import _ from 'lodash';

const FinishLayout = styled.div`
  padding: 0 16px;
  textarea.ant-input {
    font-size: 16px;
  }
  textarea.ant-input::placeholder {
    color: ${gstyles.colors.sub};
    font-size: 16px;
  }
  .ant-input {
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    --tw-border-opacity: 1;
    border-color: rgba(191, 205, 217, var(--tw-border-opacity));
  }
`;
const Triagle = styled.div.attrs((props) => ({
  halfSize: props.size ? `${props.size / 2}px` : '5px',
  size: props.size ? `${props.size}px` : '10px',
  color: props.color ? props.color : gstyles.colors.border,
}))`
  position: relative;
  overflow: hidden;
  transform: translateY(50%) rotate(45deg) skewY(45deg) scaleX(0.5);
  pointer-events: none;
  & {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }

  &::before,
  &::after {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    background: ${(props) => props.color};
    pointer-events: auto;
  }
  &::before {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
  }
  &::after {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
  }

  border-radius: 20%;
  &::before {
    border-radius: 20% 20% 20% 55%;
  }

  &::after {
    border-radius: 20% 20% 55% 20%;
  }
  textarea::-webkit-resizer {
    display: none;
  }
`;

const View8 = () => {
  return (
    <DIV className="videoCallRejected">
      {/* <Modal
        className="pb-0 overflow-hidden rounded-xl"
        style={{ borderRadius: 12 }}
        bodyStyle={{ padding: 0 }}
        width={600}
        footer={null}
        visible={true}
        closable={false}
      > */}
      <FinishLayout>
        <section className="py-0 bg-white min-lg:w-150 max-w-150 rounded-xl">
          <div className="flex flex-col items-center min-lg:p-6 max-lg:py-6">
            <h2 className="mb-1 text-xl text-main">
              {ctx.apply('i18n.t', 'AdvisorVideoCall.callStatus.earlyEndLabel')}
            </h2>
            <div className="flex mt-1">
              {gstyles.icons({
                name: 'videocam',
                fill: gstyles.colors.brandb500,
                size: 24,
                className: 'mr-2',
              })}
              <div className="mb-0 text-base font-normal text-main">{ctx.get('purchaseData.bill.totalAfter')}</div>
            </div>
            <div className="mt-2 mb-0 mr-2 text-4xl text-main">{ctx.get('transactionData.callPackageTime')}</div>
            <div className="flex items-center justify-between w-full px-4 mt-4 min-lg:py-2 max-lg:py-1 min-lg:rounded-xl bg-red-lightest">
              <span className="text-sub">{ctx.apply('i18n.t', 'AdvisorVideoCall.earlyEndCallText')}</span>
              <Button
                onClick={ctx.get('stepModel.handlers.callAgain')}
                icon={gstyles.icons({
                  name: 'videocam',
                  size: 22,
                  className: 'inline-block mr-2',
                })}
                type="link"
                name="red"
              >
                {ctx.apply('i18n.t', 'AdvisorVideoCall.reCall')}
              </Button>
            </div>
          </div>
          <div className="border-b border-divider"></div>
          <div className="flex flex-col items-center p-6">
            <div className="flex items-center">
              <Avatar
                src={ctx.get('purchaseData.advisorProfile.avatarUrl')}
                alt={ctx.get('purchaseData.advisorProfile.displayName')}
                size={32}
              />
              <h1 className="mb-0 ml-2 text-base text-main">{ctx.get('purchaseData.advisorProfile.displayName')}</h1>
            </div>
            <div className="mt-4 mb-3 text-base font-normal text-center">
              {ctx.apply('i18n.t', 'AdvisorVideoCall.finishDesc')}
            </div>
            <Rate
              count={5}
              value={ctx.get('form.values.rating')}
              onChange={(number) => {
                ctx.apply('form.handleChange', 'rating')(number.toString());
              }}
            />
          </div>
          <div className="border-b border-divider"></div>
          <div className="flex flex-col p-6 pb-0">
            <label className="mb-2 text-base">{ctx.apply('i18n.t', 'AdvisorVideoCall.userReaction')}</label>
            <div className="relative">
              <Input.TextArea
                rows={3}
                value={ctx.get('form.values.note')}
                placeholder={ctx.apply('i18n.t', 'AdvisorVideoCall.finishNote')}
                onChange={(e) => ctx.apply('form.handleChange', 'note')(_.get(e, 'target.value', ''))}
              ></Input.TextArea>
              <div className="absolute p-1 rounded-lg pointer-events-none right-1px bottom-1px bg-white500">
                <Triagle className="" size={10} color={gstyles.colors.border} />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between p-6 space-x-4">
            <Button
              onClick={ctx.get('form.submitForm')}
              size="large"
              disabled={!ctx.get('canSubmit')}
              loading={ctx.get('form.isSubmitting')}
              type="primary"
              block
            >
              {ctx.apply('i18n.t', 'AdvisorVoiceCall.finishReview')}
            </Button>
            <Button onClick={ctx.get('stepModel.handlers.callFinish')} size="large" type="primary" block>
              {ctx.apply('i18n.t', 'AdvisorVideoCall.finishSend')}
            </Button>
          </div>
        </section>
      </FinishLayout>
      {/* </Modal> */}
    </DIV>
  );
};

export default displayName(View8);
