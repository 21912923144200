import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import { ensureViewport } from '@vl/mod-utils/document';
import _ from 'lodash';

import MainToolbar from './Components/MainToolbar';
import InlineToolbar from './Components/InlineToolbar';

export const Controls = ({ canvas }) => {
  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();

  // add min toolbar
  React.useEffect(() => {
    // main toolbar
    disposerCleaner(
      (() => {
        const getMainToolbar = (name) => {
          const containerEle = document.getElementById(canvas.targetId);
          let toolbar = containerEle && containerEle.querySelector(`#canvas_main_toolbar_${name}`);
          if (!toolbar) {
            // single toolbar instance only
            const toolbarClass = 'whiteboard-main-toolbar';

            toolbar = document.createElement('div');
            toolbar.classList.add('absolute', 'z-50', toolbarClass);
            containerEle.appendChild(toolbar);
            ReactDOM.render(<MainToolbar canvas={canvas} name={name} />, toolbar);
          }
          toolbar.style.display = 'block';
          return toolbar;
        };

        const toolbar = getMainToolbar(`main_${canvas.targetId}`);
        return () => {
          toolbar && toolbar.remove();
        };
      })()
    );

    // inline toolbar
    disposerCleaner(
      (() => {
        const toolbarId = `inline_${canvas.targetId}`;
        const getContainerEle = _.memoize((targetId = canvas.targetId) => {
          return document.getElementById(targetId);
        });

        const getToolbar = _.memoize((name) => {
          const containerEle = document.getElementById(`canvas_${canvas.targetId}`);
          let toolbar = containerEle.querySelector(`#canvas_inline_toolbar_${name}`);
          if (!toolbar) {
            toolbar = document.createElement('div');
            toolbar.classList.add('absolute', 'z-50');
            containerEle.parentElement.appendChild(toolbar);
            ReactDOM.render(<InlineToolbar canvas={canvas} />, toolbar);
          }
          return toolbar;
        });

        const _positionToolbar = (obj) => {
          let absCoords = canvas.getAbsoluteCoords(obj);
          const toolbar = getToolbar(toolbarId);
          const containerEle = getContainerEle();
          absCoords = ensureViewport(containerEle, toolbar, absCoords);
          const deltaY = 40;
          const deltaX = 20;
          toolbar.style.display = 'block';
          toolbar.style.left = `${Math.max(8, absCoords.left - deltaX)}px`;
          toolbar.style.top = `${Math.max(8, absCoords.top - deltaY)}px`;
        };

        function positionToolbar(obj) {
          _positionToolbar(obj);
          setTimeout(() => {
            _positionToolbar(obj);
          }, 100);
        }

        function hideToolbar() {
          const toolbar = getToolbar(toolbarId);
          toolbar.style.display = 'none';
        }

        canvas.on({
          'selection:created': (evt) => {
            const { target } = evt;
            target && positionToolbar(target);
          },
          'selection:updated': (evt) => {
            const { target } = evt;
            target && positionToolbar(target);
            return true;
          },
          'selection:cleared': () => {
            hideToolbar();
          },
          'object:moving': (evt) => {
            const { target } = evt;
            target && positionToolbar(target);
            // console.log('selection:moving', evt);
          },
          'object:scaling': (evt) => {
            const { target } = evt;
            target && positionToolbar(target);
          },
          'object:rotating': (evt) => {
            const { target } = evt;
            target && positionToolbar(target);
          },
          'object:modified': (evt) => {
            const { target } = evt;
            target && positionToolbar(target);
          },
        });

        const toolbar = getToolbar(toolbarId);
        return () => {
          toolbar && toolbar.remove();
        };
      })()
    );
  }, []);

  return null;
};

export default Controls;
