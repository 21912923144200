import React from 'react';
import _ from 'lodash';

const resolveFunc = (fn, args) => {
  if (_.isFunction(fn)) {
    return fn.call(fn, args);
  }
  return fn;
};

export const RefreshInterval = ({ children, interval }) => {
  const [v, $v] = React.useState(0);

  const ref = React.useRef({});

  _.assign(ref.current, { v, $v });

  React.useEffect(() => {
    ref.current.timer = setInterval(() => {
      ref.current.$v && ref.current.$v(ref.current.v + 1);
    }, interval);

    return () => {
      if (ref.current.timer) {
        clearInterval(ref.current.timer);
      }
      ref.current = {};
    };
  }, [interval]);
  return resolveFunc(children);
};

export default RefreshInterval;
