import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import MessageModel from '@uz/unitz-models/MessageModel';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import { Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { Button } from '@uz/unitz-components-web/Button';
import gstyles from '@vl/gstyles';
import MessageReactionPicker from '@uz/unitz-tool-components/Message/Components/MessageReactionPicker';

const ActionButton = styled(Button)`
  margin: 0 2px;
  width: 26px;
  height: 26px;
  padding: 2px;
  display: inline-flex !important;
  justify-content: center;
`;

const ActionDropdown = styled(Dropdown)`
  margin: 0 2px;
  width: 26px;
  height: 26px;
  padding: 2px;
  display: inline-flex !important;
  justify-content: center;
  cursor: pointer;
`;

export const MessageActions = ({ message_id }) => {
  const [, $isReplying] = MessageStore.useMessageState(message_id, 'replying', false);
  const [, $isEditing] = MessageStore.useMessageState(message_id, 'editing', false);
  const messageModel = MessageStore.use(message_id);
  const actions = {
    editMessage: (ctx) => async () => {
      const messageInputRef = ctx.apply('REF.getRef', 'messageInputRef');
      const startEdit = _.get(messageInputRef, 'current.handlers.startEdit');
      $isEditing(true);
      if (_.isFunction(startEdit)) {
        await startEdit(message_id);
        $isEditing(false);
      }
    },
    replyMessage: (ctx) => async () => {
      const messageInputRef = ctx.apply('REF.getRef', 'messageInputRef');
      const startReply = _.get(messageInputRef, 'current.handlers.startReply');
      $isReplying(true);
      if (_.isFunction(startReply)) {
        await startReply(message_id);
        $isReplying(false);
      }
    },
    reactionMessage: () => async (code_decimal) => {
      await MessageModel.getClient().request(
        `mutation ($reaction: message_reaction_insert_input!){
          insert_message_reaction_one(
            object: $reaction
            on_conflict: {
              constraint: message_reaction_message_id_code_decimal_created_by_key,
              update_columns: message_id,
            }
          ) {
            id
          }
        }`,
        {
          reaction: {
            message_id,
            code_decimal,
          },
        }
      );
    },
    setAsQuestion: () => () => {},
    setAsTopic: () => () => {},
    pinMessage: () => () => {},
    removeMessage: () => () => {
      MessageModel.remove(message_id);
    },
  };

  return (
    <DIV>
      <div className="flex rounded items-center">
        {messageModel.canReaction && (
          <ActionDropdown
            type="text"
            icon={gstyles.icons({ name: 'ellipsisV', size: 20 })}
            placement="bottom"
            trigger="click"
            getPopupContainer={() => {
              return document.getElementById('messageContainerRef');
            }}
            destroyPopupOnHide
            overlay={() => (
              <MessageReactionPicker
                onClick={(emoji) => {
                  const colons = _.get(emoji, 'colons');
                  if (colons) {
                    colons && actions.reactionMessage()(colons);
                  }
                }}
              />
            )}
          >
            {gstyles.icons({ name: 'emoji', size: 20 })}
          </ActionDropdown>
        )}
        {messageModel.canReply && (
          <ActionButton
            type="text"
            size="small"
            icon={gstyles.icons({ name: 'threads', size: 20 })}
            onClick={() => actions.replyMessage(ctx)()}
          />
        )}
        {messageModel.canEdit && (
          <ActionButton
            type="text"
            size="small"
            icon={gstyles.icons({ name: 'edit', size: 20 })}
            onClick={() => actions.editMessage(ctx)()}
          />
        )}
        <ActionDropdown
          type="text"
          icon={gstyles.icons({ name: 'ellipsisV', size: 20 })}
          placement="bottomRight"
          trigger="click"
          destroyPopupOnHide
          overlay={
            <Menu>
              {messageModel.canEdit && (
                <Menu.Item key="1" onClick={() => actions.editMessage(ctx)()}>
                  {ctx.apply('i18n.t', 'Message.messageActions.edit')}
                </Menu.Item>
              )}
              {messageModel.canEdit && (
                <Menu.Item key="2" onClick={() => actions.removeMessage(ctx)()}>
                  {ctx.apply('i18n.t', 'Message.messageActions.remove')}
                </Menu.Item>
              )}
              {messageModel.canPin && (
                <Menu.Item key="3" onClick={() => actions.pinMessage(ctx)()}>
                  {ctx.apply('i18n.t', 'Message.messageActions.pin')}
                </Menu.Item>
              )}
              <Menu.Divider />
              {messageModel.canSetTopic && (
                <Menu.Item key="4" onClick={() => actions.setAsTopic(ctx)()}>
                  {ctx.apply('i18n.t', 'Message.messageActions.setAsTopic')}
                </Menu.Item>
              )}
              {messageModel.canSetQuestion && (
                <Menu.Item key="5" onClick={() => actions.setAsQuestion(ctx)()}>
                  {ctx.apply('i18n.t', 'Message.messageActions.setAsQuestion')}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          {gstyles.icons({ name: 'ellipsisV', size: 20 })}
        </ActionDropdown>
      </div>
    </DIV>
  );
};

export default MessageActions;
