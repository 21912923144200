import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetPurchase from 'unitz-gql/purchase/GetPurchase.gql';

graphme.GqlBuilder.loadDocument({
  GetPurchase,
});

class PurchaseModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'PurchaseModel',
    schema: {
      id: String,
      user_id: String,
    },

    key: 'id',

    baseQuery: 'GetPurchase',

    GQL_ACTIONS: {
      FIND: 'purchase',
      GET: 'purchase_by_pk',
      INSERT: 'insert_purchase_one',
      UPDATE: 'update_purchase_by_pk',
      DELETE: 'delete_purchase_by_pk',
    },

    getClient: hasuraClient.getClient,
  });
  
  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ PurchaseModel });

export default PurchaseModel;
