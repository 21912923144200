export function getScrollTop(target) {
  if (target) return target.scrollTop;
  return (
    window.scrollY ||
    window.pageYOffset ||
    document.body.scrollTop ||
    (document.documentElement && document.documentElement.scrollTop) ||
    0
  );
}

export function getScrollLeft(target) {
  if (target) return target.scrollLeft;
  return (
    window.scrollX ||
    window.pageXOffset ||
    document.body.scrollLeft ||
    (document.documentElement && document.documentElement.scrollLeft) ||
    0
  );
}

export function isBrowser() {
  return typeof window === 'object';
}

export function addScrollListener(listener, target) {
  return target.addEventListener('scroll', listener);
}

export function removeScrollListener(listener, target) {
  return target.removeEventListener('scroll', listener);
}
