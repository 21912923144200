import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import { message, Popconfirm } from 'antd';
import displayName from '@vl/redata/displayName.macro';
import RefreshInterval from '@uz/unitz-components-web/RefreshInterval';
import _ from 'lodash';
import CourseRoomTimeSlot, { CourseRoomTimeCountdown } from '@uz/unitz-tool-components/CourseRoomTimeSlot';
import Layout from '@uz/unitz-layout-web/LayoutRoomCall';
import Button from '@uz/unitz-components-web/Button';
import AutoJoinRoomCheckbox from '@uz/unitz-tool-components/AutoJoinRoomCheckbox';
import CountDownTimerAction from './CountDownTimerAction';

const View8 = () => {
  return (
    <DIV className="videoCallOnProgress">
      <div>
        <Layout.POS name="content-header-title">
          <div className="w-full">
            <div className="flex flex-row space-x-2">
              <h1 className="text-base font-semibold text-white500 text-ellipsis-1 overflow-hidden">
                {ctx.get('@item.course.name')} | {ctx.get('@item.name')}
              </h1>
            </div>
            <DIV forceCtx>
              <RefreshInterval interval={1000}>
                {() => (
                  <div className="flex space-x-2 divide-x divide-gray-300">
                    <span className="text-xs text-white400">
                      <CourseRoomTimeSlot />
                    </span>
                    <CourseRoomTimeCountdown
                      render={(duration) => (
                        <span className="px-2 text-xs text-white400 animate-pulse">{duration}</span>
                      )}
                    />
                  </div>
                )}
              </RefreshInterval>
            </DIV>
          </div>
        </Layout.POS>
        <Layout.POS name="content-header-subtitle">
          <div className="w-full flex space-x-2">
            {(() => {
              const next_room = ctx.get('@item.course.next_rooms.0');
              const current_room = ctx.get('@item');
              if (next_room) {
                return (
                  <div className="w-44">
                    <CountDownTimerAction room={next_room} size="small" />
                    <DIV forceCtx>
                      <div className="text-white my-1">
                        {ctx.debug(() => {
                          ctx.set('room', next_room);
                        })}
                        <AutoJoinRoomCheckbox value={true} />
                      </div>
                    </DIV>
                  </div>
                );
              }
              if (current_room) {
                return (
                  <div className="w-44">
                    <CountDownTimerAction room={current_room} size="small" />
                  </div>
                );
              }
              return null;
            })()}
            <div className="w-40">
              <Popconfirm
                placement="topLeft"
                title={ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.cancelText')}
                onConfirm={() => {
                  ctx.apply('routeStore.navigateExternal', '/');
                }}
                okText={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.yes')}
                cancelText={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.cancel')}
                okButtonProps={{ className: 'w-24' }}
                cancelButtonProps={{ className: 'w-24' }}
              >
                <Button
                  name="red"
                  type="primary"
                  size="small"
                  block
                >
                  {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.leave')}
                </Button>
              </Popconfirm>
            </div>
          </div>
        </Layout.POS>
      </div>
    </DIV>
  );
};

export default displayName(View8);
