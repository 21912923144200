import connectFirestore from '@vl/mod-utils/connectFirestore';
import { INTERNET_STATES } from './constants';

class VideoCallState {
  // syncable state here

  // local state here
  _local = {
    ctx: null,
  };

  constructor(data, ctx) {
    const { id, ...rest } = data;
    // init state
    Object.assign(this, {
      sessionData: {},
      session_id: id,
      tracks: {
        pins: [],
        screens: [],
      },
      ...rest,
    });

    this._local.ctx = ctx;
  }

  get uid() {
    const uid = this._local.ctx.apply('authModel.getUserId');
    return uid;
  }

  onConnect(state) {
    // connect to sync with firestore data;
    state.once(
      'onDestroy',
      connectFirestore(this._local.ctx, this)(state, {
        docId: this.session_id,
        collectionName: 'course_rooms',
        mode: 'r',
      })
    );
  }
}

export default VideoCallState;
