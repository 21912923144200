import _ from 'lodash';
import moment from 'moment';
import { formatVND, normalizeNumber } from '@vl/mod-utils/currencyFormatter';
import { formatFullDateDisplay } from '@vl/mod-utils/dateFormatter';
import { DATE_TIME_CREATED_FORMAT } from '@vl/mod-utils/datetime';
import helpers from '@vl/mod-utils/flattenGet';
import i18n from 'i18n-js';

export const formatter = {
  total: () => (itemData) => {
    const price_amount = _.get(itemData, 'services.0.price_amount', 0);
    if (!price_amount) return 0;
    return formatVND(price_amount);
  },
  coursePriceAmount: () => (itemData) => {
    const price_amount = _.get(itemData, 'courses.0.course.price_amount', 0);
    if (!price_amount) return 0;
    return formatVND(price_amount);
  },
  discount: () => (itemData) => {
    const percentage = _.get(itemData, 'discounts.0.percentage', 0);
    if (!percentage) return 0;
    return `${percentage} %`;
  },

  totalAfter: () => (itemData) => {
    const payments = _.get(itemData, 'payments', []);
    if (!_.get(payments, 'length')) return 0;
    const wallet_amount = _.sumBy(payments, 'amount');

    if (!wallet_amount) return 0;
    return formatVND(wallet_amount);
  },

  typePurchase: () => (itemData) => {
    const found = !_.isEmpty(_.get(itemData, 'courses.0.course.id'));
    if (found) {
      return 'course';
    }
    return 'call';
  },

  courseName: (ctx) => (itemData) => {
    const name = _.get(itemData, 'courses.0.course.name', '');
    return name;
  },

  coursePaymentType: (ctx) => (itemData) => {
    const roomPayment = _.get(itemData, 'course_rooms.length', 0) > 0;
    if (roomPayment) {
      return 'refund';
    }
    return 'payment';
  },

  courseRefund: (ctx) => (itemData) => {
    const statements = helpers.flattenGet(itemData, 'course_rooms.purchase.transaction_purchase.transaction.statement');
    const amount = _.get(_.find(statements, { name: 'refund' }), 'amount');
    return amount || 0;
  },

  fromMoney: () => (itemData, source) => {
    const payments = _.get(itemData, 'payments', []);

    if (!_.get(payments, 'length')) return 0;

    const wallet_amount = _.sumBy(
      _.filter(payments, (item) => _.get(item, 'type') === source),
      'amount'
    );

    if (!wallet_amount) return 0;
    return formatVND(wallet_amount);
  },

  getBy: () => (itemData, source) => {
    const payments = _.get(itemData, 'payments', []);

    if (!_.get(payments, 'length')) return 0;

    const payment = _.filter(payments, (item) => _.get(item, 'type') === source);

    if (!payment) return null;
    return payment;
  },

  completedTransaction: () => (itemData) => {
    const status = _.get(itemData, 'status.0.status', []);
    return status === 'complete';
  },

  getStatus: () => (itemData) => {
    return _.get(itemData, 'status_latest.status', '');
  },

  datePurchase: () => (itemData) => {
    const created_at = _.get(itemData, 'created_at', '');
    if (!created_at) return '';
    return formatFullDateDisplay(created_at);
  },

  transactionId: () => (itemData) => {
    const id = _.get(itemData, 'id', '');
    return _.last(_.split(`${id}`, '-'));
  },

  advisorName: () => (itemData) => {
    const display_name = _.get(itemData, 'services.0.advisor_service.advisor.profile.display_name', '--');

    if (!display_name) return '--';
    return display_name;
  },

  packageName: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'services.0.per_amount', 0);

    if (!per_amount) return '';
    return ctx.apply('i18n.t', 'UserWallet.packageName', { time: _.round(normalizeNumber(per_amount) / 60) });
  },

  billTime: (ctx) => (itemData) => {
    const created_at = _.get(itemData, 'created_at', '');

    if (!created_at) return '';
    return formatFullDateDisplay(created_at, ctx);
  },

  createdAt: (ctx) => (itemData) => {
    const created_at = _.get(itemData, 'created_at', '');
    const format_by_locale = _.get(DATE_TIME_CREATED_FORMAT, moment.locale(i18n.locale), 'vi');
    if (!created_at) return '';
    return `${moment(created_at).locale(i18n.locale).format(format_by_locale)}`;
  },

  bill: (ctx) => (service) => {
    if (!service) return null;
    const makeBill = {
      amount: (servicePrice, discount) => {
        return {
          // ...discount,
          priceOriginal: servicePrice,
          pricePaid: servicePrice - _.get(discount, 'amount', 0),
          priceDiscount: _.get(discount, 'amount', 0),
          amount: _.get(discount, 'amount', 0),
        };
      },
      percentage: (servicePrice, discount) => {
        return {
          // ...discount,
          priceOriginal: servicePrice,
          pricePaid: Math.round(servicePrice - (servicePrice * _.get(discount, 'percentage', 0)) / 100),
          priceDiscount: Math.round((servicePrice * _.get(discount, 'percentage', 0)) / 100),
          percentage: _.get(discount, 'percentage', 0),
        };
      },
      normal: (servicePrice) => {
        return {
          priceOriginal: servicePrice,
          pricePaid: servicePrice,
        };
      },
    };
    let priceAmount = _.get(service, 'price.price_amount');
    const discount = _.get(service, 'discount', {});
    let bill = null;
    _.map(_.keys(makeBill), (key) => {
      if (discount[key]) {
        bill = makeBill[key](priceAmount, discount);
      }
    });
    if (!bill) bill = makeBill.normal(priceAmount);
    return bill;
  },
  normalizePayments: () => (itemData) => {
    const payments = _.filter(
      _.map(itemData, (item) => ({ ...item, amount: parseInt(_.get(item, 'amount', 0)) })),
      (item) => !!item.amount
    );
    return payments;
  },
  normalizeDiscounts: (ctx) => (service) => {
    const discount = _.get(service, 'discount', 0);
    if (!_.get(discount, 'id', false)) return [];
    return [discount];
  },
  normalizePaymentForm: () => (formValues) => {
    const payments = _.filter(_.get(formValues, 'payments', []), (item) => !!item.amount && !!item.selected).map(
      (item) => ({
        ..._.omit(item, ['selected']),
        amount: parseInt(_.get(item, 'amount', 0)),
      })
    );
    return {
      ..._.pick(formValues, ['user_id', 'service_id', 'advisor_id', 'discounts']),
      payments,
      discounts: _.get(formValues, 'service.discounts', []),
    };
  },
  normalizePaymentCourseForm: () => (formValues) => {
    const payments = _.filter(_.get(formValues, 'payments', []), (item) => !!item.amount && !!item.selected).map(
      (item) => ({
        ..._.omit(item, ['selected']),
        amount: parseInt(_.get(item, 'amount', 0)),
      })
    );
    return {
      ..._.pick(formValues, ['user_id', 'course_id', 'advisor_id', 'purchase_unit', 'purchase_amount', 'discounts']),
      payments,
      // discounts: _.get(formValues, 'course.discounts', []),
    };
  },
  normalizePaymentBookingForm: () => (formValues) => {
    const payments = _.filter(_.get(formValues, 'payments', []), (item) => !!item.amount && !!item.selected).map(
      (item) => ({
        ..._.omit(item, ['selected']),
        amount: parseInt(_.get(item, 'amount', 0)),
      })
    );
    return {
      ..._.pick(formValues, ['user_id', 'service_id', 'advisor_id', 'discounts', 'start_at', 'timezone']),
      payments,
      discounts: _.get(formValues, 'service.discounts', []),
    };
  },
  normalizeTopupForm: () => (formValues) => {
    const payments = _.filter(_.get(formValues, 'payments', []), (item) => !!item.selected).map((item) => ({
      ..._.omit(item, ['selected']),
      amount: parseInt(_.get(formValues, 'amount', 0)),
    }));
    return {
      ..._.pick(formValues, ['user_id', 'discounts']),
      payments,
    };
  },

  amount: (ctx) => (itemData) => {
    const amount = _.get(itemData, 'payments.0.amount', 0);
    if (!amount) return 0;

    return formatVND(amount);
  },

  amountStatementByName: (ctx) => (itemData, key) => {
    const statement = _.get(itemData, 'statement', []);

    if (!statement || statement.length === 0 || !key) return 0;

    const obj = _.find(statement, (item) => _.get(item, 'name') === key);

    return normalizeNumber(_.get(obj, 'amount'));
  },

  formatDiscountOptionLabel: (ctx) => (option) => {
    const { percentage, amount } = option;
    if (amount && percentage) {
      return ctx.apply('i18n.t', 'Payment.Discount.optionLabelDiscountPercentageMaxAmount', {
        amount: formatVND(amount),
        percentage,
      });
    }
    if (amount) {
      return ctx.apply('i18n.t', 'Payment.Discount.optionLabelDiscountAmount', {
        amount: formatVND(amount),
      });
    }
    if (percentage) {
      return ctx.apply('i18n.t', 'Payment.Discount.optionLabelDiscountPercentage', {
        percentage,
      });
    }
    return '';
  },
};

export default formatter;
