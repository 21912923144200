import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ElementTrackMan, getStreamId } from '@vl/mod-utils/deviceInfoWeb';
import OTPublisherActions from '@uz/unitz-components-web/RoomWebComponents/OTLocalPublisherScreenActions';
import OTPublisherHeader from '@uz/unitz-components-web/RoomWebComponents/OTPublisherHeader';
import StreamLayout from '@uz/unitz-layout-web/LayoutRoomStream';
import useLocalTracks from '@vl/hooks/useLocalTracks';

export const OTPublisherScreen = ({ properties }) => {
  const [ver, $ver] = React.useState(0);
  const localTracks = useLocalTracks();
  const eleRef = React.useRef();
  const ref = React.useRef({});
  const [isSharingScreen, $isSharingScreen] = React.useState(false);
  _.assign(ref.current, { isSharingScreen, $isSharingScreen, ver, $ver, eleRef });

  const attachVideo = () => {
    eleRef.current && ElementTrackMan(eleRef.current).attachMedia(ref.current.shareScreenStream);
  };

  const releaseTrack = (track) => {
    try {
      if (track) {
        track.stop();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const startShareScreen = async () => {
    const screenTrackPrev = ref.current.screenTrack;
    if (!ref.current.isSharingScreen) {
      ref.current?.$isSharingScreen && ref.current?.$isSharingScreen(true);
      try {
        // eslint-disable-next-line
        ref.current.shareScreenStream = await navigator.mediaDevices.getDisplayMedia({
          audio: false,
          video: {
            frameRate: 24,
            height: 1080,
            width: 1920,
          },
        });
        ref.current.screenTrack = _.get(ref.current.shareScreenStream?.getTracks(), 0);

        ref.current.screenTrack.onended = () => {
          properties.videoCallModel.setState('shareScreen', false);
        };
      } catch (err) {
        await properties.videoCallModel.setState('shareScreen', false);
      }
    }
    if (ref.current.screenTrack !== screenTrackPrev) {
      ref.current.$ver && ref.current.$ver(ref.current.ver + 1);
    }
    startAudio();
  };

  const stopShareScreen = async () => {
    ref.current?.$isSharingScreen && ref.current?.$isSharingScreen(false);
    releaseTrack(ref.current.screenTrack);
    eleRef.current && ElementTrackMan(eleRef.current).attachVideo(null);
    try {
      ref.current.screenTrack = null;
    } catch (err) {}
    stopAudio();
  };

  const startAudio = async () => {
    const audioTrackPrev = ref.current.audioTrack;
    ref.current.audioTrack = await localTracks.getLocalAudioTrack();
    eleRef.current && ElementTrackMan(eleRef.current).attachAudios([ref.current.audioTrack]);
    if (audioTrackPrev && audioTrackPrev?.name !== ref.current.audioTrack?.name) {
      releaseTrack(audioTrackPrev);
    }
  };

  const stopAudio = () => {
    releaseTrack(ref.current?.audioTrack);
    eleRef.current && ElementTrackMan(eleRef.current).attachAudios([]);
    try {
      ref.current.audioTrack = null;
    } catch (err) {}
  };

  React.useEffect(() => {
    startShareScreen();
    return () => {
      stopShareScreen();
      if (eleRef.current) {
        eleRef.current.srcObject = null;
      }
      if (ref.current.ctx) {
        ref.current.ctx.apply('REF.setRef', ref.current.stream_id, null);
      }
      ref.current = {};
    };
  }, []);
  const publishVideo = true;

  const videoRefCb = React.useCallback((vidRef) => {
    eleRef.current = vidRef;
    // ref is mount, notice for update
    attachVideo();
  }, []);

  if (!ref.current.screenTrack) return null;

  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ref.current.stream_id = getStreamId([{ track: _.get(ref.current, 'screenTrack') }, null], ctx);
      })}
      <StreamLayout.POS name={`stream-thumbs.${ref.current.stream_id}`}>
        <DIV forceCtx>
          {ctx.debug(() => {
            ref.current.ctx = ctx;
            ctx.set('@item', { stream_id: ref.current.stream_id });
            ctx.apply('REF.setRef', ref.current.stream_id, ref);
          })}
          {publishVideo && (
            <StreamLayout.POS name={`stream-videos.${ref.current.stream_id}`}>
              <div className="w-full h-full rounded-md">
                <video ref={videoRefCb} style={_.pick(properties, ['width', 'height'])} autoPlay />
              </div>
            </StreamLayout.POS>
          )}
          <div className="w-48 h-28 stream-item bg-transparent flex-shrink-0 p-1 rounded-md overflow-hidden">
            <div className="OTPublisher relative w-full h-full bg-gray-500 rounded-md overflow-hidden">
              <StreamLayout.RenderPOS name={`stream-videos.${ref.current.stream_id}`} />
              <OTPublisherHeader />
              <OTPublisherActions />
            </div>
          </div>
        </DIV>
      </StreamLayout.POS>
    </DIV>
  );
};

export default OTPublisherScreen;
