import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetAdvisorReview from 'unitz-gql/advisor_review/GetAdvisorReview.gql';

graphme.GqlBuilder.loadDocument({
  GetAdvisorReview
});

class AdvisorReviewModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'AdvisorReview',
    schema: {
      id: String
    },

    key: 'id',

    baseQuery: 'GetAdvisorReview',

    GQL_ACTIONS: {
      FIND: 'advisor_review',
      GET: 'advisor_review_by_pk',
      INSERT: 'insert_advisor_review_one',
      UPDATE: 'update_advisor_review_by_pk',
      DELETE: 'delete_advisor_review_by_pk'
    },

    getClient: hasuraClient.getClient
  });
}

graphme.model({ AdvisorReviewModel });

export default AdvisorReviewModel;
