import moment from 'moment';
import _ from 'lodash';
import { formatVND, durationFormatter, parseMilliseconds } from '@vl/mod-utils/currencyFormatter';
import { formatDateDisplay, formatFullDateDisplay } from '@vl/mod-utils/dateFormatter';
import { DATE_TIME_CREATED_FORMAT } from '@vl/mod-utils/datetime';
import i18n from 'i18n-js';

export const formatter = {
  id: (ctx) => (itemData) => {
    return _.get(itemData, 'id') || '';
  },
  createdAt: (ctx) => (itemData) => {
    return formatDateDisplay(_.get(itemData, 'created_at') || '');
  },

  createdAtWithDateTime: (ctx) => (itemData) => {
    const created_at = _.get(itemData, 'created_at', '');
    const format_by_locale = _.get(DATE_TIME_CREATED_FORMAT, moment.locale(i18n.locale), 'vi');

    if (!created_at) return '';
    return `${moment(created_at)
      .locale(i18n.locale)
      .format(format_by_locale)}`;
  },

  createdAtWithTime: (ctx) => (itemData) => {
    return formatFullDateDisplay(_.get(itemData, 'created_at') || '');
  },

  priceAmount: (ctx) => (itemData) => {
    return formatVND(_.get(itemData, 'price_amount') || 0);
  },

  serviceKind: (ctx) => (itemData) => {
    const kind = _.get(itemData, 'kind', '');
    if (!kind) return '';
    return kind;
  },

  discount: () => (itemData) => {
    const percentage = _.get(itemData, 'discounts.0.percentage', 0);
    if (!percentage) return 0;
    return `${percentage}%`;
  },

  reviewText: () => (itemData) => {
    return _.get(itemData, 'reviews.0.review_text', '--');
  },

  totalCash: () => (itemData) => {
    return formatVND(_.get(itemData, 'amount') || 0);
  },

  totalPaymentAmount: () => (itemData) => {
    const payments = _.get(itemData, 'payments');
    const amount = _.sumBy(payments, 'amount');
    return formatVND(amount || 0);
  },

  packageValue: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'per_amount', 0);
    const price_amount = _.get(itemData, 'price_amount', 0);
    if (!price_amount || !price_amount) return '';
    return `${Math.round(per_amount / 60)} ${ctx.apply('i18n.t', 'time.minute')}`;
  },

  status: (ctx) => (itemData) => {
    return _.get(itemData, 'status') || 'unknown';
  },

  timeCall: (ctx) => (itemData) => {
    const duration_amount = _.get(itemData, 'duration_amount', 0);

    if (!duration_amount) return '';
    return durationFormatter(duration_amount * 1000);
  },

  callPackageVoiceTime: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'duration_amount', 0);
    const per_unit = _.get(itemData, 'duration_unit', 'millisecond');
    if (!per_amount) return ctx.apply('voiceCallModel.getCallDurationTotal', '');
    return durationFormatter(per_amount, per_unit);
  },

  avgTime: (ctx) => (itemData) => {
    const total = _.sumBy(itemData, (item) => _.ceil(_.get(item, 'duration_amount', 0) / 60));
    return total;
  },

  callPackageTimeMinute: (ctx) => (itemData) => {
    let per_amount = _.get(itemData, 'duration_amount', 0);
    const per_unit = _.get(itemData, 'duration_unit', 'millisecond');
    if (per_unit === 'second') per_amount *= 1000;
    return _.get(parseMilliseconds(per_amount), 'minutes', 0);
  },

  statusCallCheck: (ctx) => (itemData) => {
    const statusData = {
      isConfirmed: _.get(itemData, 'status') === 'completed',
      isUnconfirmed: _.get(itemData, 'status') === 'incomplete' || _.get(itemData, 'status') === 'pending',
      isCancel: _.get(itemData, 'status') === 'reject',
    };
    return statusData;
  },

  statusCheck: (ctx) => (itemData) => {
    const statusData = {
      isConfirmed: _.get(itemData, 'status') === 'completed',
      isUnconfirmed: _.get(itemData, 'status') === 'incomplete' || _.get(itemData, 'status') === 'pending',
      isCanceled: _.get(itemData, 'status') === 'canceled',
      isRefund: _.get(itemData, 'status') === 'refund',
    };
    return statusData;
  },
  statusLatest: (ctx) => (itemData) => {
    return _.get(itemData, 'status_latest.status') || 'unknown';
  },
};

export default formatter;
