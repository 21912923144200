import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Button, Tooltip } from 'antd';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

const StyledSelect = styled.div`
  .ant-btn.ant-btn-sm {
    background-color: ${gstyles.colors.sub};
    border: none;
    border-radius: 0;
  }
`;

const handleFileDownload = (data, filename) => {
  const blob = new window.Blob([data], { type: 'text/json' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });
  const forceUpdate = useForceUpdate();

  const applicableTarget = (() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    return true;
  })();

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Tooltip
        title={
          <div className="space-x-2">
            <span>{i18n.t('Blackboard.export')}</span>
          </div>
        }
      >
        <Button
          size="small"
          onClick={async () => {
            const payload = canvas.exportCanvasPayload();
            const fileData = btoa(JSON.stringify(payload));
            handleFileDownload(fileData, `whiteboard_${canvas.sessionId}.txt`);
            forceUpdate();
            _onChange && _onChange();
          }}
          {...rest}
        >
          {gstyles.icons({ name: 'download', size: 20, fill: gstyles.colors.white500 })}
        </Button>
      </Tooltip>
    </StyledSelect>
  );
};

export default displayName(Index);
