import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import Combokeys from 'combokeys';
import { isActivateMode, isDeactivateMode } from '@uz/unitz-components-web/Fabric/helper';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 2px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const target = {};
    return target;
  });
  const ref = React.useRef({ disposers: [] });

  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return false;
  }, []);

  React.useEffect(() => {
    disposerCleaner(
      (() => {
        const combokeys = new Combokeys(window);

        combokeys.bind('esc', () => {
          if (canvas.isEraserMode) {
            canvas.toggleEraserMode(false);
            canvas.defaultCursor = 'default';
            canvas.fire('mode:changed', {
              mode: 'eraser',
              value: !!canvas.isEraserMode,
              source: 'eraser',
            });

            forceUpdate();
          }
          return false;
        });

        return () => {
          combokeys.detach();
        };
      })()
    );
    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        if (isActivateMode(event, 'eraser')) {
        } else if (isDeactivateMode(event, 'eraser')) {
          canvas.defaultCursor = 'default';
          canvas.isEraserMode = false;
        }
        forceUpdate();
      })
    );

    disposerCleaner(
      canvas.$on('selection:created', (event) => {
        if (canvas.isEraserMode) {
          const activeObject = event.target;
          // group remove
          if (_.get(activeObject, '_objects')) {
            for (const obj of activeObject._objects) {
              canvas.removeItems(obj);
            }
          }
          if (activeObject) {
            canvas.removeItems(activeObject);
          }
          canvas.discardActiveObject();
        }
        // forceUpdate();
      })
    );
  }, []);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  React.useEffect(() => {
    if (canvas.isEraserMode) {
      // canvas.defaultCursor = 'default';
      gstyles.Icons.getCursor({
        name: 'cursor-erase',
        fill: gstyles.colors.white500,
        size: 24,
      }).then((cursor) => {
        const textCursor = `url(${cursor}) 8 8, pointer`;
        canvas.defaultCursor = textCursor;
      });
    }
  }, [canvas.isEraserMode]);

  return (
    <MenuItem
      type={canvas.isEraserMode ? 'primary' : 'text'}
      getPopupContainer={(node) => node && node.parentElement}
      visible={ref.current.visible}
      trigger={['click']}
      onClick={() => {
        canvas.toggleEraserMode(!canvas.isEraserMode);
        canvas.fire('mode:changed', {
          mode: 'eraser',
          value: !!canvas.isEraserMode,
          source: 'eraser',
        });
      }}
      overlay={() => {
        return <div className="flex flex-row bg-white w-full"></div>;
      }}
      buttonsRender={([leftButton, rightButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.eraserTooltip')}</span>
            </div>
          }
          key="leftButton"
        >
          {leftButton}
        </Tooltip>,
        rightButton,
      ]}
      icon={<div className="flex justify-center"></div>}
      placement="topLeft"
    >
      <span>{gstyles.icons({ name: 'cursor-erase', size: 20, fill: gstyles.colors.white500 })}</span>
    </MenuItem>
  );
};

export default displayName(Index);
