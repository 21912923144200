import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import MessageModel from '@uz/unitz-tool-models/MessageModel';
import PaginationMessageModel from '@uz/unitz-tool-models/PaginationMessageModel';
import MessageModelFormatter from '@uz/unitz-tool-models/MessageModel/formatter';

import _ from 'lodash';

const bindData = bindings({
  componentContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: true }))),
            messageModelLoader: hook((ctx) => {
              const [v, $v] = React.useState(0);
              const ref = React.useRef({});
              _.assign(ref.current, { v, $v });
              return {
                getRoom: (room_id) => {
                  let pagingModel = _.get(ref, `current.room.${room_id}`);
                  if (!pagingModel) {
                    const windowSize = [31, 'day'];
                    const condition = `
                      where: {
                        created_at: {_neq: $window_start_at}
                        updated_at: {_neq: $window_end_at}
                        b2b_course_room: {id: {_eq: "${room_id}"}}
                      }
                      order_by: { created_at: asc_nulls_last }
                    `;
                    const dataQuery = `
                      message(${condition}) {
                        id
                        target_type
                        target_id
                        message
                        message_jsonb
                        deleted
                        updated_at
                        created_at
                        author {
                          id
                          user_id
                          avatar_url
                          display_name
                        }
                        reactions {
                          code_decimal
                          id
                        }
                        replies(order_by: { created_at: asc_nulls_last }) {
                          id
                          target_type
                          target_id
                          message
                          message_jsonb
                          deleted
                          updated_at
                          created_at
                          author {
                            id
                            user_id
                            avatar_url
                            display_name
                          }
                          reactions {
                            code_decimal
                            id
                          }
                        }
                      }
                    `;
                    pagingModel = PaginationMessageModel.fromConfig({
                      Model: MessageModel,
                      dataQuery,
                      windowSize,
                      subscription: true,
                    });

                    _.set(ref, `current.room.${room_id}`, pagingModel);
                    pagingModel.onLoadPageHandler();
                  }

                  pagingModel.useEffect(
                    () => {
                      if (!_.isEqual(ref.current.items, pagingModel.getState('items'))) {
                        ref.current.items = pagingModel.getState('items');
                        ref.current.$v(ref.current.v + 1);
                      }
                    },
                    () => [room_id, pagingModel.getState('items')]
                  );

                  return pagingModel;
                },
              };
            }),
            messageModel: hook((ctx) => {
              const messageControlModel = ctx.apply('REF.getRef', 'messageControlModelRef');
              const room_id = _.get(messageControlModel, 'target_id');

              const pagingModel = ctx.apply('messageModelLoader.getRoom', room_id);

              pagingModel.hookState();

              const items = _.takeRight(pagingModel.useState('items'), 2000);

              const courseRoomData = ctx.get('courseRoomData');
              return {
                items: MessageModelFormatter.formatMessages(ctx)(items),
                message_marks: _.groupBy(_.get(courseRoomData, 'marks'), 'mark_title'),
                isLoading: pagingModel.useState('isLoading'),
                handlers: {
                  onNavigate: (...args) => pagingModel.onNavigate(...args),
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
