import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import OTStreamActions from '@uz/unitz-components-web/RoomWebComponents/OTLocalSubscriberStreamActions';
import StyledSubscriberStreamContainer from '@uz/unitz-components-web/RoomWebComponents/StyledSubscriberStreamContainer';
import StreamLayout from '@uz/unitz-layout-web/LayoutRoomStream';

export const OTSubscriberStream = ({ stream_id }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { stream_id });

  React.useEffect(() => {
    return () => {
      ref.current = {};
    };
  }, []);

  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
        ctx.set('@item', {
          identity: ref.current.stream_id,
          stream_id: ref.current.stream_id,
        });
      })}
      <div className="w-full h-full bg-transparent flex-shrink-0 px-1 overflow-hidden">
        <StyledSubscriberStreamContainer className="OTSubscriberStream relative w-full h-full overflow-hidden">
          <StreamLayout.RenderPOS name={`stream-videos.${ref.current.stream_id}`}>
            {(ele) => {
              return ele;
            }}
          </StreamLayout.RenderPOS>
          <OTStreamActions />
        </StyledSubscriberStreamContainer>
      </div>
    </DIV>
  );
};

export default OTSubscriberStream;
