import React from 'react';
import { bindings, hook } from '@vl/redata';
import VideoCallModel from '@uz/unitz-models/VideoCallWebModel';
import _ from 'lodash';

const bindData = bindings({
  videoComponent: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
  videoCallIniting: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallIniting)),
        },
      ],
    ],
  },
  videoCallConnecting: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) =>
            ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallConnecting)
          ),
        },
      ],
    ],
  },
  videoCallIncomming: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) =>
            ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallIncomming)
          ),
        },
      ],
    ],
  },
  videoCallTaking: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallTaking)),
        },
      ],
    ],
  },
  videoCallOnProgress: {
    rules: [
      [
        'display',
        {
          // display: hook(
          //   (ctx) => ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallOnProgress)
          //     && ctx.apply('videoCallModel.isConnected')
          // ),
          display: true,
        },
      ],
    ],
  },

  videoCallOnProgressOffCamera: {
    rules: [
      [
        'display',
        {
          display: hook(
            (ctx) =>
              ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallOnProgress) &&
              !(
                ctx.apply('videoCallModel.useState', 'remoteState.videoMute') && ctx.apply('videoCallModel.isConnected')
              )
          ),
        },
      ],
    ],
  },

  videoCallDisconnect: {
    rules: [
      [
        'display',
        {
          display: hook(
            (ctx) =>
              ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallFinish) &&
              ctx.apply('videoCallModel.isDisconnected')
          ),
        },
      ],
    ],
  },

  sessionReconnect: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('videoCallModel.isConnecting')),
        },
      ],
    ],
  },

  callSession: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallFinish)),
        },
      ],
    ],
  },
});

export default bindData;
