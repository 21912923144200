import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import useTravelStore from '@vl/hooks/useTravelStore';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 32px !important;
    height: 32px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const target = {};
    return target;
  });
  const ref = React.useRef({ disposers: [] });
  const [travelStore] = useTravelStore(`whiteboard_${canvas.sessionId}`);

  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return false;
  }, []);

  const visible = getVisible();
  _.assign(ref.current, { visible });
  const { redoCount } = travelStore.reduce((store) => {
    return {
      redoCount: store.getRedoCount(),
    };
  });

  return (
    <MenuItem
      type={'text'}
      getPopupContainer={(node) => node && node.parentElement}
      disabled={redoCount === 0}
      trigger={['click']}
      onClick={() => {
        redoCount && travelStore.redo();
        forceUpdate();
      }}
      overlay={() => {
        return null;
      }}
      buttonsRender={([leftButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.redoTooltip')}</span>
            </div>
          }
          key="leftButton"
        >
          {leftButton}
        </Tooltip>,
        <div key="rightButton" />,
      ]}
      placement="topLeft"
    >
      <span>
        {gstyles.icons({
          name: 'redo',
          size: 20,
          fill: gstyles.colors.white500,
        })}
      </span>
    </MenuItem>
  );
};

export default displayName(Index);
