import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import GLightBox from '@uz/unitz-components-web/Glightbox/loader';

import createLayout from '@vl/mod-utils/createLayout';
import useWindowSize from '@vl/hooks/useWindowSize';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

const FullInputWrapper = ({ children }) => {
  const windowSize = useWindowSize();
  const minWidth = Math.min(420, windowSize.width);
  const maxWidth = Math.min(420 * 1.5, windowSize.width);

  const [layoutState, $layoutState] = useLocalStorage('@UZ::LayoutRoomCall::ContentStart', {
    visible: false,
    width: minWidth,
  });
  const fullInput = {};
  const freeWidth = layoutState.visible ? windowSize.width - layoutState.width : windowSize.width;
  if (freeWidth >= minWidth) {
    fullInput.width = Math.min(freeWidth, maxWidth);
    fullInput.left = windowSize.width - freeWidth;
  } else if (windowSize.width / 2 > minWidth) {
    fullInput.width = Math.floor(windowSize.width / 2);
    fullInput.left = fullInput.width;
    $layoutState({
      ...layoutState,
      width: fullInput.width,
    });
  } else {
    fullInput.width = windowSize.width;
    fullInput.left = 0;
  }

  return (
    <div className="absolute inset-0 bg-white" style={fullInput}>
      {children}
    </div>
  );
};

const LayoutWidgetMessage = createLayout(({ children }) => {
  return (
    <DIV>
      <GLightBox />
      <div>{children}</div>
      <div className="flex w-full h-full" id="message_wrapper">
        <div className="w-full h-full relative">
          <div className="flex flex-col inset-0 absolute">
            <LayoutWidgetMessage.RenderPOS name="message-container" />
            {ctx.get('messageControlModel.fullInput') ? (
              <div className="w-full h-1" />
            ) : (
              <LayoutWidgetMessage.RenderPOS name="message-input" />
            )}
          </div>
        </div>
        {ctx.get('messageControlModel.fullInput') && (
          <FullInputWrapper>
            <LayoutWidgetMessage.RenderPOS name="message-input" />
          </FullInputWrapper>
        )}
      </div>
    </DIV>
  );
});

export default LayoutWidgetMessage;
