import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import VideoCallOnProgress from './components/VideoCallOnProgress';
// import VideoCallSession from './components/VideoCallSession';
import ZoomVideoWeb from '@uz/zoom-video-web/loader';

const Index = () => {
  return (
    <DIV className="videoComponent">
      <div
        className="drag-bounds"
        style={{
          position: 'fixed',
          zIndex: -100,
          top: 0,
          left: 0,
          width: _.get(ctx.apply('LAYOUT.getLayout', 'AdvisorVideoCallStatus'), 'width', 0),
          height: _.get(ctx.apply('LAYOUT.getLayout', 'AdvisorVideoCallStatus'), 'height', 0),
          background: 'transparent',
        }}
      />
      {/* <VideoCallSession /> */}
      <ZoomVideoWeb />
      <VideoCallOnProgress />
    </DIV>
  );
};

export default displayName(Index);
