import _ from 'lodash';

export const bindModeEvents = (canvas) => {
  canvas.on('mode:changed', (event) => {
    const { value } = event;
    if (value) {
      canvas.toggleOverlay(false);
    } else {
      canvas.toggleOverlay(true);
    }
  });
};
