import Loadable from '@loadable/component';

const Component = Loadable(() => import('./Component.js'));

Component.onLoad = (cb) => {
  if (!window || !window.fabric) {
    setTimeout(() => {
      Component.onLoad(cb);
    }, 1000);
  } else {
    cb();
  }
};

export default Component;
