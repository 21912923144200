import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Glightbox from '@uz/unitz-components-web/Glightbox';
import CollabCursor from '@uz/unitz-tool-components/Collab/Events/Cursor';
import CollabPlyr from '@uz/unitz-tool-components/Collab/Events/Plyr';
import CollabIframe from '@uz/unitz-tool-components/Collab/Events/Iframe';
import CollabWhiteboard from '@uz/unitz-tool-components/Collab/Events/Whiteboard';
import CollabLead from '@uz/unitz-tool-components/Collab/Events/Lead';

import GundbClient from '@vl/mod-clients/gundb';
import SparkMD5 from 'spark-md5';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import useForceUpdate from '@vl/hooks/useForceUpdate';

const { getLeadId } = CollabLead;

export const Collab = ({ sessionId }) => {
  const ref = React.useRef({ diposers: [] });
  const disposerCleaner = useDisposerCleaner();
  const forceUpdate = useForceUpdate();

  const trackKey = (key) => {
    _.update(ref.current, `trackMap.${key}`, (node) => {
      if (node) return node;
      const docRef = GundbClient.getClient().getDoc(`GLightbox_${sessionId}_${key}`);
      if (docRef) {
        docRef.on((item) => {
          const id = _.get(item, 'id');
          const action = _.get(item, 'action');
          if (id === getLeadId()) {
            const instance = Glightbox.getInstance();
            if (instance) {
              if (action === 'open') {
                instance.openAt(item.openAt);
              } else if (action === 'close') {
                instance.close();
              }
            }
          }
        });
        return () => {
          // docRef.off();
          // delete cusor
        };
      }
    });
  };

  React.useEffect(() => {
    const { ctx } = ref.current;
    if (!ctx) return () => {};

    const userId = ctx.apply('authModel.getUserId');
    const docRef = GundbClient.getClient().getDoc(`GLightbox_${sessionId}_${userId}`);
    // setup listener
    const sessionRef = GundbClient.getClient().getDoc(`sessions_${sessionId}`);
    sessionRef.map().on((item) => {
      const id = _.get(item, 'id');
      if (id === userId) return;
      trackKey(id);
    });
    if (userId) {
      sessionRef.get(userId).put({ id: userId, value: userId });
    }

    disposerCleaner(Glightbox.on('open', (...args) => {}));
    disposerCleaner(
      Glightbox.on('close', (...args) => {
        ref.current.slideHashId = null;
        docRef.put({
          id: userId,
          action: 'close',
        });
        forceUpdate();
      })
    );
    disposerCleaner(
      Glightbox.on('slide_changed', ({ current }) => {
        const { slideIndex, player, slideConfig, slide } = current;
        ref.current.activeSlideIndex = slideIndex;
        const slideHashId = SparkMD5.hash(JSON.stringify(slideConfig));
        ref.current.slideHashId = slideHashId;
        ref.current.activePlayer = player;
        ref.current.activeIframe = null;

        const slideMedia = slide && slide.querySelector('.gslide-media');
        const img = slide && slide.querySelector('img');

        // whiteboard for image
        if (slideMedia && img) {
          slideMedia.id = slideHashId;
        }

        const slideIframe = slide && slide.querySelector('iframe');
        // whiteboard for viewer
        if (slideIframe) {
          const slideSessionId = `${sessionId}_${slideHashId}`;
          const viewerOrigin = 'https://unitz-asset.web.app';
          slideIframe.contentWindow.postMessage(
            {
              action: 'collab:config',
              payload: {
                sessionId: slideSessionId,
                leadId: getLeadId(),
                userId,
              },
            },
            viewerOrigin
          );
          slideMedia.id = slideHashId;
          ref.current.activeIframe = slideIframe;

          const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
          const eventer = window[eventMethod];
          const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

          eventer(messageEvent, (evt) => {
            const { origin } = evt;
            if (origin === viewerOrigin) {
              const { data } = evt;
              const event = new window.CustomEvent('viewer_sync', {
                bubbles: true,
                detail: { data },
              });
              slideIframe.dispatchEvent(event);
            }
          });
        }

        docRef.put({
          id: userId,
          action: 'open',
          openAt: slideIndex,
        });
        forceUpdate();
      })
    );
    disposerCleaner(
      Glightbox.on('slide_inserted', (...args) => {
        // console.log('slide_inserted', args);
      })
    );
    disposerCleaner(
      Glightbox.on('slide_removed', (...args) => {
        // console.log('slide_removed', args);
      })
    );
    return () => {
      sessionRef.off();
      _.map(ref.current.trackMap, (disposer) => {
        disposer && disposer();
      });
      ref.current = {};
    };
  }, []);
  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
      {/* <CollabCursor
        targetId="glightbox-body"
        selector=".gslide.current .gslide-inner-content .gslide-media"
        sessionId={sessionId}
      /> */}

      {_.map(_.compact([ref.current.slideHashId]), (targetId) => {
        const slideSessionId = `${sessionId}_${targetId}`;
        return (
          <React.Fragment key={targetId}>
            {React.createElement(
              CollabLead,
              { isLead: ctx.apply('authModel.getUserId') === getLeadId(), sessionId: slideSessionId },
              null
            )}
          </React.Fragment>
        );
      })}
      <CollabPlyr sessionId={sessionId} />
      {/* <CollabIframe sessionId={sessionId} /> */}

      {/* {_.map(_.compact([ref.current.slideHashId]), (targetId) => {
        const slideSessionId = `${sessionId}_${targetId}`;
        return (
          <React.Fragment key={targetId}>
            {React.createElement(CollabIframe, { targetId, sessionId: slideSessionId }, null)}
          </React.Fragment>
        );
      })} */}
      {_.map(_.compact([ref.current.slideHashId]), (targetId) => {
        const slideSessionId = `${sessionId}_${targetId}`;
        // get fit level
        return (
          <React.Fragment key={targetId}>
            {React.createElement(
              CollabWhiteboard,
              {
                targetId,
                sessionId: slideSessionId,
                isFit: true,
                isOverlay: !!ref.current.activeIframe,
              },
              null
            )}
          </React.Fragment>
        );
      })}
    </DIV>
  );
};

export default Collab;
