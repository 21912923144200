const config = (fabric) => {
  fabric.Canvas.prototype.setZoom = (function(originalFn) {
    return function(...args) {
      originalFn.call(this, ...args);
      if (this.collab) {
        this.collab.setZoom(...args);
      }
      return this;
    };
  })(fabric.Canvas.prototype.setZoom);

  fabric.Canvas.prototype.relativePan = (function(originalFn) {
    return function(...args) {
      originalFn.call(this, ...args);
      if (this.collab) {
        this.collab.relativePan(...args);
      }
      return this;
    };
  })(fabric.Canvas.prototype.relativePan);

  fabric.Canvas.prototype.absolutePan = (function(originalFn) {
    return function(...args) {
      originalFn.call(this, ...args);
      if (this.collab) {
        this.collab.absolutePan(...args);
      }
      return this;
    };
  })(fabric.Canvas.prototype.absolutePan);

  fabric.Canvas.prototype.getAbsoluteCoords = function(object) {
    const [scaleX, skewX, skewY, scaleY, left, top] = this.viewportTransform;
    return {
      // left: object.left + this._offset.left,
      // top: object.top + this._offset.top,
      left: object.left + left,
      top: object.top + top,
    };
  };
};

export default config;
