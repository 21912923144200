import React from 'react';
import _ from 'lodash';
import createLayout from '@vl/mod-utils/createLayout';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import OTStreamsSlider from '@uz/unitz-components-web/RoomWebComponents/OTStreamsSlider';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 9999999;
  height: 1px;
  position: fixed;
  right: 1rem;
  top: 4rem;
`;

export const Layout = createLayout(({ children }) => {
  return (
    <DIV forceCtx>
      <div id="LayoutRoomStream">{children}</div>
      <Container className="fixed w-auto" id="room-stream-thumbs">
        <Layout.RenderPOS name="stream-thumbs">
          {(ele) => {
            if (!ele) return null;
            return <OTStreamsSlider ele={ele} />;
          }}
        </Layout.RenderPOS>
      </Container>
      <div className="absolute inset-0" id="room-stream-slides">
        <Layout.RenderPOS name="stream-slides" />
      </div>
      {/* <Layout.RenderPOS name="content-end" /> */}
    </DIV>
  );
});

export default Layout;
