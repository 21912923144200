const config = (fabric) => {
  function toObjectObject(toObject) {
    return function fn() {
      const isLocal = this.isLocal;
      return fabric.util.object.extend(toObject.call(this), {
        id: this.id, // object id
        owner_id: this.owner_id, // owner id
        ...(isLocal ? { isLocal } : {}),
      });
    };
  }

  fabric.Object.prototype.toObject = toObjectObject(fabric.Object.prototype.toObject);
};

export default config;
