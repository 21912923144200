import React from 'react';
import _ from 'lodash';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import UserModel from '@uz/unitz-models/UserModel';
import UserProfileModelFormatter from '@uz/unitz-models/UserProfileModel/formatter';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import { DataLoader } from '@vl/mod-utils/DataLoader';

const Index = ({ user_id, query_str, showName }) => {
  if (query_str) {
    const urlParams = new URLSearchParams(query_str);
    user_id = urlParams.get('user_id') || user_id;
    user_id = urlParams.get('advisor_id') || user_id;
  }

  const [fetching, $fetching] = React.useState(false);
  const [user, $user] = React.useState(null);
  const ref = React.useRef({});
  const forceUpdate = useForceUpdate();

  _.assign(ref.current, {
    user_id,
    fetching,
    $fetching,
    user,
    $user,
  });

  const fetchUserById = async () => {
    ref.current.$fetching && ref.current.$fetching(true);
    let user;
    try {
      user = await DataLoader(UserModel, async (user_id) => {
        const queryRes = await UserModel.find(
          `where: {id: { _eq: "${user_id}" }}`,
          `
          id 
          profile {
            display_name
            avatar_url
          }
        `
        );
        const data = _.get(queryRes.toObject(), 0);
        return data;
      }).load(ref.current.user_id);

      ref.current.$user && ref.current.$user(user);
    } catch (error) {
      console.log('error', error);
    } finally {
      ref.current.$fetching && ref.current.$fetching(false);
    }
    return user;
  };

  React.useEffect(() => {
    user_id && fetchUserById().then(() => forceUpdate());
  }, [user_id]);

  React.useEffect(() => {
    return () => {
      ref.current = {};
    };
  }, []);

  return (
    <DIV className="componentContainer">
      {(() => {
        if (_.isFunction(showName)) {
          return showName(ref.current.user);
        }
        return UserProfileModelFormatter.displayName(ctx)(ref.current.user);
      })()}
    </DIV>
  );
};

Index.getDisplayName = _.memoize(async (user_id) => {
  let user;
  try {
    user = await DataLoader(UserModel, async (user_id) => {
      const queryRes = await UserModel.find(
        `where: {id: { _eq: "${user_id}" }}`,
        `
        id 
        profile {
          display_name
          avatar_url
        }
      `
      );
      const data = _.get(queryRes.toObject(), 0);
      return data;
    }).load(user_id);
  } catch (error) {
    console.log('error', error);
  } finally {
  }
  return UserProfileModelFormatter.displayName(ctx)(user);
});

export default displayName(Index);
