import React from 'react';
import _ from 'lodash';

export const useDisposerCleaner = () => {
  const disposers = React.useState([]);

  React.useEffect(() => {
    return () => {
      for (let disposer of disposers) {
        if (_.isFunction(disposer)) {
          disposer();
        }
      }
    };
  }, []);
  const disposerMan = (item) => {
    disposers.push(item);
  };
  return disposerMan;
};

export default useDisposerCleaner;
