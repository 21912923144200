import React from 'react';

import { message as MessageAlert, Anchor, Tabs } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import hasBorderScroll from '@vl/hooks/hasBorderScroll';
import MessageList from '@uz/unitz-tool-components/Message/List';
import MessageMenu from '@uz/unitz-tool-components/Message/Menu';
import MessageHeader from '@uz/unitz-tool-components/Message/Header';
import { ReactionPickerLoader } from '@uz/unitz-tool-components/Message/Components/MessageReactionPicker';
import styled from 'styled-components';
import MessageStore from '@uz/unitz-tool-components/Message/Store';

import './style.css';

const useNewMessageCounter = ({ messages, shouldCount, showMessageCount }) => {
  const ref = React.useRef({});
  const latestMessageId = _.get(_.last(messages), 'id');
  let newMessageCounter = 0;
  let firstNewMessageIndex = messages.length - 1;
  if (shouldCount) {
    firstNewMessageIndex = _.findLastIndex(messages, { id: ref.current.lastSeenMessageId });
    newMessageCounter = messages.length - firstNewMessageIndex - 1;
    ref.current.firstNewMessageIndex = firstNewMessageIndex;
  } else {
    ref.current.lastSeenMessageId = latestMessageId;
  }
  _.assign(ref.current, { showMessageCount });

  React.useEffect(() => {
    console.log('newMessageCounter', newMessageCounter);
    if (_.isFunction(ref.current.showMessageCount) && newMessageCounter) {
      ref.current.showMessageCount(newMessageCounter, ref.current.firstNewMessageIndex);
    }
  }, [newMessageCounter]);

  const resetCounter = () => {
    ref.current.lastSeenMessageId = _.get(_.last(messages), 'id');
  };

  React.useEffect(() => {
    return () => {
      ref.current = {};
    };
  }, []);

  return {
    newMessageCounter,
    firstNewMessageIndex,
    resetCounter,
  };
};

const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    & > .ant-tabs-nav {
      margin-bottom: 0px;
    }
  }
`;

const StyledTabContent = styled.div`
  height: 100%;
  width: 100%;
`;

// padding-top: 100px;

const MessageContainer = ({ messages }) => {
  const bottomRef = React.useRef();
  const containerRef = React.useRef();
  const ref = React.useRef({ messages, localMessages: [] });
  const { isScrollBottom } = hasBorderScroll(containerRef.current, true);
  const isBottomHidden = () => {
    if (bottomRef.current) {
      const bound = bottomRef.current.getBoundingClientRect();
      return bound.width < 2;
    }
    return false;
  };

  const showMessageCount = (counter) => {
    const messageKey = 'message';
    MessageAlert.info({
      key: messageKey,
      content: ref.current.ctx.apply('i18n.t', 'Message.newMessageAlertLabel', { count: counter }),
      duration: 10,
      onClick: () => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight + 1000;
        }
        MessageAlert.destroy(messageKey);
        // try to display message container
        const messageControlModelRef = ref.current.ctx.apply('REF.getRef', 'messageControlModelRef');
        if (messageControlModelRef) {
          messageControlModelRef.show();
        }
      },
    });
    require('@vl/mod-clients/sound')
      .default({
        src: ['message-pop.wav'],
        volume: 0.7,
      })
      .play();
  };
  const shouldCount = !isScrollBottom || isBottomHidden();
  useNewMessageCounter({ messages, shouldCount, showMessageCount });

  const followScrollPosition = () => {
    if (ref.current.isScrollBottom) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight + 1000;
    }
  };
  _.assign(ref.current, { messages, isScrollBottom, followScrollPosition });

  React.useEffect(() => {
    followScrollPosition();
  }, [ref.current.messages.length, ref.current.localMessages.length, isScrollBottom]);

  const [tabKey, $tabKey] = React.useState('rooms');

  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.containerRef = containerRef;
        ctx.set('messageContainerRef', ref);
        // ctx.set('messageContainerRef', ref);
        ctx.apply('REF.setRef', 'messageContainerRef', ref);
        ref.current.ctx = ctx;
      })}
      <div className="flex-1 flex flex-col overflow-y-hidden overflow-x-hidden relative" ref={containerRef} id="messageContainerRef">
        {/* <Anchor
          className="bg-white500"
          getContainer={() => {
            if (containerRef.current) {
              return containerRef.current;
            }
            return window;
          }}
        > */}
        <div className="w-full bg-white500 relative">
          <MessageHeader />
          <StyledTabs
            // onTabClick={(val) => {
            //   $tabKey('');
            //   setTimeout(() => $tabKey(val), 1000 * 0.2);
            // }}
            onChange={(val) => {
              $tabKey('');
              setTimeout(() => $tabKey(val), 1000 * 0.2);
            }}
            animated={false}
            tabPosition="top"
          >
            <Tabs.TabPane
              tab={<div className="px-2 text-base">{ctx.apply('i18n.t', 'Message.tabPaneLabels.rooms')}</div>}
              key="rooms"
              forceRender
            >
              {/* <MessageMenu messages={ctx.get('messageModel.items')} /> */}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div className="px-2 text-base">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Detail.materials')}</div>
              }
              key="materials"
              forceRender
            ></Tabs.TabPane>
          </StyledTabs>
        </div>
        {/* </Anchor> */}
        <div className="h-full w-full">
          {tabKey === 'rooms' && (
            <StyledTabContent>
              <ReactionPickerLoader />
              <MessageList messages={[...ref.current.messages, ...ref.current.localMessages]} />
              <div style={{ float: 'left', clear: 'both', width: '100%' }} ref={bottomRef}></div>
            </StyledTabContent>
          )}
          {tabKey === 'materials' && (
            <StyledTabContent>
              <MessageList
                messages={(() => {
                  const rtn = [];
                  const materials = [
                    ...ctx.get('courseRoomData.course.materials', []),
                    ...ctx.get('courseRoomData.materials', []),
                  ];
                  materials.map((item, index) => {
                    const type = _.get(item, 'type');
                    const blockType = `${type}`.indexOf('image') === 0 ? 'block-image' : 'block-file';
                    const message = {
                      id: `attachment-${index}`,
                      message: 'message',
                      reactions: [],
                      replies: [],
                      message_jsonb: {
                        ops: [
                          {
                            attributes: { size: '' },
                            insert: {
                              [blockType]: {
                                id: _.get(item, 'uid'),
                                src: _.get(item, 'url'),
                                ..._.pick(item, ['name', 'size', 'type']),
                                ...(blockType === 'block-image' ? { width: '120' } : {}),
                              },
                            },
                          },
                        ],
                      },
                      target_id: ctx.get('courseRoomData.id'),
                      target_type: 'course_room',
                    };
                    MessageStore.set(message.id, message);
                    rtn.push(message);
                  });
                  const messages = [...ref.current.messages, ...ref.current.localMessages];
                  _.map(messages, (message) => {
                    const item = _.get(message, 'message_jsonb.ops');
                    let hasAttachment = false;
                    _.map(item, (op) => {
                      if (_.get(op, 'insert.block-file') || _.get(op, 'insert.block-image')) {
                        hasAttachment = true;
                      }
                    });
                    if (hasAttachment) {
                      rtn.push(message);
                    }
                  });
                  return rtn;
                })()}
              />
              <div style={{ float: 'left', clear: 'both', width: '100%' }} ></div>
            </StyledTabContent>
          )}
        </div>
      </div>
    </DIV>
  );
};

export default MessageContainer;
