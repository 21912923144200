import React from 'react';
import _ from 'lodash';

export const useDeferCloseSelect = (delay = 1000) => {
  const [open, $open] = React.useState(false);
  const ref = React.useRef({});
  const onDropdownVisibleChange = React.useCallback((value) => {
    if (value) {
      $open(value);
      ref.current.lastOpen = Date.now();
    } else {
      const now = Date.now();
      if (!ref.current.lastOpen || now - ref.current.lastOpen > delay) {
        $open(value);
      }
    }
  }, [open]);
  return { open, onDropdownVisibleChange };
};
