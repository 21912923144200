import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';
import displayName from '@vl/redata/displayName.macro';

const StyledSelect = styled.div`
  border: none;
`;

const Index = ({ target, canvas, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });
  const forceUpdate = useForceUpdate();

  const applicableTarget = (() => {
    if (_.get(ref.current.target, 'viewOnly')) return true;
  })();

  _.assign(ref.current, { applicableTarget, owner_id: _.get(ref.current, 'target.owner_id') });

  React.useEffect(() => {
    if (ref.current.applicableTarget && ref.current.owner_id) {
      UserIdentity.getDisplayName(ref.current.owner_id).then((val) => {
        _.set(ref.current, ['owners', ref.current.owner_id], val);
        forceUpdate();
      });
    }
  }, [ref.current.owner_id, ref.current.applicableTarget]);

  if (!applicableTarget) return null;

  return (
    <StyledSelect className="mt-3">
      <span className="bg-black500 bg-opacity-50 text-white p-1" {...rest}>{`${_.get(ref.current, [
        'owners',
        ref.current.owner_id,
      ])}`}</span>
    </StyledSelect>
  );
};

export default displayName(Index);
