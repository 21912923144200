import React from 'react';

import { bindings, hook } from '@vl/redata';
import AdvisorFormatter from '@uz/unitz-models/AdvisorModel/formatter';
import AppReviewTagModel from '@uz/unitz-models/AppReviewTagModel';
import useRoute from '@vl/hooks/useGbRoute';
import { useFormik } from 'formik';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import * as Yup from 'yup';
import _ from 'lodash';
// import { CombineLatestOperator } from 'rxjs/internal/observable/combineLatest';
import { transformArrayString } from '@vl/mod-utils/graphQLHelper';

const formRef = {
  prevValues: {},
  currValues: {},
  addValues: (val) => {
    const prevValues = _.cloneDeep(val);
    formRef.currValues = _.cloneDeep(val);
    setTimeout(() => {
      formRef.prevValues = prevValues;
    });
  },
  isChanged: (...args) => {
    const [dataPath, toVal] = args;
    const prevVal = _.get(formRef.prevValues, dataPath);
    const newVal = _.get(formRef.currValues, dataPath);
    const isChanged = !_.isEqual(prevVal, newVal);
    if (args.length === 2) {
      return isChanged && _.isEqual(toVal, newVal);
    }
    return isChanged;
  },
};

const formState = (ctx) => (formValues) => {
  formRef.addValues(formValues);
  let { rating, suggestions, ...formVal } = formValues;
  let title = '';
  let label = '';
  let suggestionRender = [];
  const ratingSuggestion = AdvisorFormatter.ratingSuggestion(ctx)(rating);
  title = _.get(ratingSuggestion, 'title', '');
  label = _.get(ratingSuggestion, 'label', '');
  if (formRef.isChanged('rating')) {
    suggestions = [];
  }
  if (formRef.isChanged('suggestions') || formRef.isChanged('rating')) {
    const tags = ctx.get('suggestions')
      ? _.get(
        ctx.get('suggestions').filter((item) => item.score === rating * 2),
        '0.review_tags',
        []
      )
      : [];
    suggestionRender = _.map(tags, (text) => ({
      text,
      selected: _.findIndex(suggestions, (suggest) => suggest === text) !== -1,
    }));
  }
  return {
    ...formVal,
    rating,
    title,
    label,
    suggestions,
    suggestionRender,
  };
};

const bindData = bindings({
  ratingFlatformPage: {
    rules: [
      [
        'data',
        {
          data: {
            suggestions: hook((ctx) => {
              const reviewTags = usePromiseSource(async () => {
                try {
                  const tags = await AppReviewTagModel.find(
                    '',
                    `
                      score
                      review_tags
                    `
                  );
                  return tags.toObject();
                } catch (err) {}
              });
              return reviewTags;
            }),
            form: hook((ctx) => {
              const advisor_id = _.get(useRoute(), 'params.id', '');
              const purchase_id = _.get(useRoute(), 'params.purchase_id', '');
              const user_id = ctx.apply('authModel.getUserId');
              const initialValues = React.useMemo(
                () => formState(ctx)({
                  rating: '5',
                  note: '',
                  suggestions: [],
                }),
                []
              );
              const form = useFormik({
                initialValues,
                onSubmit: async (values) => {
                  try {
                    // TODO: Rating Here.
                    const data = await AppReviewTagModel.addReview({
                      target_id: advisor_id,
                      review_text: _.get(values, 'note', ''),
                      score: _.get(values, 'rating', 0) * 2,
                      created_by: user_id,
                      context_id: purchase_id,
                      context_type: 'purchase',
                      review_tags: transformArrayString(form.values.suggestions),
                    });
                    if (data) {
                      ctx.apply('page.handlers.ratingFinish');
                    }
                  } catch (error) {
                    console.log(error);
                  }
                },
                validationSchema: Yup.object().shape({
                  rating: Yup.number().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });

              // Add more handle change for form
              _.assign(form, {
                onChangeSuggestion: (item) => {
                  const suggestions = _.xor(ctx.get('form.values.suggestions', []), [item.text]);
                  ctx.apply('form.setFieldValue', 'suggestions', suggestions);
                },
              });

              React.useEffect(() => {
                const formValues = formState(ctx)(form.values);
                form.setValues(formValues);
              }, [JSON.stringify({ values: form.values })]);

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
