import _ from 'lodash';

import {
  getOwnerId,
  toCanvasPayload,
  addObjectsToCanvas,
  fromCanvasPayload,
  syncObjectsToCanvas,
  syncCanvasPayload,
  toObjectPayload,
  fromObjectPayload,
  ensureHtmlCanvas,
  configFitZoom,
} from '../utils';

export const bindObjectEvents = (canvas) => {
  const userId = getOwnerId();
  canvas.$on('object:added', (options) => {
    const { target } = options;
    canvas.ensureTarget(target);
    // if (target.strokeWidth === 4) {
    //   const objects = [];
    //   _.times(1000).map(() => {
    //     const cloneTarget = _.cloneDeep(target);
    //     cloneTarget.strokeWidth = 5;
    //     cloneTarget.id = _.uniqueId(`${cloneTarget.id}_`);
    //     objects.push(cloneTarget);
    //   });
    //   addObjectsToCanvas(canvas, objects);
    // }

    const obj = options.target.toJSON();
    if (obj.owner_id === userId && !obj.isLocal) {
      target.saveState();
      canvas.saveCanvasPayload();

      const evt = {
        id: userId,
        action: 'object:added',
        payload: toObjectPayload(obj),
      };

      canvas.eventRef.putEvent(evt);
      canvas.travelStore.do(evt);
    }
  });

  canvas.$on('object:modified', (options) => {
    const obj = options.target.toJSON();
    const { target } = options;

    if (obj.owner_id === userId && !obj.isLocal) {
      target.saveState();
      canvas.saveCanvasPayload();

      const originalState = _.cloneDeep(target._stateProperties);
      target.saveState();

      const evt = {
        id: userId,
        action: 'object:modified',
        payload: toObjectPayload(obj),
      };
      canvas.eventRef.putEvent(evt);
      canvas.travelStore.do({
        ...evt,
        originalState,
      });
    }
  });

  canvas.$on('object:removed', (options) => {
    const obj = options.target.toJSON();
    if (obj.owner_id === userId && !obj.isLocal) {
      canvas.saveCanvasPayload();
      const evt = {
        id: userId,
        action: 'object:removed',
        payload: toObjectPayload(obj),
      };

      canvas.eventRef.putEvent(evt);
      canvas.travelStore.do(evt);
    }
  });

  canvas.$on('object:moving', (options) => {
    const obj = options.target.toJSON();
    if (obj.owner_id === userId && !obj.isLocal) {
      canvas.eventRef.putEvent({
        id: userId,
        action: 'object:moving',
        payload: toObjectPayload(obj),
      });
    }
  });
};
