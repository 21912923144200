import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { UnAuthRedirect, GuestRedirect } from '@uz/unitz-tool-components/AppRedirect';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import UserMessage from '@uz/unitz-tool-components/UserMessage';

const Index = () => {
  return (
    <DIV className="roomMessagePage">
      <GuestRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        {/* <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}> */}
        <div className="bg-white h-full w-full">
          <UserMessage />
        </div>
        {/* </UnAuthRedirect> */}
      </GuestRedirect>
    </DIV>
  );
};

export default displayName(Index);
