const _ = require('lodash');
const VimeoUpload = require('./VimeoUpload');

exports.getClient = _.memoize(() => {
  const client = {
    VimeoUpload,
    getVimeoEmbedUrl: (url) => {
      const regExp = /^(https?\:\/\/)?(www\.)?vimeo.com\/(\d+)($|\/)/;
      const match = `${url}`.match(regExp);
      if (match) {
        const videoId = match[3];
        const link = `https://player.vimeo.com/video/${videoId}?h=84bd8ec07c&badge=0&autopause=0&player_id=0&app_id=58479&portrait=0&byline=0`;
        return link;
      }
      return '';
    },
  };
  return client;
});
