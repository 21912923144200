import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import CollabGLightbox from '@uz/unitz-tool-components/Collab/Events/GLightbox';
import CollabWhiteboard from '@uz/unitz-tool-components/Collab/Events/Whiteboard';
// import CollabCursor from '@uz/unitz-tool-components/Collab/Events/Cursor';
import CollabLead from '@uz/unitz-tool-components/Collab/Events/Lead';

import useRoute from '@vl/hooks/useGbRoute';
import { ACL } from '@vl/mod-utils/ACL';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';

export const Collab = () => {
  const route = useRoute();
  const routeParams = route.getParams();
  const sessionId = _.get(routeParams, 'id', '');

  return (
    <DIV>
      <AsyncRender
        render={async () => {
          const isLead = await ACL.room.isTeacher() || await ACL.room.isAdmin();
          return (
            <>
              <CollabLead isLead={isLead} sessionId={sessionId} />
              {/* <CollabLead isLead={false} sessionId={sessionId} /> */}
              {/* <CollabCursor targetId="room-call-content-body" sessionId={sessionId} /> */}
              <CollabGLightbox sessionId={sessionId} />
              <CollabWhiteboard targetId="room-stream-slides" sessionId={sessionId} />
            </>
          );
        }}
      />
    </DIV>
  );
};

export default Collab;
