import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Fabric from '@uz/unitz-components-web/Fabric/loader';
import FabricControls from '@uz/unitz-components-web/Fabric/Controls';
import FabricConfig from '@uz/unitz-components-web/Fabric/config';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
// import { ResizeSensor } from 'css-element-queries';
import useTravelStore from '@vl/hooks/useTravelStore';

import CollabCursor from './Cursor';
import {
  getOwnerId,
  fromCanvasPayload,
  ensureHtmlCanvas,
  configFitZoom,
} from './utils';

import {
  extendCanvas,
  extendCanvasData,
  extendCanvasRef,
} from './canvas';

import {
  bindMarkerEvents,
  bindObjectEvents,
  bindTextEvents,
  bindKeyboardEvents,
  bindMouseEvents,
  bindModeEvents,
  getTravelStoreMappingEvents,
} from './events';

import './style.css';

export const Whiteboard = ({ targetId, sessionId, isFit = false, isOverlay = false }) => {
  const ref = React.useRef({ diposers: [] });
  const containerElementById = targetId || 'targetId';
  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();

  const genObjectId = () => {
    return `${sessionId}_${getOwnerId()}_${Date.now()}`;
  };

  _.assign(ref.current, { containerElementById });
  const getCanvas = () => ref.current.canvas;

  const travelStoreMappingEvents = React.useMemo(() => getTravelStoreMappingEvents(getCanvas), []);
  const [travelStore] = useTravelStore(`whiteboard_${sessionId}`, travelStoreMappingEvents);

  React.useEffect(() => {
    const { ctx } = ref.current;
    if (!ctx) return () => {};

    Fabric.onLoad(async () => {
      const canvasEle = await ensureHtmlCanvas(containerElementById);

      if (canvasEle) {
        const { fabric } = window;

        const canvas = new fabric.Canvas(canvasEle.id, {
          containerClass: isFit ? 'fitcanvas-container' : 'canvas-container',
          // backgroundColor: '#123456',
        });
        canvas.collab = new fabric.StaticCanvas(`${canvasEle.id}_collab`, {
          containerClass: isFit ? 'fitcanvas-container' : 'canvas-container',
        });

        canvas.htmlEle = canvasEle;
        canvas.isOverlay = isOverlay;
        ref.current.canvas = canvas;

        // config fitzoom
        if (isFit) {
          disposerCleaner(
            configFitZoom(targetId, ref.current.canvas),
          );
        }

        canvas.isTextMode = false;
        canvas.isDragMode = false;
        canvas.isDrawingMode = false;
        canvas.isMoveMode = false;
        canvas.sessionId = sessionId;
        canvas.travelStore = travelStore;

        extendCanvas(canvas);

        if (isOverlay) {
          canvas.toggleOverlay(true);
          bindModeEvents(canvas);
        }

        FabricConfig(fabric, canvas, {
          genObjectId,
          getOwnerId,
          targetId,
        });

        extendCanvasRef(canvas);
        extendCanvasData(canvas);

        // text change
        disposerCleaner(bindTextEvents(canvas));

        // mouse
        if (isOverlay && isFit) {
          disposerCleaner(bindMouseEvents(canvas));
        }

        // marker
        disposerCleaner(bindMarkerEvents(canvas));

        // object
        disposerCleaner(bindObjectEvents(canvas));

        // keyevent binding
        bindKeyboardEvents(canvas);

        // load data
        canvas.docRef.load((item) => {
          fromCanvasPayload(canvas, item.payload);
        });
      }

      forceUpdate();
    });

    return () => {
      if (ref.current.canvas) {
        const canvas = getCanvas();
        canvas.htmlEle.remove();
      }
    };
  }, []);

  // React.useEffect(() => {
  //   return () => {
  //     const parentNode = document.getElementById(containerElementById);
  //     if (parentNode) {
  //       const canvasNodes = [
  //         ...parentNode.querySelectorAll('.fitcanvas-container'),
  //         ...parentNode.querySelectorAll('.canvas-container'),
  //       ];
  //       _.map(canvasNodes, (node) => {
  //         node.remove();
  //       });
  //     }
  //   };
  // }, []);

  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
      <Fabric />
      {!!ref.current.canvas && <FabricControls canvas={ref.current.canvas} />}
      <CollabCursor targetId="room-call-content-body" sessionId={sessionId} canvas={ref.current.canvas} />
    </DIV>
  );
};

export default Whiteboard;
