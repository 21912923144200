import connectFirestore from '@vl/mod-utils/connectFirestore';
import { INTERNET_STATES } from './constants';

class VideoCallState {
  // syncable state here

  // local state here
  _local = {
    ctx: null
  };

  constructor(data, ctx) {
    const {
      id, user_id, advisor_id, ...rest
    } = data;
    // init state
    Object.assign(this, {
      states: {
        videoCallConnecting: false
      },
      sessionData: {},
      user_id,
      advisor_id,
      session_id: id,
      peerStates: {
        [user_id]: {
          micMute: false,
          videoMute: false,
          cameraPosition: '',
          collapse: true,
          internetStatus: INTERNET_STATES.connected
        },
        [advisor_id]: {
          micMute: false,
          videoMute: false,
          cameraPosition: '',
          collapse: true,
          internetStatus: INTERNET_STATES.connected
        }
      },
      ...rest
    });

    this._local.ctx = ctx;
  }

  get myState() {
    return this.peerStates[this.uid];
  }

  get otherState() {
    return this.peerStates[this.oid];
  }

  get peerState() {
    return this.peerStates[this.uid];
  }

  get uid() {
    const uid = this._local.ctx.apply('authModel.getUserId');
    return uid;
  }

  get oid() {
    const userId = this.user_id;
    const advisorId = this.advisor_id;
    const { uid } = this;
    return uid === userId ? advisorId : userId;
  }

  onConnect(state) {
    // connect to sync with firestore data;
    state.once(
      'onDestroy',
      connectFirestore(this._local.ctx, this)(state, {
        docId: this.session_id,
        collectionName: 'videocalls'
      })
    );
  }
}

export default VideoCallState;
