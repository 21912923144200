import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import ActionButton from '@uz/unitz-components-web/RoomWebComponents/StyledActionButton';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';
import HostStreamMenu from '@uz/unitz-components-web/RoomWebComponents/HostStreamMenu';

const View8 = () => {
  return (
    <DIV className="OTPublisherHeader">
      <div className="flex absolute space-x-1 inset-x-0 top-0 pt-1 z-10">
        <div className="col-start flex space-x-1 px-1 bg-black300 rounded">
          <div className="text-sm text-gray-200">
            <UserIdentity user_id={ctx.apply('authModel.getUserId')} />
          </div>
        </div>
        <div className="flex-1 col-center flex space-x-1"></div>
        <div className="col-end flex space-x-1 bg-black bg-opacity-5 px-1">
          <HostStreamMenu />
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
