import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Rate } from 'antd';
import { Button } from '@uz/unitz-components-web/Button';
import { Avatar } from '@uz/unitz-components-web/Avatar';

import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const FinishLayout = styled.div`
  padding: 0 16px;
  textarea.ant-input {
    font-size: 16px;
  }
  textarea.ant-input::placeholder {
    color: ${gstyles.colors.sub};
    font-size: 16px;
  }
  .ant-input {
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    --tw-border-opacity: 1;
    border-color: rgba(191, 205, 217, var(--tw-border-opacity));
  }
`;

const View8 = () => {
  return (
    <DIV className="videoCallMissed">
      {/* <Modal
        className="pb-0 overflow-hidden rounded-xl"
        style={{ borderRadius: 12 }}
        bodyStyle={{ padding: 0 }}
        width={600}
        footer={null}
        visible={true}
        closable={false}
      > */}
      <FinishLayout>
        <section className="py-0 bg-white min-lg:w-150 max-w-150 rounded-xl">
          <div className="flex flex-col items-center min-lg:p-6 max-lg:py-6">
            <h2 className="mb-1 text-xl text-main">{ctx.apply('i18n.t', 'AdvisorVoiceCall.callStatus.missedLabel')}</h2>
            <div className="flex mt-1">
              {gstyles.icons({
                name: 'videocam',
                fill: gstyles.colors.brandb500,
                size: 24,
                className: 'mr-2',
              })}
              <div className="mb-0 text-base font-normal text-main">{ctx.get('purchaseData.bill.totalAfter')}</div>
            </div>
            <div className="flex items-center justify-between w-full px-4 mt-4 min-lg:py-2 max-lg:py-1 min-lg:rounded-xl bg-red-lightest">
              <span className="text-sub">{ctx.apply('i18n.t', 'AdvisorVideoCall.missCallText')}</span>
              <Button
                onClick={ctx.get('stepModel.handlers.callAgain')}
                icon={gstyles.icons({
                  name: 'videocam',
                  size: 22,
                  className: 'inline-block mr-2',
                })}
                type="link"
                name="red"
              >
                {ctx.apply('i18n.t', 'AdvisorVideoCall.reCall')}
              </Button>
            </div>
          </div>
          <div className="border-b border-divider"></div>
          <div className="flex flex-col items-center p-6">
            <div className="flex items-center">
              <Avatar
                src={ctx.get('purchaseData.advisorProfile.avatarUrl')}
                alt={ctx.get('purchaseData.advisorProfile.displayName')}
                size={80}
              />
              <div className="flex flex-col ml-5">
                <h1 className="mb-1 text-xl font-medium">{ctx.get('purchaseData.advisorProfile.displayName')}</h1>
                <div className="flex items-center mb-1">
                  <div className="mr-2 -mb-1 text-base">{ctx.get('purchaseData.advisorProfile.rating.ratingText')}</div>
                  <Rate disabled count={5} value={ctx.get('purchaseData.advisorProfile.rating.ratingScore')} />
                </div>
                <div className="mb-2 uppercase text-sub text-xxs">
                  {ctx.get('purchaseData.advisorProfile.reviewCount')} {ctx.apply('i18n.t', 'Payment.Profile.reviews')}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-divider"></div>
          <div className="flex flex-row justify-between p-6 space-x-4">
            <Button onClick={ctx.get('stepModel.handlers.callFinish')} size="large" type="primary" block>
              {ctx.apply('i18n.t', 'AdvisorVideoCall.finishSend')}
            </Button>
          </div>
        </section>
      </FinishLayout>
      {/* </Modal> */}
    </DIV>
  );
};

export default displayName(View8);
