import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import GundbClient from '@vl/mod-clients/gundb';

const refs = {
  monitorId: '',
};

export const getLeadId = () => {
  return refs.monitorId;
};

export const Collab = ({ sessionId, isLead }) => {
  const ref = React.useRef({ diposers: [] });
  _.assign(ref.current, { isLead });

  const trackKey = (key) => {
    _.update(ref.current, `trackMap.${key}`, (node) => {
      if (node) return node;
      const docRef = GundbClient.getClient().getDoc(`lead_${sessionId}`);
      if (docRef) {
        docRef.on((item) => {
          const monitorId = _.get(item, 'value');
          refs.monitorId = monitorId;
        });
      }
      return () => {
        // docRef.off();
        // delete cusor
      };
    });
  };

  React.useEffect(() => {
    const { ctx } = ref.current;
    if (!ctx) return () => {};
    const userId = ctx.apply('authModel.getUserId');

    if (ref.current.isLead) {
      refs.monitorId = userId;
    }

    // setup listener

    // interval update player status
    ref.current.diposers.push(
      (() => {
        const docRef = GundbClient.getClient().getDoc(`lead_${sessionId}`);
        const timer = setInterval(() => {
          if (ref.current.isLead) {
            docRef.put({
              id: userId,
              value: userId,
            });
          } else {
            trackKey(userId);
          }
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      })()
    );

    const sessionRef = GundbClient.getClient()
      .getDoc(`sessions_${sessionId}`)
      .get(userId);
    if (userId) {
      sessionRef.get(userId).put({ id: userId, value: userId });
    }

    return () => {
      _.map(ref.current.trackMap, (disposer) => {
        disposer && disposer();
      });
      _.map(ref.current.diposers, (dis) => dis && dis());
      ref.current = {};
    };
  }, []);
  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
    </DIV>
  );
};

Collab.getLeadId = getLeadId;

export default Collab;
