import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import OTPublisher from '@uz/unitz-components-web/RoomWebComponents/OTLocalPublisher';
import OTPublisherScreen from '@uz/unitz-components-web/RoomWebComponents/OTLocalPublisherScreen';

import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

export const OTStreams = () => {
  const [videoInputDeviceIndexList] = useLocalStorage('@UZ::videoInputDeviceIndexList', [0]);

  return (
    <DIV forceCtx>
      {_.map(videoInputDeviceIndexList, (videoInputDeviceIndex) => (
        <React.Fragment key={`index_${videoInputDeviceIndex}`}>
          <OTPublisher
            className="w-full h-full bg-transparent"
            properties={{
              videoInputDeviceIndex,
              cameraPosition: ctx.apply('videoCallModel.useState', 'cameraPosition'),
              publishAudio: !ctx.apply('videoCallModel.useState', 'myState.micMute'),
              publishVideo: !ctx.apply('videoCallModel.useState', 'myState.videoMute'),
              width: '100%',
              height: '100%',
              showControls: false,
            }}
          />
        </React.Fragment>
      ))}
      {ctx.apply('videoCallModel.useState', 'shareScreen') && (
        <OTPublisherScreen
          className="w-full h-full bg-transparent"
          properties={{
            publishAudio: !ctx.apply('videoCallModel.useState', 'myState.micMute'),
            publishScreen: true,
            width: '100%',
            height: '100%',
            videoCallModel: ctx.get('videoCallModel'),
          }}
        />
      )}
    </DIV>
  );
};

export default OTStreams;
