import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import cx from 'classnames';
import { Tooltip } from 'antd';
// import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/videoUpload';

import { Button } from '@uz/unitz-components-web/Button';
import CountDownRecTooltip from '@uz/unitz-components-web/CountDownRecTooltip';

const RecordButton = ({ mediaRef, ButtonComponent, duration, ...restProps }) => {
  const ref = React.useRef({});
  const [recordDuration, $recordDuration] = React.useState(duration || 60);
  const [isRecording, $isRecording] = React.useState(false);
  const containerRef = React.useRef();

  _.assign(ref.current, {
    isRecording,
    $isRecording,
    recordDuration,
    $recordDuration,
  });

  const stopRecordVideo = async () => {
    if (ref.current.recorder) {
      await ref.current.recorder.stop();
      ref.current.recorder = null;
    }
  };

  const startRecordVideo = async () => {
    mediaRef = _.isFunction(mediaRef) ? mediaRef() : mediaRef;
    const preview = _.get(mediaRef, 'current.eleRef.current', mediaRef?.current);
    const disposer = mediaRef?.current?.attachAudio && (await mediaRef?.current?.attachAudio());
    if (preview) {
      preview.captureStream = preview.captureStream || preview.mozCaptureStream;
      // console.log('preview.captureStreampreview.captureStreampreview.captureStream', preview.captureStream);
      // return;
      if (!_.isFunction(preview.captureStream)) return;
      const stream = preview.captureStream();
      const recordOptions = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 8000000,
        // mimeType : 'video/mp4',
        mimeType: 'video/webm',
      };
      // start recording a stream
      const recorder = new window.MediaRecorder(stream, recordOptions);
      const data = [];
      recorder.ondataavailable = (event) => data.push(event.data);
      recorder.start();
      ref.current.recorder = recorder;

      await new Promise((resolve, reject) => {
        recorder.onstop = resolve;
        recorder.onerror = (event) => reject(event.name);
      });
      let width = 200;
      let height = 164;

      const recordedBlob = new window.Blob(data, { type: 'video/webm' });
      const file = new window.File([recordedBlob], `Record ${new Date().toUTCString()}.webm`);
      const messageInputRef = ref.current.ctx.apply('REF.getRef', 'messageInputRef');
      const quill = messageInputRef.current.getQuillRef();
      if (messageInputRef && quill) {
        // post to message chanel
        let range = quill.getSelection(true);
        const fid = `uid_${Date.now()}`;

        console.log('messageInputRef && file', messageInputRef, file.size, file, recorder);
        if (recorder) {
          const streamSettings = recorder.stream?.getVideoTracks()[0]?.getSettings();
          console.log('streamSettingsstreamSettings', streamSettings);
          width = streamSettings?.width;
          height = streamSettings?.height;
        }
        if (messageInputRef && file) {
          const uploadContainerRef = ref.current.ctx.apply('REF.getRef', 'uploadContainerRef');
          uploadContainerRef && uploadContainerRef.add(file);
          _.assign(file, { status: 'uploading', percent: 0 });
          // AssetModelFormatter.uploadVideoCDN(messageInputRef.current.ctx)({
          AssetModelFormatter.uploadVideoVimeo(messageInputRef.current.ctx)({
            file,
            onComplete: (file) => {
              uploadContainerRef.remove(file);
            },
            onProgress: ({ percent }) => {
              const element = document.getElementById(fid);
              const loadingClass = `loading-${Math.floor(percent / 10) * 10}`;
              _.assign(file, { percent });
              if (element) {
                element.classList.add('block-loading', loadingClass);
                if (percent === 100) {
                  element.classList.remove('block-loading');
                  const messageControlModelRef = ref.current.ctx.apply('REF.getRef', 'messageControlModelRef');
                  if (messageControlModelRef) {
                    messageControlModelRef.show();
                  }
                  // auto submit post
                  messageInputRef.current.handlers.submitMessage();
                }
              }
            },
          }).then(({ url }) => {
            quill.insertEmbed(
              range.index,
              'block-video',
              {
                src: url,
                id: fid,
                name: file.name,
                width,
                height,
              },
              'user'
            );
            quill.insertText(range.index + 1, '\u00a0', 'user');
            quill.setSelection(range.index + 2);
            disposer && disposer();
            messageInputRef.current.handlers.submitMessage();
          });
        }
      }
    }
  };

  React.useEffect(() => {
    if (ref.current.isRecording) {
      startRecordVideo();
    } else {
      stopRecordVideo();
    }
  }, [ref.current.isRecording]);

  const toggleRecord = async () => {
    ref.current.$isRecording && ref.current.$isRecording(!ref.current.isRecording);
  };

  ButtonComponent = ButtonComponent || Button;
  let icon;
  if (ref.current.isRecording) {
    icon = restProps.icon;
  } else {
    icon = restProps.icon;
  }
  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
      <div className="relative" ref={containerRef}>
        {ref.current.isRecording && <div className="absolute inset-0 flex justify-center items-center"></div>}
        <Tooltip
          placement="bottom"
          title={<CountDownRecTooltip seconds={recordDuration} onTimer={toggleRecord} />}
          trigger="click"
          visible={ref.current.isRecording}
          destroyTooltipOnHide
          // getPopupContainer={() => {
          //   const mediaRef = _.isFunction(mediaRef) ? mediaRef() : mediaRef;
          //   return mediaRef?.current || containerRef?.current;
          // }}
        >
          <ButtonComponent
            {...restProps}
            onClick={toggleRecord}
            icon={icon}
            className={cx(restProps.className, 'relative')}
          ></ButtonComponent>
        </Tooltip>
      </div>
    </DIV>
  );
};

export default RecordButton;
