import moment from 'moment';
import _ from 'lodash';

export const formatter = {
  formatMessage: () => (itemData) => {
    return {
      ...itemData,
    };
  },
  formatMessages: (ctx) => (messages) => {
    return _.map(messages, (message) => {
      return formatter.formatMessage(ctx)(message);
    });
  },
  formatCreatedAt: () => (itemData) => {
    const $created_at = moment(_.get(itemData, 'created_at'));
    return $created_at.from(moment());
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);
    return _.get(itemData, 'author.avatar_url') || `https://ui-avatars.com/api/?name=${displayName}`;
  },

  displayName: (ctx) => (itemData) => {
    return _.get(itemData, 'author.display_name', '');
  },

  message: (ctx) => (itemData) => {
    return _.get(itemData, 'message', '');
  },

  time: (ctx) => (itemData) => {
    return moment(_.get(itemData, 'created_at', '')).utc().format('hh:mm A');
  },
  isSeen: (ctx) => (message, user_id) => {
    const created_by = _.get(message, 'seen.created_by');
    const seen = _.get(message, 'seen.seen_at');
    return !created_by || _.isEqual(created_by, user_id) || (user_id && !_.isEqual(created_by, user_id) && !!seen);
  },
};

export default formatter;
