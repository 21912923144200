import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Select } from 'antd';
import displayName from '@vl/redata/displayName.macro';
import { useDeferCloseSelect } from '../hooks';

const StyledSelect = styled.div`
  .rc-virtual-list-holder-inner {
    background-color: ${gstyles.colors.sub};
    border-radius: 8px;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    .ant-select-item-option {
      padding: 4px;
      min-width: 50%;
      &.ant-select-item-option-active,
      &.ant-select-item-option-selected {
        .ant-select-item-option-content {
          & > div {
            .option-label {
              border-color: ${gstyles.colors.sub} !important;
            }
          }
        }
      }
    }
  }
  .ant-select-selection-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .option-label {
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  .ant-select {
    &.ant-select-single {
      .ant-select-selector {
        background-color: ${gstyles.colors.sub} !important;
      }
    }
  }

  .ant-select-dropdown {
    padding: 0px;
    background-color: ${gstyles.colors.sub};
    border-radius: 8px;
  }
  .ant-select-dropdown {
    padding: 0px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${gstyles.colors.white200};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${gstyles.colors.white400};
  }
`;

const createOption = (value) => {
  return {
    value,
    label: (
      <div className="flex justify-center items-center h-full">
        <div
          className="border border-white500 option-label bg-white500 rounded-full"
          style={{ borderWidth: value, height: value, width: value }}
        ></div>
      </div>
    ),
  };
};

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });

  const getValue = React.useCallback(() => {
    const type = _.get(ref.current.target, 'type');
    if (['path', 'marker'].includes(type)) {
      return ref.current.target.get('strokeWidth');
    }
  }, [ref.current.target]);

  const applicableTarget = React.useMemo(() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    const type = _.get(ref.current.target, 'type');
    if (['path', 'marker'].includes(type)) {
      return true;
    }
  }, [ref.current.target]);

  const [value, $value] = React.useState(getValue);

  _.assign(ref.current, { value, $value });

  const onChange = React.useCallback(
    (val) => {
      ref.current.$value && ref.current.$value(val);
      _onChange && _onChange(value);

      const type = _.get(ref.current.target, 'type');
      if (['path', 'marker'].includes(type)) {
        ref.current.target.set({
          strokeWidth: val,
        });
        canvas && canvas.updateObject(ref.current.target);
      }
    },
    [ref.current.target]
  );
  const openProps = useDeferCloseSelect();

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Select
        dropdownAlign={{
          offset: [-10, -100],
        }}
        getPopupContainer={(node) => node}
        size="small"
        dropdownMatchSelectWidth={false}
        dropdownClassName="flex flex-wrap w-16"
        suffixIcon={gstyles.icons({ name: 'arrow-down', size: 20, className: '-mt-1', fill: gstyles.colors.white500 })}
        options={[createOption(2), createOption(4), createOption(6), createOption(8)]}
        value={value}
        onChange={onChange}
        {...rest}
        {...openProps}
      />
    </StyledSelect>
  );
};

export default displayName(Index);
