import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { computeGridDimension } from '@vl/mod-utils/deviceInfoWeb';
import OTStream from '@uz/unitz-components-web/RoomWebComponents/OTLocalSubscriberStream';

export const OTSubscriber = React.memo(
  () => {
    const ref = React.useRef({});
    React.useEffect(() => {
      return () => {
        ref.current = {};
      };
    }, []);

    return (
      <DIV forceCtx>
        {ctx.debug(() => {
          ref.current.ctx = ctx;
          const tracks = ctx.apply('videoCallModel.useState', 'tracks');
          if (!_.isEqual(tracks, ref.current.tracks) && ref.current.$tracks) {
            ref.current.$tracks(_.cloneDeep(tracks));
          }
        })}
        <div className="h-full w-full flex justify-center flex-wrap">
          {_.map(ctx.apply('videoCallModel.useState', 'tracks.pins'), (stream_id, key, pins) => {
            const numberOfStreams = _.get(pins, 'length');
            const [numberOfRows, numberOfCols] = computeGridDimension(numberOfStreams);

            return (
              <div
                key={stream_id}
                className="OTSubscriberStream"
                style={{
                  width: `${(1 / numberOfCols) * 100}%`,
                  height: `${(1 / numberOfRows) * 100}%`,
                }}
              >
                <OTStream
                  stream_id={stream_id}
                  properties={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            );
          })}
        </div>
      </DIV>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps.properties, nextProps.properties);
  }
);

export default OTSubscriber;
