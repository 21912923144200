exports.projectSize = (from, to, boundRect) => {
  const maxWidth = (boundRect && boundRect.width) || 1000;
  const maxHeight = (boundRect && boundRect.height) || 1000;
  if (to.width && from.width) {
    const height = Math.min(Math.ceil((to.width * from.height) / from.width), maxHeight);
    const width = Math.min(Math.ceil((height * from.width) / from.height), maxWidth);
    return {
      ...to,
      height,
      width,
    };
  }

  if (to.height && from.height) {
    const width = Math.min(Math.ceil((to.height * from.width) / from.height), maxWidth);
    const height = Math.min(Math.ceil((width * from.height) / from.width), maxHeight);
    return {
      ...to,
      height,
      width,
    };
  }
  return { ...to };
};
