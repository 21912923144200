import _ from 'lodash';

import {
  getOwnerId,
  toCanvasPayload,
  addObjectsToCanvas,
  fromCanvasPayload,
  syncObjectsToCanvas,
  syncCanvasPayload,
  toObjectPayload,
  fromObjectPayload,
  ensureHtmlCanvas,
  configFitZoom,
} from '../utils';

export const bindMarkerEvents = (canvas) => {
  let isDrawingMode = false;
  const markerId = _.uniqueId('marker');
  const userId = getOwnerId();
  canvas.$on('mouse:down', (evt) => {
    if (canvas.isDrawingMode) {
      isDrawingMode = true;
      const pointer = canvas.getPointer(evt);
      canvas.eventRef.putEvent({
        id: userId,
        action: 'marker:down',
        payload: toObjectPayload({
          ...pointer,
          id: markerId,
          color: canvas.freeDrawingBrush.color,
          width: canvas.freeDrawingBrush.width,
          _points: canvas.freeDrawingBrush._points,
        }),
      });
    }
  });
  canvas.$on('mouse:up', (evt) => {
    if (isDrawingMode) {
      isDrawingMode = false;
      const pointer = canvas.getPointer(evt);
      canvas.eventRef.putEvent({
        id: userId,
        action: 'marker:up',
        payload: toObjectPayload({
          ...pointer,
          id: markerId,
          color: canvas.freeDrawingBrush.color,
          width: canvas.freeDrawingBrush.width,
          _points: canvas.freeDrawingBrush._points,
        }),
      });
    }
  });
  canvas.$on('mouse:move', (evt) => {
    if (isDrawingMode) {
      const pointer = canvas.getPointer(evt);
      canvas.eventRef.putEvent({
        id: userId,
        action: 'marker:move',
        payload: toObjectPayload({
          ...pointer,
          id: markerId,
          color: canvas.freeDrawingBrush.color,
          width: canvas.freeDrawingBrush.width,
          _points: canvas.freeDrawingBrush._points,
        }),
      });
    }
  });
};
