import { bindings, hook } from '@vl/redata';

const bindData = bindings({
  videoComponent: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },

  sessionReconnect: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('videoCallModel.isConnecting')),
        },
      ],
    ],
  },
});

export default bindData;
