import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import RecordButton from '@uz/unitz-components-web/RecordButton';
import _ from 'lodash';
import ActionButton from '@uz/unitz-components-web/RoomWebComponents/StyledActionButton';

const View8 = () => {
  return (
    <DIV className="OTPublisherActions">
      <div
        className="absolute inset-0"
        onDoubleClick={() => {
          ctx.apply('videoCallModel.pinStream', ctx.get('@item.stream_id'));
        }}
      >
        <div className="flex absolute space-x-1 inset-x-0 bottom-0">
          <div className="col-start flex space-x-1 bg-black bg-opacity-5 px-1">
            <ActionButton
              onClick={() => {
                ctx.apply('videoCallModel.toggleMic');
              }}
              className="bg-gray-400"
              name="overlay"
              type="primary"
              loading={false}
            >
              {gstyles.icons({
                name: ctx.apply('videoCallModel.useState', 'myState.micMute') ? 'micro-off' : 'micro',
                fill: ctx.apply('videoCallModel.useState', 'myState.micMute')
                  ? gstyles.colors.main
                  : gstyles.colors.white500,
                size: 14,
              })}
            </ActionButton>
            <ActionButton
              onClick={() => {
                ctx.apply('videoCallModel.toggleVideo');
              }}
              className="bg-gray-400"
              name="overlay"
              type="primary"
              loading={false}
            >
              {gstyles.icons({
                name: ctx.apply('videoCallModel.useState', 'myState.videoMute') ? 'videocam-off' : 'videocam',
                fill: ctx.apply('videoCallModel.useState', 'myState.videoMute')
                  ? gstyles.colors.main
                  : gstyles.colors.white500,
                size: 14,
              })}
            </ActionButton>
          </div>
          <div className="flex-1 col-center flex space-x-1"></div>
          <div className="col-end flex space-x-1 bg-black bg-opacity-5 px-1">
            <RecordButton
              ButtonComponent={ActionButton}
              mediaRef={() => ctx.apply('REF.getRef', `${ctx.get('@item.stream_id')}`)}
              icon={gstyles.icons({
                name: 'record-2',
                fill: gstyles.colors.white500,
                size: 14,
              })}
              duration={15 * 60}
              loading={false}
              name="overlay"
              type="primary"
            />
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
