import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Checkbox } from 'antd';
import { setTimeoutAt, clearTimeout } from '@vl/mod-utils/safeTimers';
import moment from 'moment';
import useRoute from '@vl/hooks/useGbRoute';
import { getREF } from '@uz/unitz-providers/RefProvider';

const AutoJoinRoomCheckbox = ({ value }) => {
  const ref = React.useRef({});
  const [autoJoin, $autoJoin] = React.useState(!!value);
  const route = useRoute();

  React.useEffect(() => {
    if (ref.current.timer) {
      clearTimeout(ref.current.timer);
      ref.current.timer = null;
    }
    if (autoJoin && ref.current.room) {
      const start_at = _.get(ref.current.room, 'start_at');
      const $start_at = moment(start_at);
      ref.current.timer = setTimeoutAt(async () => {
        // const join_url = _.get(ref.current.room, 'join_url');
        // if (join_url) {
        //   // window.open(join_url, '_blank');
        //   // window.location.href = join_url;
        //   const videoSdkModelRef = getREF().getRef('videoSdkModelRef');
        //   if (videoSdkModelRef?.connectRoom) {
        //     await videoSdkModelRef.connectRoom();
        //   }
        // }
        const videoSdkModelRef = getREF().getRef('videoSdkModelRef');
        if (videoSdkModelRef?.connectRoom) {
          await videoSdkModelRef.connectRoom();
        }
      }, $start_at.toDate());
    }
  }, [autoJoin]);
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ref.current.room = ctx.get('room');
      })}
      <Checkbox
        checked={autoJoin}
        onChange={(event) => {
          $autoJoin(_.get(event, 'target.checked'));
        }}
      >
        <span className="text-white">{ctx.apply('i18n.t', 'AdvisorCourse.roomActions.autoJoin')}</span>
      </Checkbox>
    </DIV>
  );
};

export default AutoJoinRoomCheckbox;
