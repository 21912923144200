import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import RecordButton from '@uz/unitz-components-web/RecordButton';
import _ from 'lodash';
import ActionButton from '@uz/unitz-components-web/RoomWebComponents/StyledActionButton';
import ActionButtonLarge from '@uz/unitz-components-web/RoomWebComponents/StyledActionButtonLarge';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';

const View8 = () => {
  return (
    <DIV className="OTSubscriberStreamActions">
      <div className="flex absolute space-x-1 inset-x-0 bottom-0 p-2">
        <div className="col-start flex space-x-1 px-1 z-10">
          <div className="text-base text-gray-200 px-2 p-1 bg-black300 rounded">
            <UserIdentity query_str={ctx.get('@item.identity')} />
          </div>
          {!!ctx.apply('roomControlModel.can.recordStream') && (
            <ActionButtonLarge
              icon={gstyles.icons({
                name: 'unpin',
                fill: gstyles.colors.white500,
                size: 20,
              })}
              onClick={() => {
                ctx.apply('videoCallModel.pinStream', ctx.get('@item.stream_id'));
              }}
              loading={false}
              name="overlay"
              type="primary"
            />
          )}
          {!!ctx.apply('roomControlModel.can.recordStream') && (
            <RecordButton
              ButtonComponent={ActionButtonLarge}
              mediaRef={() => ctx.apply('REF.getRef', ctx.get('@item.stream_id'))}
              icon={gstyles.icons({
                name: 'record-2',
                fill: gstyles.colors.white500,
                size: 20,
              })}
              duration={15 * 60}
              loading={false}
              name="overlay"
              type="primary"
            />
          )}
        </div>
        <div className="flex-1 col-center flex space-x-2"></div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
