import React, { useCallback, useState } from 'react';
import Video from 'twilio-video';
import _ from 'lodash';
import { message } from 'antd';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { getDeviceInfo, isPermissionDenied } from '@vl/mod-utils/deviceInfoWeb';

function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState();
  const [videoTrack, setVideoTrack] = useState();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
  const ref = React.useRef({});
  const [audioInputDeviceIndex] = useLocalStorage('@UZ::audioInputDeviceIndex', 0);
  const [videoInputDeviceIndex] = useLocalStorage('@UZ::videoInputDeviceIndex', 0);
  // const [audioOutputDeviceIndex] = useLocalStorage('@UZ::audioOutputDeviceIndex', 0);
  _.assign(ref.current, { audioInputDeviceIndex, videoInputDeviceIndex });

  const getLocalAudioTrack = async () => {
    try {
      const { audioInputDevices, hasAudioInputDevices } = await getDeviceInfo();
      if (!hasAudioInputDevices) return Promise.resolve();

      const selectedAudioDeviceId = audioInputDevices[ref.current.audioInputDeviceIndex]?.deviceId;
      const hasSelectedAudioDevice = audioInputDevices[ref.current.audioInputDeviceIndex];

      const options = {
        // name: `mic-${selectedAudioDeviceId}-${Date.now()}`,
        name: `mic-${selectedAudioDeviceId}`,
        ...(selectedAudioDeviceId && hasSelectedAudioDevice && { deviceId: { exact: selectedAudioDeviceId } }),
      };

      const newTrack = await Video.createLocalAudioTrack(options);
      setAudioTrack(newTrack);
      return newTrack;
    } catch (err) {
      console.log('getLocalAudioTrack error', err);
      message.error({ key: 'permission_microphone', content: 'Microphone access is blocked' });
      // throw err;
    }
  };

  const getLocalVideoTrack = async (videoInputDeviceIndex) => {
    try {
      const { videoInputDevices } = await getDeviceInfo();

      videoInputDeviceIndex = _.isUndefined(videoInputDeviceIndex)
        ? ref.current.videoInputDeviceIndex
        : videoInputDeviceIndex;

      const selectedVideoDeviceId = videoInputDevices[videoInputDeviceIndex]?.deviceId;
      const hasSelectedVideoDevice = videoInputDevices[ref.current.videoInputDeviceIndex];

      const options = {
        // name: `camera-${selectedVideoDeviceId}-${Date.now()}`,
        name: `camera-${selectedVideoDeviceId}`,
        ...(selectedVideoDeviceId && hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      };

      const newTrack = await Video.createLocalVideoTrack(options);

      setVideoTrack(newTrack);
      return newTrack;
    } catch (err) {
      console.log('getLocalVideoTrack error', err);
      message.error({ key: 'permission_camera', content: 'Camera access is blocked' });
      // throw err;
    }
  };

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
    if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = audioInputDevices[ref.current.audioInputDeviceIndex]?.deviceId;
    const selectedVideoDeviceId = videoInputDevices[ref.current.videoInputDeviceIndex]?.deviceId;

    const hasSelectedAudioDevice = audioInputDevices[ref.current.audioInputDeviceIndex];
    const hasSelectedVideoDevice = videoInputDevices[ref.current.videoInputDeviceIndex];

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        name: `camera-${selectedVideoDeviceId}-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio: shouldAcquireAudio && {
        name: `mic-${selectedAudioDeviceId}-${Date.now()}`,
        ...(hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices),
      },
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === 'video');
        const newAudioTrack = tracks.find((track) => track.kind === 'audio');
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack);
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack);
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied');
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied');
        }
        return tracks;
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [audioTrack, videoTrack, isAcquiringLocalTracks]);

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined);

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    audioInputDeviceIndex,
    videoInputDeviceIndex,
  };
}

export default useLocalTracks;
