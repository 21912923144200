import React from 'react';
import _ from 'lodash';
import displayName from '@vl/redata/displayName.macro';

import UserIdentity from '@uz/unitz-components-web/UserIdentity';
import gstyles from '@vl/gstyles';

const Index = ({ user_id }) => {
  return (
    <div className="absolute transform -translate-x-1/2 flex flex-col justify-center items-center">
      <div className="bg-black bg-opacity-50 w-4">
        {gstyles.icons({ name: 'arrow-up', size: 16, fill: gstyles.colors.white })}
      </div>
      <div className="text-white whitespace-nowrap bg-black bg-opacity-50">
        <UserIdentity user_id={user_id} />
      </div>
    </div>
  );
};

export default displayName(Index);
