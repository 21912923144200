// import isPlainObject from 'is-plain-object';
import _ from 'lodash';
import { waitUntil } from '@vl/mod-utils/waitUntil';

export const isMobile = () => {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') {
    return false;
  }
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(`${navigator.userAgent || navigator.vendor || window.opera}`);
  return check;
};

export const isMobileAndTablet = () => {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') {
    return false;
  }
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(`${navigator.userAgent || navigator.vendor || window.opera}`);
  return check;
};

// Recursively removes any object keys with a value of undefined
export function removeUndefineds(obj) {
  if (!_.isPlainObject(obj)) return obj;

  const target = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target;
}

let didInitUserMediaPermission = false;
const initUserMediaPermission = async () => {
  if (!didInitUserMediaPermission) {
    // Safari requires the user to grant device access before providing
    // all necessary device info, so do that first.
    // eslint-disable-next-line
    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    didInitUserMediaPermission = true;
  }
};

let deviceInfo = null;

const createPromise = () => {
  const rtn = {};
  rtn.promise = new Promise((resolve, reject) => {
    rtn.resolve = resolve;
    rtn.reject = reject;
  });
  return rtn;
};

export async function getDeviceInfo() {
  let devices = [];
  if (deviceInfo) return await deviceInfo;

  try {
    const promiseObj = createPromise();
    deviceInfo = promiseObj.promise;
    await initUserMediaPermission();
    // eslint-disable-next-line
    devices = await navigator.mediaDevices.enumerateDevices();
    deviceInfo = {
      audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
      videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
      audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
      hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
      hasVideoInputDevices: devices.some((device) => device.kind === 'videoinput'),
    };
    promiseObj.resolve(deviceInfo);
  } catch (err) {
    console.log(err);
  }
  setTimeout(() => {
    deviceInfo = null;
  }, 1000);
  return deviceInfo;
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name) {
  // eslint-disable-next-line
  if (navigator.permissions) {
    try {
      // eslint-disable-next-line
      const result = await navigator.permissions.query({ name });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

const userIdQueryString = (query_str) => {
  let user_id;
  if (query_str) {
    try {
      const urlParams = new URLSearchParams(query_str);
      user_id = urlParams.get('user_id') || user_id;
      user_id = urlParams.get('advisor_id') || user_id;
    } catch (err) {
      console.log('err', err);
    }
  }
  return user_id;
};

export function getStreamId(stream, ctx) {
  const [track, participant] = stream;
  const user_id = userIdQueryString(participant?.identity) || ctx?.apply('authModel.getUserId');
  const identity = `user_id=${user_id}`;
  // const device_id = _.get(track, 'track.name') || _.get(track, 'track.id');
  const device_id = _.get(track, 'track.id') || _.get(track, 'track.name');
  return `${identity}&device_id=${device_id}`;
}

export function getInfoStream(stream, ctx) {
  const [track, participant] = stream;
  const user_id = userIdQueryString(participant?.identity) || ctx?.apply('authModel.getUserId');
  const device_id = _.get(track, 'track.id') || _.get(track, 'track.name');
  const identity = `user_id=${user_id}`;
  return { user_id, device_id, stream_id: `${identity}&device_id=${device_id}` };
}

export function promoteTrack(room, ctx) {
  const items = [];
  let tracks = {};
  if (ctx) {
    tracks = ctx.apply('videoCallModel.getState', 'tracks');
  }
  const localParticipant = room?.localParticipant;

  if (room?.localParticipant) {
    const participant = room?.localParticipant;
    const { videoTracks } = participant;
    if (videoTracks?.size) {
      for (const [, track] of videoTracks) {
        items.push([track, participant]);
      }
    } else {
      items.push([undefined, participant]);
    }
  }
  let randomStream;
  if (room?.participants) {
    for (const [, participant] of room.participants) {
      const { videoTracks } = participant;
      if (videoTracks?.size) {
        for (const [, track] of videoTracks) {
          randomStream = [track, participant];
          items.push(randomStream);
        }
      } else {
        randomStream = [undefined, participant];
        items.push(randomStream);
      }
    }
  }
  const participantsCount = room?.participants?.size || 0;
  const hasShareScreen = !!_.find(items, (item) => {
    const [track] = item;
    if (_.get(track, 'trackName') === 'screen') {
      return true;
    }
  });

  let subscribeTracks = [];
  let streamTracks = [];
  let screenTracks = [];

  for (const item of items) {
    const [track, participant] = item;
    // screen track
    if (_.get(track, 'trackName') === 'screen' && localParticipant?.identity !== participant.identity) {
      screenTracks.push(item);
      continue;
    }

    const stream_id = getStreamId(item, ctx);
    if (_.get(tracks, 'pins', []).includes(stream_id)) {
      subscribeTracks.push(item);
      continue;
    }

    // for one-one room
    if (
      participantsCount === 1 &&
      !hasShareScreen &&
      _.get(participant, 'identity') === _.get(randomStream, '1.identity')
    ) {
      // return true;
    }
    streamTracks.push(item);
    continue;
  }
  // if screen track available, only show screen track as subscribeStream
  if (screenTracks.length) {
    streamTracks = [...subscribeTracks, ...streamTracks];
    subscribeTracks = [...screenTracks];
  }

  // auto unsubscribe streams if glightbox active?
  if (_.get(window, 'lightboxOpen')) {
    streamTracks = [...subscribeTracks, ...streamTracks];
    subscribeTracks = [];
  }

  streamTracks = _.filter(streamTracks, ([, participant]) => {
    // do not include localParticipant stream
    return localParticipant?.identity !== participant.identity;
  });

  return [subscribeTracks, streamTracks];
}

export function flattenStreams(room, ctx) {
  const items = [];
  if (room?.participants) {
    for (const [, participant] of room.participants) {
      const { videoTracks } = participant;
      if (videoTracks?.size) {
        for (const [, track] of videoTracks) {
          items.push([track, participant]);
        }
      } else {
        items.push([undefined, participant]);
      }
    }
  }

  return items;
}

export function flattenLocalStreams(room, ctx) {
  const items = [];
  if (room?.localParticipant) {
    const participant = room.localParticipant;
    const { videoTracks } = participant;
    if (videoTracks?.size) {
      for (const [, track] of videoTracks) {
        items.push([track, participant]);
      }
    } else {
      items.push([undefined, participant]);
    }
  }

  return items;
}

const VIDEOTRACKMAP = new WeakMap();

export function ElementTrackMan(ele) {
  const man = {
    attachMedia: (mediaStream) => {
      if ('srcObject' in ele) {
        ele.srcObject = mediaStream;
      } else {
        // Avoid using this in new browsers, as it is going away.
        ele.src = mediaStream && URL.createObjectURL(mediaStream);
      }
    },
    attachVideo: async (track) => {
      const videoTracks = man.getVideoTracks();
      const trackName = _.get(track, 'name');
      // only allow signle video per track
      let isExists = false;
      _.map(videoTracks, (currTrack, currName) => {
        if (currName === trackName) {
          isExists = true;
        } else {
          // deattach track
          currTrack?.detach && currTrack.detach(ele);
          _.unset(man.getVideoTracks(), trackName);
        }
      });
      // for non existing tracks, attach
      if (!isExists) {
        track?.attach && track.attach(ele);
        _.set(man.getVideoTracks(), trackName, track);
      }
    },
    attachAudios: async (tracks) => {
      tracks = await Promise.all(
        _.map(tracks, async (track) => {
          // nested track
          if (_.has(track, 'track')) {
            track = await waitUntil(
              () => _.get(track, 'track'),
              (item) => !!item,
              500
            );
          }
          return track;
        })
      );
      const eleTracks = man.getEleTracks();
      const trackNames = _.keyBy(
        _.compact(tracks),
        (track) => _.get(track, 'mediaStreamTrack.id') || _.get(track, 'name')
      );

      // only allow signle video per track
      _.map(eleTracks.audioTracks, (currTrack, currName) => {
        if (trackNames[currName]) {
          _.unset(trackNames, currName);
        } else {
          // deattach track
          currTrack?.detach && currTrack.detach(ele);
          currTrack?.stop && currTrack?.stop();
        }
      });
      // for non existing tracks, attach
      _.map(trackNames, (track, name) => {
        track?.attach && track.attach(ele);
      });
    },
    detachAudios: async (tracks) => {
      tracks = await Promise.all(
        _.map(tracks, async (track) => {
          // nested track
          if (_.has(track, 'track')) {
            track = await waitUntil(
              () => _.get(track, 'track'),
              (item) => !!item,
              500
            );
          }
          return track;
        })
      );
      const eleTracks = man.getEleTracks();
      const trackNames = _.keyBy(
        _.compact(tracks),
        (track) => _.get(track, 'mediaStreamTrack.id') || _.get(track, 'name')
      );
      // only allow signle video per track
      _.map(eleTracks.audioTracks, (currTrack, currName) => {
        if (trackNames[currName]) {
          // deattach track
          currTrack?.detach && currTrack.detach(ele);
          currTrack?.stop && currTrack?.stop();
        }
      });
    },

    getEleTracks: () => {
      if (ele && ele.srcObject && ele.srcObject.getAudioTracks) {
        const audioTracks = ele && ele.srcObject && ele.srcObject.getAudioTracks && ele.srcObject.getAudioTracks();
        return {
          audioTracks: _.keyBy(audioTracks, 'id'),
        };
      }
      return { audioTracks: {} };
    },
    getVideoTracks: () => {
      if (!VIDEOTRACKMAP.has(ele)) {
        VIDEOTRACKMAP.set(ele, {});
      }
      return VIDEOTRACKMAP.get(ele);
    },
  };
  return man;
}

const globalAudio = {
  ref: null,
};

export function attachGlobalAudio(audioTracks) {
  globalAudio.ref?.current && ElementTrackMan(globalAudio.ref?.current).attachAudios(audioTracks);
}

export const refGlobalAudio = (ref) => {
  globalAudio.ref = ref;
};

export function attachMedia() {}

export function computeGridDimension(numberOfCell) {
  let numberOfRows = Math.ceil(Math.sqrt(numberOfCell));
  let numberOfCols = Math.ceil(numberOfCell / numberOfRows);
  const winWidth = _.get(window, 'innerWidth');
  const winHeight = _.get(window, 'innerHeight');
  if (winWidth > winHeight) {
    let swap = numberOfRows;
    numberOfRows = numberOfCols;
    numberOfCols = swap;
  }
  return [numberOfRows, numberOfCols];
}

export const createTrackMan = (room) => {
  const actionQueue = [];
  const manRef = {
    published: {},
  };
  const findTrackByName = (target) => {
    if (room?.localParticipant?.tracks) {
      for (const [, { track }] of room?.localParticipant?.tracks) {
        if (_.get(track, 'name') === _.get(target, 'name')) {
          return track;
        }
      }
    }
  };

  manRef.processQueue = async () => {
    if (manRef.running) return;
    const [action] = actionQueue.splice(0, 1);
    if (action) {
      try {
        manRef.running = true;
        const [type, track] = action;
        const DELAY = 500;
        if (track && type === 'publish') {
          const foundTrack = findTrackByName(track);
          if (!foundTrack) {
            const localTrackPublication = await room.localParticipant.publishTrack(track);

            // const mediaTrack = _.get(track, 'mediaStreamTrack') || track;
            // console.log('mediaTrack', mediaTrack);
            // const localTrackPublication = await room.localParticipant.publishTrack(mediaTrack);
            // console.log('localTrackPublication', localTrackPublication);
            await new Promise((res) => setTimeout(res, DELAY));
          }
        } else if (track && type === 'unpublish') {
          await track.stop();
          const foundTrack = findTrackByName(track);
          if (foundTrack) {
            await foundTrack.stop();
            await room?.localParticipant?.unpublishTrack(foundTrack);
          }
          await new Promise((res) => setTimeout(res, DELAY));
        }
      } catch (err) {
        console.log('track && type', action);
        console.log('err', err);
      }
      manRef.running = false;
      manRef.processQueue();
    }
  };

  return {
    publishTrack: (track) => {
      actionQueue.push(['publish', track]);
      manRef.processQueue();
      return track;
    },

    unpublishTrack: (track) => {
      actionQueue.push(['unpublish', track]);
      manRef.processQueue();
      return track;
    },
  };
};

export const getTrack = async (track) => {
  if (_.has(track, 'track')) {
    track = await waitUntil(
      () => _.get(track, 'track'),
      (item) => !!item,
      500
    );
  }
  return track;
};

export const getTrackId = async (track) => {
  track = await getTrack(track);
  return _.get(track, 'mediaStreamTrack.id') || _.get(track, 'name');
};
