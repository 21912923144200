import _ from 'lodash';

import {
  getOwnerId,
  toObjectPayload,
} from '../utils';

export const bindTextEvents = (canvas) => {
  const userId = getOwnerId();
  canvas.$on('text:changed', (options) => {
    const { target } = options;
    canvas.ensureTarget(target);

    const obj = options.target.toJSON();
    if (obj.owner_id === userId && !obj.isLocal) {
      canvas.saveCanvasPayload(500);
      canvas.eventRef.putEvent({
        id: userId,
        action: 'text:changed',
        payload: toObjectPayload(obj),
      });
    }
  });
};