import _ from 'lodash';

import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import fibApp from '@vl/mod-clients/fibGatsby';

import { BaseModel } from '@uz/unitz-models/BaseModel';

import Stream from '@vl/mod-utils/Stream';
import moment from 'moment';
import { durationFormatter } from '@vl/mod-utils/currencyFormatter';

import VideoCallState from './VideoCallState';
import { INTERNET_STATES } from './constants';

const NOTIF_INTERVAL = 2000;

const EventType = {};
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
class InstanceMap {
  items = {};

  add(instance) {
    const id = instance.get('id');
    if (!id) return null;
    this.items[id] = instance;
  }

  get(id) {
    return this.items[id];
  }
}

// export class VideoCallModel extends BaseModel {
export class VideoCallModel extends BaseModel {
  static instances = new InstanceMap();

  static CALL_STATES = {
    videoCallConnecting: 'videoCallConnecting',
    videoCallOnProgress: 'videoCallOnProgress',
    videoCallIncomming: 'videoCallIncomming',
    videoCallFinish: 'videoCallFinish',
    videoCallIniting: 'videoCallIniting',
    videoCallTaking: 'videoCallTaking',
    videoCallReject: 'videoCallReject',
  };

  static INTERNET_STATES = {
    ...INTERNET_STATES,
  };

  state = {
    callState: VideoCallModel.CALL_STATES.videoCallIniting,
    videoMute: false,
    shareScreen: false,
    cameraPosition: 'front',
    startTime: null,
    msg: '',
    remoteState: {
      videoMute: false,
    },
    localState: {
      videoMute: false,
    },
    myState: {
      micMute: false,
      videoMute: false,
      cameraPosition: '',
      screenTrackSid: false,
      shareScreen: false,
      collapse: true,
      internetStatus: INTERNET_STATES.connected,
    },
  };

  constructor(...args) {
    super(...args);
    this.constructor.initState(this);

    const stream = new Stream();

    this.set({
      stream,
      resolvers: {
        user: async () => {
          const user_id = this.get('user_id');
          console.log('user_id', user_id);
          // const user = await UserModel.fromId({ id: user_id });
          // return user;
          return { id: user_id };
        },
        advisor: async () => {
          const advisor_id = this.get('advisor_id');
          console.log('advisor_id', advisor_id);
          // const instance = await AdvisorModel.fromId({ id: advisor_id });
          // return instance;
          // TODO: APP NÊN SỬA CHỔ NÀY.
          return { id: advisor_id };
        },
      },
    });

    // configure stream reducer
    stream.on('notify', async (msg) => {
      // reschedule notify stream
      const notifTimer = setTimeout(() => {
        stream.emit('notify', msg);
      }, NOTIF_INTERVAL);
      this.set({ notifTimer });
    });

    stream.on('stopNotify', async () => {
      // remove timer
      const notifTimer = this.get('notifTimer');
      if (notifTimer) {
        clearTimeout(notifTimer);
        this.set({ notifTimer: null });
      }
      // await notifee.cancelNotification(notifId);
    });

    // effect to start call
    this.useEffect(
      () => {
        if (this.hasCallState(VideoCallModel.CALL_STATES.videoCallTaking)) {
          this.startCall();
          this.startTimer();
        }
      },
      () => [this.getCallState()]
    );
    this.useEffect(
      () => {
        if (this.getState('states.startTime') && this.hasCallState(VideoCallModel.CALL_STATES.videoCallConnecting)) {
          this.startCall();
          this.startTimer();
        }
      },
      () => [this.getState('states.startTime')]
    );
    // effect to end call
    this.useEffect(
      () => {
        if (
          this.getState('states.endTime') &&
          this.getState('states.videoCallComplete') &&
          !this.hasCallState(VideoCallModel.CALL_STATES.videoCallFinish)
        ) {
          this.setState({
            endTime: moment(),
            // callState: VideoCallModel.CALL_STATES.videoCallFinish,
          });
        }
      },
      () => [this.getState('states.endTime'), this.getState('states.videoCallComplete')]
    );

    this.state.once('onDestroy', () => {
      this.isMounted = false;
      this.stopNotifySession();

      if (
        this.hasCallState(VideoCallModel.CALL_STATES.videoCallConnecting) ||
        this.hasCallState(VideoCallModel.CALL_STATES.videoCallIncomming) ||
        this.hasCallState(VideoCallModel.CALL_STATES.videoCallIniting)
      ) {
        this.setState('states.videoCallReject', true);
      }
    });
  }

  static create(data, opts) {
    const instance = new VideoCallModel();
    const id = _.get(data, 'data.session_id');

    instance.set({ ...opts, msg: data, id });

    this.instances.add(instance);
    //
    return instance;
  }

  static fromMessage(msg, ctx) {
    const id = _.get(msg, 'data.session_id') || _.get(msg, 'id');
    let instance = this.instances.get(id);
    if (!instance) {
      instance = this.create(msg);
    }

    const user_id = _.get(msg, 'data.user_id');

    const advisor_id = _.get(msg, 'data.advisor_id');

    const service_id = _.get(msg, 'data.service_id');

    const session_id = _.get(msg, 'data.session_id');

    if (user_id && advisor_id && service_id && session_id) {
      instance.set({
        user_id,
        advisor_id,
        service_id,
        session_id,
      });
      instance.connectStore(ctx);
    }

    instance.ctx = ctx;

    return instance;
  }

  static async fromSession({ user_id, advisor_id }) {
    try {
      const instance = await VideoCallModel.create({ user_id, advisor_id });
      await instance.createSession();
      return instance;
    } catch (err) {
      console.log('ererer', err);
    }
  }

  static fromCall(callData) {
    try {
      const instance = VideoCallModel.create();

      callData && instance.set(callData);
      return instance;
    } catch (err) {
      console.log('ererer', err);
    }
  }

  connectStore(ctx) {
    // store
    const id = this.get('session_id');

    const user_id = this.get('user_id');
    const advisor_id = this.get('advisor_id');
    const room_id = this.get('room_id');

    this.state.enhance(
      new VideoCallState(
        {
          id,
          user_id,
          advisor_id,
          room_id,
        },
        ctx
      )
    );
  }

  getSectionId() {
    const msg = this.get('msg');
    const id = _.get(msg, 'data.session_id');
    return id;
  }

  getSectionToken() {
    const msg = this.get('msg');
    const token = _.get(msg, 'data.session_token');
    return token;
  }

  getSectionKey() {
    const msg = this.get('msg');
    const key = _.get(msg, 'data.session_key');
    return key;
  }

  async createSession(ctx) {
    // return null;

    this.setState({
      msg: 'AdvisorVideoCall.initingStatusTxt',
    });

    try {
      const user_id = this.get('user_id');
      const advisor_id = this.get('advisor_id');
      const room_id = this.get('room_id');

      const params = {
        user_id,
        advisor_id,
        room_id,
      };
      let data;

      const retryCall = async (asyncFunc, count = 3) => {
        try {
          let res = await asyncFunc();
          const errors = _.get(res, 'errors');
          console.log({ count, res });
          if (errors) {
            if (count === 0) return res;
            await sleep(2000);
            return await retryCall(asyncFunc, count - 1);
          }
          if (!errors) {
            return res;
          }
        } catch (err) {
          if (count === 0) throw err;
          await sleep(2000);
          return await retryCall(asyncFunc, count - 1);
        }
      };
      data = await retryCall(async () => {
        return await fbFnsClient.getClient().post('course-attendRoom', params);
      }, 2);
      const errors = _.get(data, 'errors');
      if (errors) {
        throw errors;
      }
      if (_.get(data, 'message.length', 0) > 0) {
        return this.setState({
          msg: { text: _.get(data, 'message') },
        });
      }

      this.set('session_id', _.get(data, 'session_id'));

      this.connectStore(ctx);
      this.ctx = ctx;

      this.set({ msg: { data } });
      // update call state
      this.setState({
        msg: 'AdvisorVideoCall.ringingStatusTxt',
        callState: VideoCallModel.CALL_STATES.videoCallConnecting,
      });
      ctx.apply('loungeRoomModel.setResponse', { success: true });
      return data;
    } catch (err) {
      console.log('create session error', err);
      const manual_handle_error = this.get('manual_handle_error');

      const errorHandler = ctx && ctx.get('errorHandler');
      if (manual_handle_error) {
        ctx.apply('loungeRoomModel.setResponse', { error: err, errorHandler });
      } else {
        errorHandler && errorHandler(err);
      }
      this.setState({
        msg: { text: err.toString() },
      });
    }
  }

  async notifySession() {
    console.log('@notifySession');
    this.set({ stopNotifySession: false });
    const msg = this.get('msg');

    const notifId = _.get(msg, 'data.session_id');
    this.set({ notifId, msg });
    this.get('stream').emit('notify', msg);
    this.watchNotifTimeout();
  }

  async stopNotifySession() {
    this.set({ stopNotifySession: true });
    this.get('stream').emit('stopNotify');
  }

  watchNotifTimeout() {
    console.log('@watchNotifTimeout');
    const notifId = this.get('notifId');
    const watcherKey = `notifListener.${notifId}`;
    if (!this.get(watcherKey)) {
      const db = fibApp.getClient().firestore();

      const docRef = db.collection('videocalls').doc(notifId);
      const disposers = [];
      disposers.push(
        docRef.onSnapshot((docData) => {
          const data = docData.data();
          if (_.get(data, 'states.isNoAnswer')) {
            this.stopNotifySession();
          }
        })
      );

      disposers.push(
        this.get('stream').on('stopNotify', () => {
          disposers.map((dis) => dis());
          disposers.splice(0, disposers.length);
        })
      );
      this.set(watcherKey, true);
    }
  }

  /**
   * handlers for VideoCallModel
   *
   * @memberof VideoCallModel
   */
  switchCamera() {
    const cameraPositions = ['front', 'back'];
    const cameraPosition = this.getState('cameraPosition');
    const currIndex = _.indexOf(cameraPositions, cameraPosition);
    const newPos = cameraPositions[(currIndex + 1) % cameraPositions.length];
    this.setState('myState.cameraPosition', newPos);
    this.setState({
      cameraPosition: newPos,
    });
  }

  toggleMic() {
    this.setState('myState.micMute', !this.getState('myState.micMute'));
  }

  toggleVideo() {
    this.setState('myState.videoMute', !this.getState('myState.videoMute'));
  }

  toggleShareScreen() {
    this.setState('shareScreen', !this.getState('shareScreen'));
  }

  toggleVideoSubscriber() {
    this.setState('remoteState.videoMute', !this.getState('remoteState.videoMute'));
  }

  collapseVideo() {
    this.setState('myState.collapse', true);
  }

  separateVideo() {
    this.setState('myState.collapse', false);
  }

  reConnecting() {
    this.setState('myState.internetStatus', VideoCallModel.INTERNET_STATES.reconnecting);
  }

  reConnected() {
    this.setState('myState.internetStatus', VideoCallModel.INTERNET_STATES.connected);
  }

  disConnected() {
    if (this.getCallState() !== VideoCallModel.CALL_STATES.videoCallFinish) {
      this.setState('myState.internetStatus', VideoCallModel.INTERNET_STATES.disconnect);
    }
  }

  isConnected() {
    return (
      this.useState('myState.internetStatus') === VideoCallModel.INTERNET_STATES.connected &&
      this.useState('otherState.internetStatus') === VideoCallModel.INTERNET_STATES.connected
    );
  }

  isConnecting() {
    return (
      this.useState('myState.internetStatus') === VideoCallModel.INTERNET_STATES.reconnecting ||
      this.useState('otherState.internetStatus') === VideoCallModel.INTERNET_STATES.reconnecting
    );
  }

  isDisconnected() {
    return this.useState('myState.internetStatus') === VideoCallModel.INTERNET_STATES.disconnect;
  }

  async startCall() {
    this.setState({
      callState: VideoCallModel.CALL_STATES.videoCallOnProgress,
    });
  }

  takeCall() {
    console.log('take the call, update state');
    this.stopNotifySession();
    this.setState({
      callState: VideoCallModel.CALL_STATES.videoCallTaking,
    });
  }

  startTimer() {
    if (!this.getState('states.startTime')) {
      this.setState('states.startTime', moment().valueOf());
    }
    this.setState({
      startTime: moment(this.getState('states.startTime')),
    });
  }

  endCall() {
    if (
      this.hasCallState(VideoCallModel.CALL_STATES.videoCallConnecting) ||
      this.hasCallState(VideoCallModel.CALL_STATES.videoCallIncomming)
    ) {
      this.setState('states.videoCallReject', true);
      return;
    }
    this.setState('states.endTime', moment().valueOf());
    this.setState('states.videoCallComplete', true);
  }

  startIncomingCall() {
    this.setState({
      callState: VideoCallModel.CALL_STATES.videoCallIncomming,
    });
  }

  rejectCall() {
    // try to go to previous screen
    // routes.goBack();
    this.setState('states.videoCallReject', true);
  }

  /**
   * getter for VideoCallModel
   *
   * @returns
   * @memberof VideoCallModel
   */
  getCallState() {
    const callState = this.useState('callState');
    // console.log('---hasCallState', callState);
    return callState;
  }

  hasCallState(state) {
    return this.getCallState() === state;
  }

  getCallDuration() {
    const startTime = this.useState('startTime');
    if (!startTime) return '';

    const now = moment();
    const duration = moment.duration(now.diff(startTime));
    return durationFormatter(duration.asMilliseconds());
  }

  getCallDurationTotal(format = 'MM') {
    const startTime = this.getState('startTime');
    const endTime = this.getState('endTime');
    if (!startTime || !endTime) return '';

    const duration = moment.duration(endTime.diff(startTime));
    return durationFormatter(duration.asMilliseconds());
  }

  getCallMaxDuration() {
    const msg = this.get('msg');
    const durationSecondsStr = _.get(msg, 'data.session_duration');
    const duration = moment.duration(durationSecondsStr, 'seconds');

    return durationFormatter(duration.asMilliseconds());
  }

  isPinStream(stream_id) {
    const pinTracks = this.useState('tracks.pins');
    return _.includes(pinTracks, stream_id);
  }

  pinStream(stream_id) {
    const pinTracks = _.xor(this.getState('tracks.pins'), [stream_id]);
    this.setState('tracks.pins', pinTracks);
  }

  static async onForegroundEvent(ev) {
    const { type, detail } = ev;
    if (type === EventType.ACTION_PRESS) {
      const action = _.get(detail, 'pressAction.id');
      const msg = _.get(detail, 'notification');
      const instance = await VideoCallModel.fromMessage(msg);
      if (instance) {
        const handlers = instance.get('actions');
        const handler = _.get(handlers, [action]);
        if (_.isFunction(handler)) handler(msg);
      }
    }
  }

  static async onBackgroundEvent(ev) {
    const { type, detail } = ev;
    if (type === EventType.ACTION_PRESS) {
      console.log('type, detail', type, detail.pressAction);
    } else {
      console.log('tyatyatya', type);
    }
  }
}

// graphme.model({ VideoCallModel });

export default VideoCallModel;
