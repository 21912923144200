import React from 'react';
import _ from 'lodash';

import 'emoji-mart/css/emoji-mart.css';
import { NimblePicker } from 'emoji-mart';
import emojiData from 'emoji-mart/data/apple.json';

const elementRef = {
  listeners: [],
  on: (event, cb) => {
    _.update(elementRef.listeners, [event], (items) => {
      const rtn = items || [];
      rtn.push(cb);
      return rtn;
    });
    return () => {
      const listeners = _.get(elementRef.listeners, [event]);
      const foundIndex = listeners.indexOf(cb);
      if (foundIndex >= 0) {
        listeners.splice(foundIndex, 1);
      }
    };
  },
  emit: (event, ...args) => {
    const listeners = _.get(elementRef.listeners, [event]);
    if (listeners) {
      for (const listener of listeners) {
        listener && listener(...args);
      }
    }
  },
  onClick: (...args) => {
    elementRef.emit('click', ...args);
  },
};

export const MessageReactionPicker = ({ onClick }) => {
  const ref = React.useRef({});
  React.useEffect(() => {
    const reactionPickerLoaderEle = document.getElementById('reactionPickerLoader');
    const messageReactionPickerEle = document.getElementById('messageReactionPicker');
    ref.current.disposer = elementRef.on('click', onClick);
    ref.current.timer = setInterval(() => {
      const messageReactionPickerEleBounds = messageReactionPickerEle.getBoundingClientRect();
      const left = `${messageReactionPickerEleBounds.left}px`;
      const top = `${messageReactionPickerEleBounds.top}px`;
      if (left !== ref.current.left || top !== ref.current.top) {
        ref.current.left = 0;
        ref.current.top = top;

        reactionPickerLoaderEle.style.left = ref.current.left;
        reactionPickerLoaderEle.style.top = ref.current.top;
        reactionPickerLoaderEle.style.display = 'block';
      }
    }, 60);
    return () => {
      reactionPickerLoaderEle.style.display = 'none';
      ref.current.disposer && ref.current.disposer();
      ref.current.timer && clearInterval(ref.current.timer);
      ref.current = {};
    };
  }, []);
  return (
    <div
      className=""
      style={{
        width: 343,
        height: 352,
      }}
      id="messageReactionPicker"
    ></div>
  );
};

export const ReactionPickerLoader = () => {
  return (
    <div id="reactionPickerLoader" style={{ display: 'none', position: 'absolute', zIndex: 10000 }}>
      <NimblePicker
        emojiTooltip={false}
        showPreview={false}
        showSkinTones={false}
        title="Emoji"
        skin={1}
        data={emojiData}
        set="apple"
        onClick={elementRef.onClick}
      />
    </div>
  );
};

export default MessageReactionPicker;
