import React from 'react';
import _ from 'lodash';
import { getDeviceInfo } from '@vl/mod-utils/deviceInfoWeb';
import { Menu } from 'antd';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

export const AudioSettingsMenu = () => {
  const [audioInputDevices, $audioInputDevices] = React.useState([]);
  const [audioOutputDevices, $audioOutputDevices] = React.useState([]);
  const [audioInputDeviceIndex, $audioInputDeviceIndex] = useLocalStorage('@UZ::audioInputDeviceIndex', 0);
  const [audioOutputDeviceIndex, $audioOutputDeviceIndex] = useLocalStorage('@UZ::audioOutputDeviceIndex', 0);
  const audioInputDeviceKey = `input.${_.get(audioInputDevices, `${audioInputDeviceIndex}.deviceId`)}`;
  const audioOutputDeviceKey = `output.${_.get(audioOutputDevices, `${audioOutputDeviceIndex}.deviceId`)}`;
  const selectHandlers = {
    input: (deviceId) => {
      const foundIndex = _.findIndex(audioInputDevices, { deviceId });
      if (foundIndex >= 0) {
        $audioInputDeviceIndex(foundIndex);
      }
    },
    output: (deviceId) => {
      const foundIndex = _.findIndex(audioOutputDevices, { deviceId });
      if (foundIndex >= 0) {
        $audioOutputDeviceIndex(foundIndex);
      }
    },
  };

  const ref = React.useRef({});
  _.assign(ref.current, { audioInputDevices, $audioInputDevices, audioOutputDevices, $audioOutputDevices });
  React.useEffect(() => {
    getDeviceInfo().then((res) => {
      if (_.size(ref.current.audioInputDevices) !== _.size(res.audioInputDevices)) {
        ref.current.$audioInputDevices(res.audioInputDevices);
      }
      if (_.size(ref.current.audioOutputDevices) !== _.size(res.audioOutputDevices)) {
        ref.current.$audioOutputDevices(res.audioOutputDevices);
      }
    });
  });
  return (
    <Menu
      style={{ width: 320 }}
      selectedKeys={[audioInputDeviceKey, audioOutputDeviceKey]}
      triggerSubMenuAction="click"
      onSelect={({ key }) => {
        const [type, deviceId] = _.split(key, '.');
        if (selectHandlers[type]) {
          selectHandlers[type](deviceId);
        }
      }}
      theme="dark"
    >
      <Menu.ItemGroup key="g1" title="Select a microphone">
        {_.map(audioInputDevices, (item) => {
          return <Menu.Item key={`input.${item.deviceId}`}>{item.label}</Menu.Item>;
        })}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup key="g2" title="Select a speaker">
        {_.map(audioOutputDevices, (item) => {
          return <Menu.Item key={`output.${item.deviceId}`}>{item.label}</Menu.Item>;
        })}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="5">Audio Settings ...</Menu.Item>
    </Menu>
  );
};

export default AudioSettingsMenu;
