import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Checkbox } from 'antd';
import { setTimeoutAt, clearTimeout } from '@vl/mod-utils/safeTimers';
import moment from 'moment';
import useRoute from '@vl/hooks/useGbRoute';

const AutoJoinRoomCheckbox = ({ value }) => {
  const ref = React.useRef({});
  const [autoJoin, $autoJoin] = React.useState(!!value);
  const route = useRoute();

  React.useEffect(() => {
    if (ref.current.timer) {
      clearTimeout(ref.current.timer);
      ref.current.timer = null;
    }
    if (autoJoin && ref.current.room) {
      const start_at = _.get(ref.current.room, 'start_at');
      const $start_at = moment(start_at);

      ref.current.timer = setTimeoutAt(() => {
        const roomUrl = route.toUrl('room', ref.current.room);
        roomUrl && route.navigateExternal(`${roomUrl}&redirect_code=auto_join`);
      }, $start_at.toDate());
    }
  }, [autoJoin]);
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ref.current.room = ctx.get('room');
      })}
      <Checkbox
        checked={autoJoin}
        onChange={(event) => {
          $autoJoin(_.get(event, 'target.checked'));
        }}
      >
        <span className="text-white">{ctx.apply('i18n.t', 'AdvisorCourse.roomActions.autoJoin')}</span>
      </Checkbox>
    </DIV>
  );
};

export default AutoJoinRoomCheckbox;
