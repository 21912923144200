import _ from 'lodash';
// import UserModelFormatter from '@uz/unitz-models/UserModel/formatter';
// import * as tus from 'tus-js-client';
import axios from 'axios';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import vimeoClient from '@vl/mod-clients/vimeo';

const createPromiseRef = () => {
  const rtn = {};
  rtn.promise = new Promise((res, rej) => {
    rtn.res = res;
    rtn.rej = rej;
  });
  return rtn;
};

export const formatter = {
  uploadVideoCDN: (ctx) => async (req) => {
    const { file } = req;
    // const firebase = ctx.get('firebase');
    const userId = ctx.apply('authModel.getUserId');
    const uploadCredential = await fbFnsClient.getClient().post('asset-createUploadVideoUrl', { user_id: userId });
    // // const tusUrl = await fbFnsClient.getClient().url('asset-tusUploadVideoUrl', _.get(uploadCredential, 'data'));
    // const tusUrl = await fbFnsClient.getClient().url('asset-createUploadVideoUrl', _.get(uploadCredential, 'data'));
    const uploadURL = _.get(uploadCredential, 'data.uploadURL');
    // const uploadURL = _.get(uploadCredential, 'data.videoURL');

    // const upload = new tus.Upload(file, {
    //   endpoint: tusUrl,
    //   // headers: {
    //   //   Authorization: `Bearer ${api_token}`,
    //   // },
    //   retryDelays: [0, 3000, 5000, 10000, 20000],
    //   chunkSize: 5 * 1024 * 1024, // Required a minimum chunk size of 5MB, here we use 50MB.
    //   // resume: true,
    //   metadata: {
    //     filename: file.name,
    //     filetype: file.type,
    //     internal_user_id: userId,
    //   },
    //   // overridePatchMethod: true,
    //   uploadSize: file.size,
    //   onError: function(error) {
    //     throw error;
    //   },
    //   onProgress: function(bytesUploaded, bytesTotal) {
    //     const percent = Math.floor(((bytesUploaded / bytesTotal) * 100).toFixed(2));
    //     console.log(bytesUploaded, bytesTotal, percent);
    //     req.onProgress && req.onProgress({ percent }, file);
    //   },
    //   onSuccess: function() {
    //     console.log('Download %s from %s', upload.file.name, upload.url);
    //     console.log('uploadupload', upload);
    //   },
    // });
    // upload.start();

    const formData = new window.FormData();
    formData.append('file', file);
    const upload = axios.post(uploadURL, formData, {
      onUploadProgress: (progressEvent) => {
        const percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        req.onProgress && req.onProgress({ percent }, file);
      },
    });
    const watchURL = `${uploadURL}`.replace('//upload.', '//watch.');

    return { url: watchURL, task: upload };
  },

  uploadVideoVimeo: (ctx) => async (req) => {
    const { file } = req;
    const userId = ctx.apply('authModel.getUserId');
    const uploadCredential = await fbFnsClient.getClient().post('asset-vimeoUpload', { user_id: userId });
    const access_token = _.get(uploadCredential, 'data.access_token');
    const task = createPromiseRef();
    const getUrlById = (videoId) => `https://vimeo.com/${videoId}`;

    const rtn = {
      // placeholder video for upload progress?
      url: vimeoClient.getClient().getVimeoEmbedUrl(getUrlById('unitz')),
      task: task.promise,
    };
    const { VimeoUpload } = vimeoClient.getClient();
    rtn.uploader = new VimeoUpload({
      file,
      token: access_token,
      name: `${_.get(file, 'name')}`,
      videoMetadata: {
        'embed.buttons.embed': false,
        'embed.buttons.like': false,
        'embed.buttons.share': false,
        'embed.buttons.watchlater': false,
        'embed.color': '#2eb2aa',
        'embed.event_schedule': false,
        'embed.logos.vimeo': false,
        'embed.title.name': 'hide',
        'embed.title.owner': 'hide',
        'embed.title.portrait': 'hide',
        'privacy.comments': 'nobody',
        'privacy.download': false,
        // 'privacy.embed': 'whitelist',
        'embed.end_screen.type': 'loop',
      },
      onError: (data) => {
        console.log('onError', data);
      },
      onProgress: (data) => {
        const percent = Math.floor((data.loaded * 100) / data.total);
        req.onProgress && req.onProgress({ percent }, file);
      },
      onComplete: async (videoId) => {
        let url = getUrlById(videoId);
        rtn.url = vimeoClient.getClient().getVimeoEmbedUrl(url);
        // wait for file viewable
        rtn.uploader.waitUntilAvailable(videoId, () => {
          task.res(rtn);
          req.onComplete && req.onComplete(file);
        });
      },
    });

    rtn.uploader.upload();

    await rtn.task;
    return rtn;
  },
};

export default formatter;
