import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import Button from '@uz/unitz-components-web/Button';
import AudioSettingsMenu from '@uz/unitz-components-web/AudioSettingsMenu';
import VideoSettingsMenu from '@uz/unitz-components-web/VideoSettingsMenu';
import Link from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import styled from 'styled-components';
import LayoutRoomCall from '@uz/unitz-layout-web/LayoutRoomCall';
import i18n from 'i18n-js';
import { Dropdown } from 'antd';
import RoomParticipants from '@uz/unitz-components-web/RoomWebComponents/RoomParticipants';
import ControlButton from '@uz/unitz-components-web/RoomWebComponents/StyledControlButton';

const ButtonGroups = styled.div`
  border-radius: 24px;
  background: ${gstyles.colors.black400};
  button {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn-text {
    padding-right: 2px;
  }
  .ant-dropdown-trigger {
    padding-left: 2px;
    padding-right: 2px;
    width: 30px;
  }
  > div.share-screen {
    button {
      padding-right: 12px;
      &.ant-btn {
        svg {
          filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(158deg) brightness(92%) contrast(119%) !important;
        }
      }
    }
  }
  > div.leave-room {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
  }
`;

const View8 = () => {
  const url = `https://unitz.biz/${i18n.locale}/guides#classroom?referral=classroom`;
  return (
    <DIV className="videoCallConnecting">
      <div className={cx('h-full', 'z-10 flex justify-between items-center w-full relative')}>
        <LayoutRoomCall.RenderPOS name="content-footer-start">
          <div className="flex items-center h-full justify-center absolute inset-y left-0 pl-4">
            <ControlButton
              icon={gstyles.icons({
                name: 'message',
                fill: gstyles.colors.white500,
                size: 24,
              })}
              onClick={() => {
                const messageControlModelRef = ctx.apply('REF.getRef', 'messageControlModelRef');
                if (messageControlModelRef) {
                  messageControlModelRef.toggle();
                }
              }}
              // text={ctx.apply('i18n.t', 'VideoCallPage.SectionAction.message')}
            />
          </div>
        </LayoutRoomCall.RenderPOS>
        <LayoutRoomCall.RenderPOS name="content-footer-middle"></LayoutRoomCall.RenderPOS>
        <LayoutRoomCall.RenderPOS name="content-footer-end">
          <div className="flex items-center h-full justify-center absolute inset-y right-0 pr-4">
            <Link target="_blank" to={url}>
              {gstyles.icons({ name: 'guide', size: 24 })}
            </Link>
            <RoomParticipants />
          </div>
        </LayoutRoomCall.RenderPOS>
      </div>
    </DIV>
  );
};

export default displayName(View8);
