import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import RecordButton from '@uz/unitz-components-web/RecordButton';
import _ from 'lodash';
import ActionButton from '@uz/unitz-components-web/RoomWebComponents/StyledActionButton';

const View8 = () => {
  return (
    <DIV className="OTPublisherActions">
      <div className="flex absolute space-x-1 inset-x-0 bottom-0">
        <div className="col-start flex space-x-1 bg-black bg-opacity-5 px-1"></div>
        <div className="flex-1 col-center flex space-x-1"></div>
        <div className="col-end flex space-x-1 bg-black bg-opacity-5 px-1">
          <RecordButton
            ButtonComponent={ActionButton}
            mediaRef={() => ctx.apply('REF.getRef', ctx.get('@item.stream_id'))}
            icon={gstyles.icons({
              name: 'record-2',
              fill: gstyles.colors.white500,
              size: 14,
            })}
            duration={15 * 60}
            loading={false}
            name="overlay"
            type="primary"
          />
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
