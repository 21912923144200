import _ from 'lodash';
import Glightbox from '@uz/unitz-components-web/Glightbox';

const VideoBlot = {
  createHTML(value) {
    const embedsrc = _.get(value, 'embedsrc') || _.get(value, 'src');
    const src = _.get(value, 'src');
    const html = `<a class="glightbox" href="${src}" style="display: block; position:relative;"><div style="padding: 8px 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${src}</div><iframe src="${embedsrc} frameborder="0" allowfullscreen/></a>`;

    setTimeout(() => {
      const instance = Glightbox.getInstance();
      if (instance) {
        instance.insertSlide({
          href: _.get(value, 'src'),
          type: 'video',
          width: _.get(value, 'width'),
        });
      }
      Glightbox.apply();
    }, 100);
    return html;
  },

  renderCustomWith(customOp) {
    const { value } = customOp.insert;
    if (!value) return '';
    const id = _.get(value, 'id');
    const html = `<div id="${id}" class="${
      VideoBlot.className
    }" style="position: relative; margin: 16px;">${VideoBlot.createHTML(value)}</div>`;

    return html;
  },

  register(registerRender) {
    registerRender(VideoBlot.blotName, VideoBlot.renderCustomWith);
  },
};

VideoBlot.blotName = 'iframe-video';
VideoBlot.className = 'iframe-video';
VideoBlot.tagName = 'div';

export default VideoBlot;
