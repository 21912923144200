const _ = require('lodash');

class Stream {
  listeners = {};

  emit(event, ...params) {
    _.get(this.listeners, [event], []).map((listener) => listener.call(this, ...params));
  }

  on(event, listener) {
    _.update(this.listeners, [event], (val) => (val ? val.concat(listener) : [listener]));
    return () => {
      _.update(this.listeners, [event], (val) => _.reject(val || [], listener));
    };
  }

  proxy(stream, events) {
    const dispoers = [].concat(events || _.keys(stream.listeners)).map((event) => {
      return stream.on(event, (...params) => {
        this.emit(event, ...params);
      });
    });
    return () => dispoers.map((dis) => dis());
  }
}

module.exports = Stream;
