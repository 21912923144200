import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Glightbox from '@uz/unitz-components-web/Glightbox';
import { getLeadId } from '@uz/unitz-tool-components/Collab/Events/Lead';
import GundbClient from '@vl/mod-clients/gundb';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import SparkMD5 from 'spark-md5';

const equalSyncProps = ['speed', 'ratio'];
const stateSyncProps = ['paused', 'playing', 'stopped'];
const tolSyncProps = ['currentTime'];

export const Collab = ({ sessionId }) => {
  const ref = React.useRef({ diposers: [] });
  const disposerCleaner = useDisposerCleaner();
  const forceUpdate = useForceUpdate();

  const getPlayerProps = (player) => {
    return _.pick(player, [...equalSyncProps, ...tolSyncProps, ...stateSyncProps]);
  };

  const getActivePlyr = () => {
    const instance = Glightbox.getInstance();
    if (instance) {
      const activeIndex = instance.getActiveSlideIndex();
      const player = instance.getSlidePlayerInstance(activeIndex);
      return player;
    }
  };

  const syncPlayerProps = (toPlayer, fromPlayer) => {
    _.assign(toPlayer, _.pick(fromPlayer, equalSyncProps));
    if (Math.abs(_.get(toPlayer, 'currentTime') - _.get(fromPlayer, 'currentTime')) >= 0.5) {
      _.assign(toPlayer, _.pick(fromPlayer, tolSyncProps));
    }
  };

  const trackKey = (userId) => {
    _.update(ref.current, `trackMap.${userId}`, (node) => {
      if (node) return node;
      const docRef = GundbClient.getClient().getEventStream(`plyr_${sessionId}_${userId}`);
      if (docRef) {
        docRef.onEvent((item) => {
          const id = _.get(item, 'id');
          const action = _.get(item, 'action');
          if (id === getLeadId()) {
            const instance = Glightbox.getInstance();
            const player = getActivePlyr();
            const currentTime = _.get(item, 'currentTime');
            if (instance && player) {
              if (action === 'playing') {
                syncPlayerProps(player, item);
                player.currentTime = currentTime;
                player.play();
              } else if (action === 'paused') {
                syncPlayerProps(player, item);
                player.currentTime = currentTime;
                !player.paused && player.pause();
              } else if (action === 'sync') {
                if (item.paused) {
                  syncPlayerProps(player, item);
                  if (!player.paused) {
                    player.currentTime = currentTime;
                    player.pause();
                  }
                  syncPlayerProps(player, item);
                } else if (item.playing) {
                  syncPlayerProps(player, item);
                  if (!player.playing) {
                    player.currentTime = currentTime;
                    player.play();
                  }
                }
              }
            }
          }
        });
        return () => {
          // docRef.off();
          // delete cusor
        };
      }
    });
  };

  React.useEffect(() => {
    const { ctx } = ref.current;
    if (!ctx) return () => {};
    const userId = ctx.apply('authModel.getUserId');

    // setup listener
    const sessionRef = GundbClient.getClient().getDoc(`sessions_${sessionId}`);
    sessionRef.map().on((item) => {
      const id = _.get(item, 'id');
      if (id === userId) return;
      trackKey(id);
    });
    if (userId) {
      sessionRef.get(userId).put({ id: userId, value: userId });
    }

    disposerCleaner(
      Glightbox.on('slide_changed', ({ current }) => {
        const { slideIndex, slideNode, player, slideConfig } = current;
        const slideHashId = SparkMD5.hash(JSON.stringify(slideConfig));
        ref.current.slideHashId = slideHashId;
        ref.current.activePlayer = player;
        ref.current.activeSlideIndex = slideIndex;
        if (player) {
          const docRef = GundbClient.getClient().getEventStream(`plyr_${sessionId}_${userId}`);
          if (!player.ready) {
            // If player is not ready
            player.on('ready', (event) => {
              // Do something when video is ready
              console.log('player ready');
            });
          }

          player.on('playing', () => {
            docRef.putEvent({
              id: userId,
              action: 'playing',
              slideIndex,
              slideHashId,
              ...getPlayerProps(player),
            });
          });

          player.on('play', (event) => {
            console.log('Started play');
          });

          player.on('pause', (event) => {
            docRef.putEvent({
              id: userId,
              action: 'paused',
              slideIndex,
              slideHashId,
              ...getPlayerProps(player),
            });
          });

          player.on('seeked', (event) => {
            console.log('seeked');
          });

          player.on('timeupdate', (event) => {
            // console.log('timeupdate', event);
          });

          player.on('volumechange', (event) => {
            console.log('Volume change');
          });

          player.on('ended', (event) => {
            console.log('Video ended');
          });
        }
      })
    );
    // interval update player status
    disposerCleaner(
      (() => {
        const docRef = GundbClient.getClient().getEventStream(`plyr_${sessionId}_${userId}`);
        const timer = setInterval(() => {
          if (ref.current.activePlayer) {
            docRef.putEvent({
              id: userId,
              action: 'sync',
              slideIndex: ref.current.activeSlideIndex,
              slideHashId: ref.current.slideHashId,
              ...getPlayerProps(ref.current.activePlayer),
            });
          }
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      })()
    );

    return () => {
      sessionRef.off();
      _.map(ref.current.trackMap, (disposer) => {
        disposer && disposer();
      });
      _.map(ref.current.diposers, (dis) => dis && dis());
      ref.current = {};
    };
  }, []);
  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
    </DIV>
  );
};

export default Collab;
