import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import MessageStore from '@uz/unitz-tool-components/Message/Store';
import { Tag } from 'antd';
import { Button } from '@uz/unitz-components-web/Button';

export const MessageReplyInputIndicator = ({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const hasReplies = !!_.get(message, 'replies.length');
  const [isReplying, $isReplying] = MessageStore.useMessageState(message_id, 'replying', false);
  const [, $isViewingReplies] = MessageStore.useMessageState(message_id, 'viewingReplies', false);
  const ref = React.useRef({});
  _.assign(ref.current, { isReplying, $isReplying, $isViewingReplies });

  React.useEffect(() => {
    const messageInputRef = ref.current.ctx.apply('REF.getRef', 'messageInputRef');
    if (isReplying) {
      const startReply = _.get(messageInputRef, 'current.handlers.startReply');
      if (_.isFunction(startReply)) {
        startReply(message_id).then(() => {
          $isReplying(false);
        });
        $isViewingReplies(true);
      }
    } else {
      const stopReply = _.get(messageInputRef, 'current.handlers.stopReply');
      if (_.isFunction(stopReply)) {
        stopReply(message_id);
      }
    }
  }, [isReplying]);

  React.useEffect(() => {
    return () => {
      ref.current = {};
    };
  }, []);
  return (
    <DIV>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
      })}
      {isReplying ? (
        <div className="flex items-center">
          <Tag closable onClose={() => $isReplying(false)}>
            {ctx.apply('i18n.t', 'Message.replyingIndicatorLabel')}
          </Tag>
        </div>
      ) : (
        hasReplies && (
          <div className="flex items-center">
            <Button
              type="link"
              onClick={() => {
                $isReplying(!isReplying);
              }}
              size="small"
            >
              {ctx.apply('i18n.t', 'Message.replyActionLabel')}
            </Button>
          </div>
        )
      )}
    </DIV>
  );
};

export default MessageReplyInputIndicator;
