import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Input } from 'antd';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import styled from 'styled-components';
import displayName from '@vl/redata/displayName.macro';

import ColorInput from './Inputs/Color';
import LineStyleInput from './Inputs/LineStyle';
import TypographyInput from './Inputs/Typography';
import FontSizeInput from './Inputs/FontSize';
import TextAlignInput from './Inputs/TextAlign';
import ListInput from './Inputs/List';
import AuthorInput from './Inputs/Author';
import RemoveInput from './Inputs/Remove';
import OwnerInput from './Inputs/Owner';

const StyledToolbar = styled.div`
  & > .ant-input-group.ant-input-group-compact {
    display: flex;
    flex-wrap: nowrap;
    &.ant-input-group-compact > :last-child {
      border-right: none;
    }
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(canvas.getActiveObject());
  const ref = React.useRef({});
  _.assign(ref.current, { target, $target, canvas });
  const disposerCleaner = useDisposerCleaner();

  React.useEffect(() => {
    disposerCleaner(
      canvas &&
        canvas.$on({
          'selection:created': (evt) => {
            const { target } = evt;
            ref.current.$target(target);
          },
          'selection:updated': (evt) => {
            const { target } = evt;
            ref.current.$target(target);
          },
          'selection:cleared': (evt) => {
            // console.log('selection:cleared', event);
            // ref.current.$target(target);
          },
        })
    );
  }, []);

  return (
    <StyledToolbar className="flex flex-row justify-center items-center">
      <Input.Group compact size="small">
        <ColorInput target={ref.current.target} canvas={canvas} />
        <LineStyleInput target={ref.current.target} canvas={canvas} />
        <TypographyInput target={ref.current.target} canvas={canvas} />
        <ListInput target={ref.current.target} canvas={canvas} />
        <TextAlignInput target={ref.current.target} canvas={canvas} />
        <FontSizeInput target={ref.current.target} canvas={canvas} />
        <AuthorInput target={ref.current.target} canvas={canvas} />
        <OwnerInput target={ref.current.target} canvas={canvas} />
        <RemoveInput target={ref.current.target} canvas={canvas} />
      </Input.Group>
    </StyledToolbar>
  );
};

export default displayName(Index);
