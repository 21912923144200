import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Button, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import displayName from '@vl/redata/displayName.macro';

const StyledSelect = styled.div`
  .ant-btn.ant-btn-sm {
    border: none;
    border-radius: 0px;
  }
`;

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });
  const forceUpdate = useForceUpdate();

  const getValue = React.useCallback(() => {
    const type = _.get(ref.current.target, 'type');
    if (['StickyNote', 'Picture'].includes(type)) {
      return ref.current.target.get('showAuthor');
    }
  }, [ref.current.target]);

  const applicableTarget = (() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    const type = _.get(ref.current.target, 'type');
    if (['StickyNote', 'Picture'].includes(type)) {
      return true;
    }
  })();

  const [value, $value] = React.useState(getValue);

  _.assign(ref.current, { value, $value });

  const onChange = React.useCallback(
    (val) => {
      ref.current.$value && ref.current.$value(val);
      _onChange && _onChange(value);
      const type = _.get(ref.current.target, 'type');
      if (['StickyNote', 'Picture'].includes(type)) {
        ref.current.target.set({
          showAuthor: val,
        });
        canvas && canvas.updateObject(ref.current.target);
      }
    },
    [ref.current.target]
  );

  React.useEffect(() => {
    const val = getValue();
    if (ref.current.value !== val) {
      ref.current.$value(val);
    }
  }, [ref.current.target]);

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Tooltip
        title={
          <div className="space-x-2">
            <span>{i18n.t('Whiteboard.toolbar.authorTooltip')}</span>
          </div>
        }
        key="leftButton"
      >
        <Button
          size="small"
          type={ref.current.value ? 'primary' : 'default'}
          onClick={async () => {
            onChange(!ref.current.target.get('showAuthor'));
            await new Promise((res) => setTimeout(res, 300));
            forceUpdate();
          }}
          {...rest}
        >
          {gstyles.icons({
            name: 'participant',
            size: 20,
            fill: ref.current.value ? gstyles.colors.white500 : gstyles.colors.black500,
          })}
        </Button>
      </Tooltip>
    </StyledSelect>
  );
};

export default displayName(Index);
