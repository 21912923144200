import React from 'react';
import _ from 'lodash';

import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

import BlockImageRender from '@uz/unitz-components-web/Message/Input/Quill/Blots/Renders/BlockImage';
import BlockFileRender from '@uz/unitz-components-web/Message/Input/Quill/Blots/Renders/BlockFile';
import BlockVideoRender from '@uz/unitz-components-web/Message/Input/Quill/Blots/Renders/BlockVideo';
import EmojiRender from '@uz/unitz-components-web/Message/Input/Quill/Blots/Renders/Emoji';
import MarkRender from '@uz/unitz-components-web/Message/Input/Quill/Blots/Renders/Mark';
import VideoRender from '@uz/unitz-components-web/Message/Input/Quill/Blots/Renders/Video';

const quillDeltaConfig = {};
const converter = new QuillDeltaToHtmlConverter([], quillDeltaConfig);
const BlotsMap = {};

export const registerRender = (type, renderCusom) => {
  if (_.isFunction(_.get(type, 'register'))) {
    type.register(registerRender);
  }
  _.set(BlotsMap, type, renderCusom);
};

registerRender(BlockFileRender);
registerRender(BlockImageRender);
registerRender(BlockVideoRender);
registerRender(EmojiRender);
registerRender(MarkRender);
registerRender(VideoRender);

converter.renderCustomWith((customOp, contextOp) => {
  const renderer = _.get(BlotsMap, customOp.insert.type);
  if (renderer) {
    const content = renderer(customOp, contextOp);
    if (!_.isUndefined(content)) return content;
  }
  return '';
});

// converter.afterRender(function(groupType, data) {
//   console.log('groupTypegroupTypegroupType', groupType, data);
// });

export const renderer = {
  renderMessage: () => (itemData) => {
    const message = _.get(itemData, 'message');
    const message_ops = _.get(itemData, 'message_jsonb.ops');
    if (!message) return '';
    converter.rawDeltaOps = message_ops;
    const message_html = converter.convert();
    // console.log('delta_htmldelta_html', message_ops, message_html);
    // const cleanHTML = messageXss.process(message);
    const cleanHTML = message_html;
    // console.log('message', `-x-${cleanHTML}-x-`, `--${message}--`);

    return React.createElement('div', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
};

export default renderer;
