import _ from 'lodash';

export const bindMouseEvents = (canvas) => {
  canvas.$on('mouse:up', (evt) => {
    const editingModes = _.pick(canvas, ['isEraserMode', 'isDrawingMode', 'isTextMode', 'isStickyNoteMode']);
    if (!evt.target && canvas.isMoveMode && !_.some(editingModes)) {
      // toggel focus mode
      setTimeout(() => {
        // simulate mouse:up event to prevent selecting state
        const evt = new window.MouseEvent('mouseup', {
          clientX: 0,
          clientY: 0,
        });
        canvas.upperCanvasEl.dispatchEvent(evt);
        canvas.toggleMoveMode(false);
      }, 130);
    }
  });
};
