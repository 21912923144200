import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';

import EraseInput from '../Inputs/Erase';
import Export from '../Inputs/Export';
import Import from '../Inputs/Import';
// import ZoomInput from '../Inputs/Zoom';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 32px !important;
    height: 32px !important
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const target = {};
    return target;
  });
  const ref = React.useRef({ disposers: [] });

  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return false;
  }, []);

  React.useEffect(() => {
    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        const { mode } = event;
        if (mode === 'hide') {
          if (canvas.isHideMode && canvas.htmlEle) {
            canvas.htmlEle.parentElement.style.visibility = 'hidden';
          } else {
            canvas.htmlEle.parentElement.style.visibility = 'visible';
          }
        }
        forceUpdate();
      })
    );
  }, []);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  return (
    <MenuItem
      type={canvas.isMoreMode ? 'primary' : 'text'}
      getPopupContainer={(node) => node && node.parentElement}
      // visible={ref.current.visible}
      trigger={['click']}
      onClick={() => {
        canvas.isHideMode = !canvas.isHideMode;
        canvas.fire('mode:changed', {
          mode: 'hide',
          source: 'more',
          value: !!canvas.isHideMode,
        });
        forceUpdate();
      }}
      overlay={() => {
        return (
          <div className="flex flex-row bg-white500 w-full">
            <div>
              <EraseInput target={ref.current.target} canvas={canvas} />
            </div>
            <div>
              <Export target={ref.current.target} canvas={canvas} />
            </div>
            <div>
              <Import target={ref.current.target} canvas={canvas} />
            </div>
            {/* <div>
              <ZoomInput target={ref.current.target} canvas={canvas} />
            </div> */}
          </div>
        );
      }}
      buttonsRender={([leftButton, rightButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>
                {canvas.isHideMode
                  ? i18n.t('Whiteboard.toolbar.showTooltip')
                  : i18n.t('Whiteboard.toolbar.hideTooltip')}
              </span>
            </div>
          }
          key="rightButton"
        >
          {leftButton}
        </Tooltip>,
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.moreTooltip')}</span>
            </div>
          }
          key="leftButton"
          placement="right"
        >
          {rightButton}
        </Tooltip>,
      ]}
      icon={
        <div className="flex justify-center">
          {gstyles.icons({ name: 'more', size: 20, fill: gstyles.colors.white500 })}
        </div>
      }
      placement="topLeft"
    >
      <span>
        {gstyles.icons({
          name: canvas.isHideMode ? 'hide-board' : 'on-board',
          size: 20,
          fill: gstyles.colors.white500,
        })}
      </span>
    </MenuItem>
  );
};

export default displayName(Index);
