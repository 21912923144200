import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import MessageStore from '@uz/unitz-tool-components/Message/Store';
import MessageTimer from '@uz/unitz-tool-components/Message/Components/MessageTimer';
import MessageReplyItem from '@uz/unitz-tool-components/Message/Components/MessageReplyItem';
import MessageReplyInputIndicator from '@uz/unitz-tool-components/Message/Components/MessageReplyInputIndicator';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { Button } from '@uz/unitz-components-web/Button';

export const MessageReply = ({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const [isViewingReplies, $isViewingReplies] = MessageStore.useMessageState(message_id, 'viewingReplies', false);

  if (!_.get(message, 'replies.length')) return null;
  const replies = _.get(message, 'replies');

  return (
    <DIV>
      {isViewingReplies && _.map(replies, (item) => <MessageReplyItem key={item.id} message_id={item.id} />)}
      <div className="flex items-center mt-1">
        {/* <Avatar
          className="object-cover rounded"
          size={24}
          src={UserProfileModelFormatter.avatarUrl()({ profile: _.get(message, 'author') })}
          shape="square"
        /> */}
        <Button type="link" onClick={() => $isViewingReplies(!isViewingReplies)} size="small">
          {isViewingReplies
            ? ctx.apply('i18n.t', 'Message.repliesCollapseLabel', { count: replies.length })
            : ctx.apply('i18n.t', 'Message.repliesCountLabel', { count: replies.length })}
        </Button>
        <MessageReplyInputIndicator message_id={message_id} />
        {/* <MessageTimer message_id={message_id} /> */}
      </div>
    </DIV>
  );
};

export default MessageReply;
