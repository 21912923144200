import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import Combokeys from 'combokeys';
import { isActivateMode, isDeactivateMode } from '@uz/unitz-components-web/Fabric/helper';

import ColorInput from '../Inputs/Color';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 2px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
    border-radius: 8px !important;
    &:last-child {
      display: none;
    }
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const { fabric } = window;
    const target = new fabric.StickyNote({
      text: '',
      owner_id: canvas.getOwnerId(),
      left: -10,
      top: -10,
      colorType: '#E05932',
      fill: '#F5BFB1',
      strokeWidth: 1,
      charSpacing: 10,
      fontSize: 16,
      paddingX: 10,
      paddingY: 10,
      width: 0,
      height: 0,
      selectable: false,
      isLocal: true,
      isToolbar: true,
      isToolbarActive: false,
      isStickyNoteMode: false,
      showAuthor: false,
      opacity: 0,
    });
    canvas.add(target);
    return target;
  });
  const ref = React.useRef({ disposers: [] });

  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return !!ref.current.target.isStickyNoteMode;
  }, [ref.current.target.isStickyNoteMode]);

  React.useEffect(() => {
    const { fabric } = window;
    const paddingX = 10;
    const paddingY = 10;

    // shortcut key bindings
    disposerCleaner(
      (() => {
        const combokeys = new Combokeys(window);
        // const combokeys = new Combokeys(canvas.htmlEle);
        combokeys.bind('esc', (evt) => {
          if (canvas.isStickyNoteMode) {
            evt.stopPropagation();
            evt.preventDefault();
            ref.current.target.set({
              isStickyNoteMode: false,
              isToolbarActive: false,
              left: 0,
              top: 0,
              width: 0,
              height: 0,
              opacity: 0,
            });
            canvas.isStickyNoteMode = false;
            canvas && canvas.updateObject(ref.current.target);
            canvas.fire('mode:changed', {
              mode: 'StickyNote',
              value: !!canvas.isStickyNoteMode,
              source: 'StickyNote',
            });
            forceUpdate();
            return false;
          }
          return false;
        });
        return () => {
          combokeys.detach();
        };
      })()
    );

    disposerCleaner(
      canvas.$on('object:modified', ({ target }) => {
        const { type } = target;

        if (_.get(target, 'isToolbar')) {
          if (type === 'StickyNote') {
            forceUpdate();
          }
        }
      })
    );

    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        if (isActivateMode(event, 'StickyNote')) {
          // activate mode
          canvas.isStickyNoteMode = true;
          ref.current.target.set({
            isStickyNoteMode: true,
            isToolbarActive: true,
          });
          canvas.requestRenderAll();
          forceUpdate();
        } else if (isDeactivateMode(event, 'StickyNote')) {
          // deactivate mode
          canvas.isStickyNoteMode = false;
          ref.current.target.set({
            isStickyNoteMode: false,
            isToolbarActive: false,
          });
          ref.current.target.set({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            opacity: 0,
          });
          canvas.requestRenderAll();
          forceUpdate();
        }
      })
    );

    disposerCleaner(
      canvas.$on('mouse:up', (evt) => {
        if (canvas.isStickyNoteMode) {
          const left = _.get(evt, 'absolutePointer.x');
          const top = _.get(evt, 'absolutePointer.y');
          const stickyNote = new fabric.StickyNote({
            text: '',
            owner_id: canvas.getOwnerId(),
            left,
            top,
            radius: 4,
            colorType: ref.current.target.colorType,
            strokeWidth: ref.current.target.strokeWidth,
            charSpacing: ref.current.target.charSpacing,
            fontSize: ref.current.target.fontSize,
            paddingX,
            paddingY,
            width: 240,
            height: 200,
          });
          canvas.add(stickyNote);
          setTimeout(() => {
            canvas.isStickyNoteMode = false;
          }, 13);
          canvas.fire('mode:changed', { mode: 'StickyNote', source: 'StickyNote', value: false });
          canvas.defaultCursor = 'default';
          ref.current.target.set({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            opacity: 0,
          });
          stickyNote.enterEditing();
          ref.current.target.sendToBack();
        }
      })
    );
    disposerCleaner(
      canvas.$on('mouse:move', (evt) => {
        if (canvas.isStickyNoteMode) {
          const left = _.get(evt, 'absolutePointer.x');
          const top = _.get(evt, 'absolutePointer.y');

          ref.current.target.set({
            left,
            top,
            width: 240,
            height: 200,
            opacity: 0.3,
          });
          ref.current.target.setCoords();
          ref.current.target.bringToFront();
          canvas.requestRenderAll();
        }
      })
    );
  }, []);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  return (
    <MenuItem
      type={canvas.isStickyNoteMode ? 'primary' : 'text'}
      getPopupContainer={(node) => node && node.parentElement}
      visible={canvas.isStickyNoteMode}
      trigger={['click']}
      onClick={() => {
        canvas.isStickyNoteMode = !canvas.isStickyNoteMode;
        canvas.fire('mode:changed', {
          mode: 'StickyNote',
          source: 'StickyNote',
          value: !!canvas.isStickyNoteMode,
        });
      }}
      overlay={() => {
        return (
          <div className="flex flex-row bg-white w-full">
            <div>
              <ColorInput
                target={ref.current.target}
                canvas={canvas}
                open
                dropdownAlign={{
                  offset: [-17, -62],
                }}
              />
            </div>
          </div>
        );
      }}
      buttonsRender={([leftButton, rightButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.stickyNoteTooltip')}</span>
              <span className="">S</span>
            </div>
          }
          key="leftButton"
        >
          {leftButton}
        </Tooltip>,
        rightButton,
      ]}
      icon={gstyles.icons({ name: 'arrow-down', size: 16, fill: gstyles.colors.white500 })}
      placement="topLeft"
    >
      <span>{gstyles.icons({ name: 'sticky-note', size: 20, fill: gstyles.colors.white500 })}</span>
    </MenuItem>
  );
};

export default displayName(Index);
