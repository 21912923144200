const _ = require('lodash');
const Gun = require('gun');
require('gun/lib/load');

// extends gun

Gun.chain.putEvent = function(data, ...rest) {
  const gun = this;
  return gun.put(
    {
      ...data,
      ts: Date.now(),
    },
    ...rest
  );
};

Gun.chain.onEvent = function(callback, option) {
  const gun = this;
  const nowTs = Date.now();
  return gun.on((item) => {
    const ts = _.get(item, 'ts');
    if (ts >= nowTs) {
      callback(item);
    }
  }, option);
};

exports.getClient = _.memoize(() => {
  const getId = () => {
    let id;
    if (typeof window !== 'undefined') {
      const urlObject = new URL(window.location.href);
      id = urlObject.searchParams.get('id');
    }
    return id;
  };

  const rtn = {
    get: _.memoize(() => {
      const id = getId();
      const qs = id ? `?sid=${id}` : '';
      // const qs = '';
      const prefix = `gun-${id || '/'}`;
      const res = Gun({
        // peers: [`http://localhost:8765/gun${qs}`],
        // peers: [`http://localhost:8080/gun${qs}`],
        peers: [`https://run-gundb-gy7rbds4sa-uc.a.run.app/gun${qs}`],
        // peers: [`https://unitz-gun.herokuapp.com/gun${qs}`],
        // peers: [`https://bgundb.herokuapp.com/gun${qs}`],
        file: prefix,
        axe: false,
      });
      return res;
    }),
    getInstance: () => {
      return rtn.get();
    },
    getDoc: (name) => {
      return rtn.getInstance().get(name);
    },
    getDateTree: _.memoize((name) => {
      const gun = rtn.get();
      const treeRoot = gun.get(name);
      return treeRoot;
    }),
    getEventStream: _.memoize((name) => {
      const gun = rtn.get();
      const node = gun.get(name);
      return node;
    }),
  };
  return rtn;
});
