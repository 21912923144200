import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import UploadList from './UploadList';

const UploadContainer = () => {
  const ref = React.useRef({});
  const [fileList, $fileList] = React.useState([]);
  _.assign(ref.current, { fileList, $fileList });

  const uploadContainerRef = React.useMemo(() => {
    const uploadContainerRef = {
      add: (file) => {
        const { fileList, $fileList } = ref.current;
        $fileList([...fileList, file]);
      },

      remove: (file) => {
        const { fileList, $fileList } = ref.current;
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        $fileList(newFileList);
      },

      download: (file) => {
        console.log('file', file);
      },
    };
    return uploadContainerRef;
  }, []);
  return (
    <DIV>
      {ctx.debug(() => {
        ctx.apply('REF.setRef', 'uploadContainerRef', uploadContainerRef);
      })}
      <div
        id="input-uploader"
        className="bg-white absolute top-0 left-0 right-0 transform -translate-y-full border-black border-t"
      >
        {!!_.get(fileList, 'length') && (
          <div className="w-full p-2 ">
            <UploadList
              items={fileList}
              // listType={listType}
              // previewFile={previewFile}
              // onPreview={onPreview}
              onDownload={uploadContainerRef.onDownload}
              onRemove={uploadContainerRef.remove}
              // showPreviewIcon={false}
              // showDownloadIcon={false}
              // removeIcon={removeIcon}
              // downloadIcon={downloadIcon}
              // iconRender={() => null}
              // isImageUrl={isImageUrl}
              // progress={progress}
              // appendAction={button}
              // itemRender={itemRender}
            />
          </div>
        )}
      </div>
    </DIV>
  );
};

export default UploadContainer;
