import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import ActionButton from '@uz/unitz-components-web/RoomWebComponents/StyledActionButton';
import { Menu, Dropdown } from 'antd';

const View8 = () => {
  return (
    <DIV>
      <Dropdown
        overlay={
          <Menu style={{ borderRadius: '8px', textAlign: 'right' }} theme="dark">
            <Menu.Item
              className="flex space-x-2 items-center"
              onClick={() => {
                ctx.apply('videoCallModel.pinStream', ctx.get('@item.stream_id'));
              }}
            >
              {gstyles.icons({
                name: _.includes(ctx.apply('videoCallModel.useState', 'tracks.pins'), ctx.get('@item.stream_id'))
                  ? 'unpin'
                  : 'pin',
                fill: gstyles.colors.white500,
                size: 14,
              })}
              <span>
                {_.includes(ctx.apply('videoCallModel.useState', 'tracks.pins'), ctx.get('@item.stream_id'))
                  ? ctx.apply('i18n.t', 'AdvisorCourse.streamActions.unpin')
                  : ctx.apply('i18n.t', 'AdvisorCourse.streamActions.pin')}
              </span>
            </Menu.Item>
            <Menu.Item className="flex space-x-2 items-center">
              {gstyles.icons({
                name: 'record-2',
                fill: gstyles.colors.white500,
                size: 14,
              })}
              <span>{ctx.apply('i18n.t', 'AdvisorCourse.streamActions.record')}</span>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger="click"
        type="text"
        loading={false}
      >
        <ActionButton className="bg-black300 flex justify-center items-center" name="overlay" type="primary" loading={false}>
          {gstyles.icons({
            name: 'more',
            fill: gstyles.colors.white500,
            size: 14,
          })}
        </ActionButton>
      </Dropdown>
    </DIV>
  );
};

export default displayName(View8);
