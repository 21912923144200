import _ from 'lodash';

export const LoadingContent = ({ loading, children }) => {
  const isLoading = _.isFunction(loading) ? loading() : loading;
  if (isLoading) {
    return null;
  }
  const rtn = _.isFunction(children) ? children() : children;
  return rtn;
};

export default LoadingContent;
