import StickyNote from '../Classes/StickyNote';
import Picture from '../Classes/Picture';
import ToolbarText from '../Classes/ToolbarText';
import IText from '../Classes/IText';
import Cursor from '../Classes/Cursor';
import Eraser from '../Classes/Eraser';
import Marker from '../Classes/Marker';
import ObjectClass from '../Classes/Object';
import Canvas from '../Classes/Canvas';
import StaticCanvas from '../Classes/StaticCanvas';

const configFabric = (fabric) => {
  if (!fabric.didConfigFabric) {
    // Text toolbar
    ObjectClass(fabric);
    IText(fabric);
    Marker(fabric);
    Cursor(fabric);
    Eraser(fabric);
    ToolbarText(fabric);
    StickyNote(fabric);
    Picture(fabric);
    Canvas(fabric);
    StaticCanvas(fabric);
    // controls

    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#2eb2aa',
      cornerColor: '#ff0000',
      cornerSize: 8,
    });

    fabric.didConfigFabric = true;
  }
};

export default configFabric;
