const waitMs = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

const ref = {};
ref.waitUntil = async (fn, test, delay) => {
  let rtn = await fn();
  const isValid = await test(rtn);
  if (!isValid) {
    await waitMs(delay);
    rtn = await ref.waitUntil(fn, test, delay);
  }
  return rtn;
};

exports.waitUntil = ref.waitUntil;
