import React from 'react';
import _ from 'lodash';

import MessageStore from '@uz/unitz-tool-components/Message/Store';
import { Popover } from 'antd';
import { Button } from '@uz/unitz-components-web/Button';

import 'emoji-mart/css/emoji-mart.css';
import { Emoji } from 'emoji-mart';

export const MessageReaction = ({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const containerRef = React.useRef();
  if (!_.get(message, 'reactions.length')) return null;
  const reactions = _.get(message, 'reactions');
  const reactionsByCode = _.groupBy(reactions, 'code_decimal');
  return (
    <div className="flex" ref={containerRef}>
      {_.map(reactionsByCode, (items, code_decimal) => {
        if (!code_decimal) return null;
        return (
          <Popover
            content={
              <div>
                {_.map(items, ({ id }) => (
                  <div key={id}>{id}</div>
                ))}
              </div>
            }
            trigger="hover"
            key={`${code_decimal}`}
            overlayClassName="bg-white500"
            getPopupContainer={() => containerRef.current}
          >
            <Button type="outline" size="small" className="p-1 mr-1 flex align-center items-center">
              <Emoji size={20} emoji={code_decimal} />
              <span className="px-1 text-gray-400">{items.length}</span>
            </Button>
          </Popover>
        );
      })}
    </div>
  );
};

export default MessageReaction;
