import React from 'react';
import _ from 'lodash';
import { getDeviceInfo, isMobileAndTablet } from '@vl/mod-utils/deviceInfoWeb';
import { Menu } from 'antd';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

const VideoSingle = () => {
  const [videoInputDevices, $videoInputDevices] = React.useState([]);
  const [videoInputDeviceIndex, $videoInputDeviceIndex] = useLocalStorage('@UZ::videoInputDeviceIndex', 0);
  const [videoInputDeviceIndexList, $videoInputDeviceIndexList] = useLocalStorage('@UZ::videoInputDeviceIndexList', [
    0,
  ]);

  const videoInputDeviceKey = `input.${_.get(videoInputDevices, `${videoInputDeviceIndex}.deviceId`)}`;
  const selectHandlers = {
    input: (deviceId) => {
      const foundIndex = _.findIndex(videoInputDevices, { deviceId });
      if (foundIndex >= 0) {
        $videoInputDeviceIndex(foundIndex);
        $videoInputDeviceIndexList([foundIndex]);
      }
    },
  };

  const ref = React.useRef({});
  _.assign(ref.current, { videoInputDevices, $videoInputDevices });
  React.useEffect(() => {
    getDeviceInfo().then((res) => {
      if (_.size(ref.current.videoInputDevices) !== _.size(res.videoInputDevices)) {
        ref.current.$videoInputDevices(res.videoInputDevices);
      }
    });
  });

  return (
    <Menu
      style={{ width: 320, minHeight: 100 }}
      selectedKeys={[videoInputDeviceKey]}
      triggerSubMenuAction="click"
      onClick={({ key }) => {
        const [type, deviceId] = _.split(key, '.');
        if (selectHandlers[type]) {
          selectHandlers[type](deviceId);
        }
      }}
      theme="dark"
    >
      <Menu.ItemGroup key="g1" title="Select a Camera">
        {_.map(videoInputDevices, (item) => {
          return <Menu.Item key={`input.${item.deviceId}`}>{item.label}</Menu.Item>;
        })}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Divider />
      {/* <Menu.Item key="5">Video Settings ...</Menu.Item> */}
    </Menu>
  );
};

const VideoMultiple = () => {
  const [videoInputDevices, $videoInputDevices] = React.useState([]);
  const [videoInputDeviceIndexList, $videoInputDeviceIndexList] = useLocalStorage('@UZ::videoInputDeviceIndexList', [
    0,
  ]);

  const selectedKeys = _.map(
    videoInputDeviceIndexList,
    (videoInputDeviceIndex) => `input.${_.get(videoInputDevices, `${videoInputDeviceIndex}.deviceId`)}`
  );
  const selectHandlers = {
    input: (deviceId) => {
      const foundIndex = _.findIndex(videoInputDevices, { deviceId });
      if (foundIndex >= 0) {
        // multiple select toggle
        let videoInputDeviceIndexListNew = _.uniq(_.xor(videoInputDeviceIndexList, [foundIndex]));
        if (!videoInputDeviceIndexListNew.length) videoInputDeviceIndexListNew.push(foundIndex);
        $videoInputDeviceIndexList(videoInputDeviceIndexListNew);
      }
    },
  };

  const ref = React.useRef({});
  _.assign(ref.current, { videoInputDevices, $videoInputDevices });
  React.useEffect(() => {
    console.log('getDeviceInfo VideoMultiple');
    getDeviceInfo().then((res) => {
      if (_.size(ref.current.videoInputDevices) !== _.size(res.videoInputDevices)) {
        ref.current.$videoInputDevices(res.videoInputDevices);
      }
    });
  });

  return (
    <Menu
      style={{ width: 320, minHeight: 100 }}
      selectedKeys={selectedKeys}
      triggerSubMenuAction="click"
      onClick={({ key }) => {
        const [type, deviceId] = _.split(key, '.');
        if (selectHandlers[type]) {
          selectHandlers[type](deviceId);
        }
      }}
      theme="dark"
    >
      <Menu.ItemGroup key="g1" title="Select a Camera">
        {_.map(videoInputDevices, (item) => {
          return <Menu.Item key={`input.${item.deviceId}`}>{item.label}</Menu.Item>;
        })}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Divider />
      {/* <Menu.Item key="5">Video Settings ...</Menu.Item> */}
    </Menu>
  );
};

const supportMultipleVideo = false;

export const VideoSettingsMenu = () => {
  if (isMobileAndTablet() || !supportMultipleVideo) return <VideoSingle />;
  return <VideoMultiple />;
};

export default VideoSettingsMenu;
