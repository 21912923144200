import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import LayoutRoomCall from '@uz/unitz-layout-web/LayoutRoomCall';

import RoomTool from '@uz/unitz-tool-components/RoomTool';
import RoomPreview from '@uz/unitz-tool-components/RoomPreview';
import PreviewRoomCallActions from '@uz/unitz-tool-components/RoomWebComponents/PreviewRoomCallActions';
import CollabAttendRoom from '@uz/unitz-tool-components/Collab/Components/AttendRoom';

import UserRoomCallActions from '@uz/unitz-tool-components/RoomWebComponents/UserRoomCallActions';
import { ctx } from '@vl/redata';
import RoomMessage from '@uz/unitz-tool-pages/RoomMessage';

const Index = () => {
  return (
    <DIV className="videoPage">
      <DIV className="serviceStep">
        <LayoutRoomCall>
          <div ref={ctx.apply('LAYOUT.onLayout', 'AdvisorVideoCallStatus')} className="relative flex-1 w-full h-full" />
          {ctx.get('@params.preview') ? (
            <>
              <LayoutRoomCall.POS name="content-body-floating">
                <RoomPreview />
              </LayoutRoomCall.POS>
              <LayoutRoomCall.POS name="content-footer">
                <PreviewRoomCallActions />
              </LayoutRoomCall.POS>
              <CollabAttendRoom />
            </>
          ) : (
            <>
              <LayoutRoomCall.POS name="content-body-floating">
                <RoomTool />
              </LayoutRoomCall.POS>
              <LayoutRoomCall.POS name="content-footer">
                <UserRoomCallActions />
              </LayoutRoomCall.POS>
              <CollabAttendRoom />
            </>
          )}
          <LayoutRoomCall.POS name="content-start">
            <RoomMessage />
          </LayoutRoomCall.POS>
        </LayoutRoomCall>
      </DIV>
    </DIV>
  );
};

export default displayName(Index);
