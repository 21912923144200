import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetB2BCourseRoom from 'unitz-gql/b2b_course_room/GetB2BCourseRoom.gql';

graphme.GqlBuilder.loadDocument({
  GetB2BCourseRoom,
});

class B2BCourseRoomModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'B2BCourseRoom',
    schema: {
      id: String,
      is_active: Boolean,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetB2BCourseRoom',

    GQL_ACTIONS: {
      FIND: 'b2b_course_room',
      GET: 'b2b_course_room_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ B2BCourseRoomModel });

export default B2BCourseRoomModel;
