import React from 'react';
import _ from 'lodash';

import configFabric from './fabric';

const config = (fabric, canvas, options) => {
  canvas.genObjectId = options.genObjectId;
  canvas.getOwnerId = options.getOwnerId;
  canvas.targetId = options.targetId;

  canvas.updateObject = (target) => {
    target && target.fire('modified');
    canvas.requestRenderAll();
    canvas.fire('object:modified', { target });
  };

  canvas.$on = (...args) => {
    canvas.on(...args);
    return () => {
      const [event, handler] = args;
      if (_.isString(event)) {
        canvas.off(event, handler);
      }
    };
  };
  configFabric(fabric);

  const configViewPort = () => {
    try {
      let viewportTransform = window.localStorage.getItem(_.camelCase(`canvas_${canvas.targetId}_transform`)) || '[]';
      viewportTransform = JSON.parse(viewportTransform) || [];
      const [scaleX, skewX, skewY, scaleY, left = 0, top = 0] = viewportTransform;
      let delta = new fabric.Point(0 - left, 0 - top);
      canvas.absolutePan(delta);
    } catch (err) {
      console.log('configViewPort error');
    }
  };

  configViewPort();
};

export default config;
