import React from 'react';
import { Helmet } from 'react-helmet';
import { ResizableBox } from 'react-resizable';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import useWindowSize from '@vl/hooks/useWindowSize';
import _ from 'lodash';
import cx from 'classnames';
import StreamLayout from '@uz/unitz-layout-web/LayoutRoomStream';
import styled from 'styled-components';
import { useDrag } from '@use-gesture/react';

import createLayout from '@vl/mod-utils/createLayout';
import { Row, Col } from 'antd';
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

const isSwipeLeft = _.debounce((state, cb) => {
  if (_.get(state, 'movement.0') <= -100) {
    cb && cb(state);
  }
}, 200);

const isSwipeRight = _.debounce((state, cb) => {
  if (_.get(state, 'movement.0') >= 100) {
    cb && cb(state);
  }
}, 200);

const ContentStartRender = ({ ele }) => {
  const windowSize = useWindowSize();
  const minWidth = 420;
  const [layoutState, $layoutState] = useLocalStorage('@UZ::LayoutRoomCall::ContentStart', {
    width: minWidth,
    visible: false,
  });
  const ref = React.useRef({});
  let [initWidth, $initWidth] = React.useState(layoutState.width || minWidth);
  initWidth = Math.min(windowSize.width, initWidth);
  const maxWidth = windowSize.width;
  _.assign(ref.current, { layoutState, $layoutState, initWidth, $initWidth });

  const bind = useDrag((state) => {
    isSwipeLeft(state, () => {
      // ref.current.$layoutState && ref.current.$layoutState({ ...ref.current.layoutState, visible: false });
    });
    isSwipeRight(state, () => {
      // const newWidth = windowSize.width;
      // ref.current.$layoutState && ref.current.$layoutState({ ...ref.current.layoutState, width: newWidth });
      // ref.current.$initWidth && ref.current.$initWidth(newWidth);
    });
  });
  const onResize = React.useMemo(() => {
    const dataRef = {};
    const delayUpdate = _.debounce(() => {
      ref.current.$layoutState &&
        ref.current.$layoutState({ ...ref.current.layoutState, width: dataRef.data.size.width });
    }, 400);
    return (evt, data) => {
      dataRef.data = data;
      return delayUpdate(evt, data);
    };
  }, []);
  if (!ele) return null;
  const width = _.get(layoutState, 'visible') ? _.get(layoutState, 'width', initWidth) : 0;
  const layoutStateWidth = parseFloat(_.get(ref.current, 'layoutState.width')) || minWidth;
  return (
    <ResizableBox
      width={width}
      height={_.get(windowSize, 'height', 800)}
      draggableOpts={{}}
      minConstraints={[minWidth]}
      maxConstraints={[maxWidth]}
      axis="x"
      resizeHandles={['e']}
      onResize={onResize}
      className={cx('content-start-wrapper z-30', {
        'absolute top-0 bottom-20 left-0': windowSize.width - layoutStateWidth < minWidth,
        'overflow-hidden': !_.get(layoutState, 'visible'),
      })}
    >
      <div {...bind()} className="w-full h-full relative" style={{ touchAction: 'none' }}>
        {ele}
      </div>
    </ResizableBox>
  );
};

const RoomSpaceStyled = styled.div`
  ${() => {
    // const spaceY = '1.4em';
    const spaceY = '2em';
    const gridColor = '#abced4';
    // const gridColor2 = '#eee';
    const gridColor2 = '#3e3e3e';
    const lineHeight = 79;
    const lineBorderWidth = 1;
    const lineBorderWidth2 = '0.05em';
    return `
    background-size: 100% ${spaceY};
    background-image: -webkit-linear-gradient(0deg, transparent ${lineHeight}px, ${gridColor} ${lineHeight}px, ${gridColor} ${lineHeight +
      lineBorderWidth}px, transparent ${lineHeight + lineBorderWidth}px),
      -webkit-linear-gradient(${gridColor2} ${lineBorderWidth2}, transparent ${lineBorderWidth2});
    background-image: -moz-linear-gradient(0deg, transparent ${lineHeight}px, ${gridColor} ${lineHeight}px, ${gridColor} ${lineHeight +
      lineBorderWidth}px, transparent ${lineHeight + lineBorderWidth}px),
      -moz-linear-gradient(${gridColor2} ${lineBorderWidth2}, transparent ${lineBorderWidth2});
    background-image: -ms-linear-gradient(0deg, transparent ${lineHeight}px, ${gridColor} ${lineHeight}px, ${gridColor} ${lineHeight +
      lineBorderWidth}px, transparent ${lineHeight + lineBorderWidth}px),
      -ms-linear-gradient(${gridColor2} ${lineBorderWidth2}, transparent ${lineBorderWidth2});
    background-image: -o-linear-gradient(0deg, transparent ${lineHeight}px, ${gridColor} ${lineHeight}px, ${gridColor} ${lineHeight +
      lineBorderWidth}px, transparent ${lineHeight + lineBorderWidth}px),
      -o-linear-gradient(${gridColor2} ${lineBorderWidth2}, transparent ${lineBorderWidth2});
    background-image: linear-gradient(0deg, transparent ${lineHeight}px, ${gridColor} ${lineHeight}px, ${gridColor} ${lineHeight +
      lineBorderWidth}px, transparent ${lineHeight + lineBorderWidth}px),
      linear-gradient(${gridColor2} ${lineBorderWidth2}, transparent ${lineBorderWidth2});
    -pie-background: linear-gradient(0deg, transparent ${lineHeight}px, ${gridColor} ${lineHeight}px, ${gridColor} ${lineHeight +
      lineBorderWidth}px, transparent ${lineHeight + lineBorderWidth}px) 0 0 / 100%
        1.2em,
      linear-gradient(${gridColor2} ${lineBorderWidth2}, transparent ${lineBorderWidth2}) 0 0 / 100% 1.2em #fff;
      `;
  }}
`;

export const Layout = createLayout(({ children }) => {
  return (
    <div className="fixed top-0 left-0 z-10 w-screen h-screen h-fill-screen bg-main flex">
      <Helmet>
        <script src="https://embed.videodelivery.net/embed/sdk.latest.js"></script>

        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/glightbox/dist/css/glightbox.min.css" />
      </Helmet>

      <div id="layoutcontent">{children}</div>
      <Layout.RenderPOS name="content-start">{(ele) => <ContentStartRender ele={ele} />}</Layout.RenderPOS>
      <div className="w-full h-full relative flex-1 flex flex-col" id="room-call-content-wrapper">
        <div
          className="content-header py-4 w-full bg-transparent absolute z-20 pointer-events-none top-0 left-0"
          id="room-call-content-header"
        >
          <Layout.RenderPOS name="content-header">
            <Row gutter={{ md: 24 }} className="h-full px-4">
              <Col className="h-18 w-full relative overflow-hidden" span={24}>
                <div className="w-full h-full bg-transparent flex md:flex-row justify-between overflow-hidden flex-col space-y-1">
                  <Layout.RenderPOS name="content-header-title">
                    {(ele) => (
                      <div className="content-header-title bg-black-400 bg-opacity-70 pointer-events-auto">{ele}</div>
                    )}
                  </Layout.RenderPOS>

                  {/* <Layout.RenderPOS name="sharing-screen"></Layout.RenderPOS> */}

                  <Layout.RenderPOS name="content-header-subtitle">
                    {(ele) => ele && <div className="content-header-subtitle pointer-events-auto">{ele}</div>}
                  </Layout.RenderPOS>
                </div>
              </Col>
              <Col className="w-full relative overflow-hidden" span={24}>
                <Layout.RenderPOS name="content-header-action">
                  {(ele) => {
                    if (!ele) return null;
                    return <div className="w-full h-full py-2 absolute inset-0 px-2">{ele}</div>;
                  }}
                </Layout.RenderPOS>
              </Col>
            </Row>
          </Layout.RenderPOS>
        </div>
        <RoomSpaceStyled
          className="content-body bg-transparent flex-1 w-full bg-black-400 relative overflow-hidden"
          id="room-call-content-body"
        >
          <StreamLayout />
          <Layout.RenderPOS name="content-body-floating" />
          <Layout.RenderPOS name="content-body" />
        </RoomSpaceStyled>
        <div className="content-footer w-full h-20 bg-black300 bg-opacity-70" id="room-call-content-footer">
          <Layout.RenderPOS name="content-footer" />
        </div>
      </div>
      <Layout.RenderPOS name="content-end" />
    </div>
  );
});

export default Layout;
