import React from 'react';
import { bindings, hook } from '@vl/redata';

import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';
import B2BCourseRoomModel from '@uz/unitz-models/B2BCourseRoomModel';
import CourseRoomModelFormatter from '@uz/unitz-models/CourseRoomModel/formatter';

const STEP_LIST = ['service'];

const STEP_LIST_BY_NAME = {};
_.map(STEP_LIST, (val, index) => (STEP_LIST_BY_NAME[val] = index));

const bindData = bindings({
  serviceSessionPage: {
    rules: [
      [
        'data',
        {
          data: {
            courseRoom: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const room_id = _.get(routeParams, 'id');
              const items = useObservableSource(() =>
                B2BCourseRoomModel.find(
                  `where: {id: {_eq: "${room_id}"}}`,
                  `
                  id
                  status
                  start_at
                  end_at
                  materials
                  course {
                    id
                    slug
                    materials
                    passed_rooms: course_rooms(
                      limit: 60,
                      order_by: { end_at: desc_nulls_last}
                      where: {${CourseRoomModelFormatter.getPassedRoomCondition()()}}
                    ) {
                      id
                      status
                      start_at
                      end_at
                    }
                    upcomming_rooms: course_rooms(
                      limit: 30,
                      order_by: { end_at: asc_nulls_last}
                      where: {${CourseRoomModelFormatter.getUpcomeRoomCondition()()}}
                    ) {
                      id
                      status
                      start_at
                      end_at
                    }
                  }
                  `
                )
              );
              return items;
            }),
            courseRoomData: hook((ctx) => {
              const courseRooms = useSubjectSource(ctx.get('courseRoom'));
              const courseRoom = _.get(courseRooms, '0');
              return {
                ...courseRoom,
              };
            }),
            roomControlModel: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const room_id = _.get(routeParams, 'id');

              const model = {
                room_id,
                can: {
                  recordStream: () => false,
                },
              };
              ctx.apply('REF.setRef', 'roomControlModelRef', model);
              return model;
            }),

            stepModel: hook((ctx) => {
              // const route = useRoute();
              // const routeParams = route.getParams();
              // const step = _.get(routeParams, 'step', _.first(STEP_LIST));
              const [currentStep, $currentStep] = React.useState('service');
              // React.useEffect(() => {
              //   if (currentStep !== step) {
              //     $currentStep(step);
              //   }
              // }, [currentStep, $currentStep, step]);
              return {
                currentStep,
                setStep: (newStep) => _.has(STEP_LIST_BY_NAME, newStep) && $currentStep(newStep),
                hasNext: () => {},
                nextStep: () => {},
                prevStep: () => {},
              };
            }),
          },
        },
      ],
    ],
  },
  serviceStep: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return true;
          }),
        },
      ],
    ],
  },
  completedStep: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => false),
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingRoomModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingRoomModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
