import _ from 'lodash';

export const getTravelStoreMappingEvents = (canvas) => {
  const getCanvas = () => {
    return _.isFunction(canvas) ? canvas() : canvas;
  };

  return {
    mappings: {
      undo: {
        'object:added': async (evt) => {
          const canvas = getCanvas();
          const { payload } = evt;
          canvas && canvas.removeItem(JSON.parse(payload));
        },
        'object:removed': async (evt) => {
          const canvas = getCanvas();
          const { payload } = evt;
          canvas && canvas.addItem(JSON.parse(payload));
        },
        'object:modified': async (evt) => {
          const canvas = getCanvas();
          const { payload, originalState } = evt;
          const item = { ...JSON.parse(payload), ...originalState };
          if (canvas) {
            canvas && canvas.updateItem(item);
            const { fabric } = window;
            fabric.util.enlivenObjects([item], ([target]) => {
              canvas.fire('object:modified', { target });
            });
          }
        },
      },
      redo: {
        'object:added': async (evt) => {
          const canvas = getCanvas();
          const { payload } = evt;
          canvas && canvas.addItem(JSON.parse(payload));
        },
        'object:removed': async (evt) => {
          const canvas = getCanvas();
          const { payload } = evt;
          canvas && canvas.removeItem(JSON.parse(payload));
        },
        'object:modified': async (evt) => {
          const canvas = getCanvas();
          const { payload } = evt;
          const item = { ...JSON.parse(payload) };
          if (canvas) {
            canvas && canvas.updateItem(item);
            const { fabric } = window;
            fabric.util.enlivenObjects([item], ([target]) => {
              canvas.fire('object:modified', { target });
            });
          }
        },
      },
    },
  };
};
