import moment from 'moment';
import _ from 'lodash';
import currencyFormatter, {
  durationFormatter,
  formatVND,
  normalizeNumber,
  parseMilliseconds,
} from '@vl/mod-utils/currencyFormatter';
import { dateDiff, normalizeDate, formatFullDateDisplay, formatDateDisplay } from '@vl/mod-utils/dateFormatter';
import i18n from 'i18n-js';
const DAY_MAP = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const formatter = {
  createdAtTxt: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'profile.created_at', '');
    if (!createdAt) return '';
    return ctx.apply('i18n.t', 'Advisor.joinedSince', { dateStr: moment(createdAt).format('Do MMM, YYYY') });
  },
  passedDate: (ctx) => (itemData) => {
    return !!_.get(itemData, 'profile.passed_date')
      ? formatFullDateDisplay(_.get(itemData, 'profile.passed_date'))
      : '';
  },
  createdAtWithTime: (ctx) => (itemData) => {
    return formatFullDateDisplay(_.get(itemData, 'profile.created_at') || '');
  },

  displayName: () => (itemData) => {
    return _.get(itemData, 'profile.display_name', '--');
  },

  statusAccount: () => (itemData) => {
    const statusData = {
      isActive: _.get(itemData, 'is_active'),
      isInActive: !_.get(itemData, 'is_active'),
    };
    return statusData;
  },

  email: () => (itemData) => {
    return _.get(itemData, 'profile.email', '--');
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);
    return _.get(itemData, 'profile.avatar_url', `https://ui-avatars.com/api/?name=${displayName}`);
  },

  iconService: (ctx) => (itemData) => {
    const kind = _.get(itemData, 'kind');
    return `${kind}Advisor`;
  },
  typeService: (ctx) => (itemData) => {
    const name = _.get(itemData, 'kind');
    return name || '';
  },
  timeAvgService: (ctx) => (itemData) => {
    return 1000;
  },
  statusService: (ctx) => (itemData) => {
    const is_active = _.get(itemData, 'is_active');
    return is_active || false;
  },
  priceService: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount', 0);
    if (!price_amount) return 0;
    const currency = _.get(itemData, 'price.price_currency', 0);

    return `${formatVND(price_amount, currency)}`;
  },

  servicesByKind: (ctx) => (itemData) => {
    const groupServices = _.toArray(_.keyBy(itemData, 'service.kind'));

    return groupServices;
  },
  dayTitle: (ctx) => (itemData) => {
    return ctx.apply('i18n.t', `HomeAdvisor.date.${DAY_MAP[itemData]}`);
  },
  avgTime: (ctx) => (itemData) => {
    // const total = _.sumBy(itemData, 'detail.per_amount');
    const total = _.sumBy(itemData, (item) =>
      _.ceil(_.get(item, 'transaction.session_detail.duration_amount', 0) / 60)
    );

    return total;
  },
  avgTimeTransaction: (ctx) => (itemData) => {
    const total = _.sumBy(itemData, (item) => _.ceil(_.get(item, 'session_detail.duration_amount', 0) / 60));
    return total;
  },
  avgAmount: (ctx) => (itemData) => {
    const total = _.sumBy(itemData, 'advisor_income.0.amount', 0);
    if (!total) return 0;

    return total;
  },
  perMount: (ctx) => (itemData) => {
    if (!itemData || !_.isArray(itemData)) return 0;
    const len = itemData.length;
    const total = _.sumBy(itemData, 'advisor_income.0.amount', 0);
    if (!total) return 0;

    return ctx.apply('i18n.t', 'AdvisorInCome.perMount', { num: currencyFormatter(ctx)(total / len) });
  },

  perTime: (ctx) => (itemData) => {
    if (!itemData || !_.isArray(itemData)) return 0;
    const len = itemData.length;
    const total = _.sumBy(itemData, 'session_detail.duration_amount', 0);
    if (!total) return 0;

    return ctx.apply('i18n.t', 'AdvisorInCome.perTime', { num: Math.round(total / len / 60) });
  },
  avgAdvisor: (ctx) => (itemData) => {
    const total = _.toArray(itemData).length;

    return total;
  },
  nameService: (ctx) => (itemData) => {
    const total = _.get(itemData, '0.services.0.price.per_amount');

    if (!total) return '';

    return ctx.apply('i18n.t', 'HomeAdvisor.namePackage', {
      time: total / 60,
    });
  },

  callPackage: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'service_pricing.detail.per_amount');

    if (!per_amount) return '';

    return `${per_amount / 60} ${ctx.apply('i18n.t', 'time.minute')}`;
  },

  callPackageTime: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'session_detail.duration_amount', 0);
    const per_unit = _.get(itemData, 'session_detail.duration_unit', 'millisecond');
    if (!per_amount) return ctx.apply('videoCallModel.getCallDurationTotal', '');
    return durationFormatter(per_amount * 1000);
  },

  callPackageTimeMinute: (ctx) => (itemData) => {
    let per_amount = _.get(itemData, 'session_detail.duration_amount', 0);
    const per_unit = _.get(itemData, 'session_detail.duration_unit', 'millisecond');
    if (per_unit === 'second') per_amount *= 1000;
    return _.get(parseMilliseconds(per_amount), 'minutes', 0);
  },

  callPackageVoiceTime: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'session_detail.duration_amount', 0);
    const per_unit = _.get(itemData, 'session_detail.duration_unit', 'millisecond');
    if (!per_amount) return ctx.apply('voiceCallModel.getCallDurationTotal', '');
    return durationFormatter(per_amount * 1000);
  },

  callPackagePrice: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'service_pricing.detail.per_amount');
    const price_amount = _.get(itemData, 'service_pricing.detail.price_amount');
    const price_currency = _.get(itemData, 'service_pricing.detail.price_currency');

    if (!price_amount || !price_currency || !per_amount) return '';

    return `${formatVND((price_amount / per_amount) * 60, price_currency)}/${ctx.apply('i18n.t', 'time.minute')}`;
  },
  callPackageTotal: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'service_pricing.detail.price_amount');
    const price_currency = _.get(itemData, 'service_pricing.detail.price_currency');
    return formatVND(price_amount, price_currency);
  },

  avatarProfile: (ctx) => (currentUser) => {
    const displayName = _.get(currentUser, 'display_name', '');
    const photoURL =
      _.get(currentUser, 'avatar_url', `https://ui-avatars.com/api/?name=${displayName}`) ||
      `https://ui-avatars.com/api/?name=${displayName}`;

    return photoURL;
  },

  advisorServiceKindTxt: (ctx) => (itemData) => {
    const kind = _.get(itemData, 'kind', '');
    if (!kind) return '';
    return ctx.apply('i18n.t', `HomeAdvisor.kindCall${_.capitalize(kind)}`);
  },

  displayNameProfile: (ctx) => (currentUser) => {
    const displayName = _.get(currentUser, 'display_name', '');
    if (!displayName) return ctx.apply('i18n.t', 'AdvisorProfile.noName');

    return displayName;
  },
  // TODO: HARD CODE
  ratingSuggestion: (ctx) => (star) => {
    const CONTENT_SUGGESTION = {
      title1: ctx.apply('i18n.t', 'RatingSuggestion.title1'),
      title2: ctx.apply('i18n.t', 'RatingSuggestion.title2'),
      title3: ctx.apply('i18n.t', 'RatingSuggestion.title3'),
      title4: ctx.apply('i18n.t', 'RatingSuggestion.title4'),
      title5: ctx.apply('i18n.t', 'RatingSuggestion.title5'),
      // bad
      noise: ctx.apply('i18n.t', 'RatingSuggestion.noise'),
      reverb: ctx.apply('i18n.t', 'RatingSuggestion.reverb'),
      lossOfSound: ctx.apply('i18n.t', 'RatingSuggestion.lossOfSound'),
      echo: ctx.apply('i18n.t', 'RatingSuggestion.echo'),
      distortion: ctx.apply('i18n.t', 'RatingSuggestion.distortion'),
      interrupt: ctx.apply('i18n.t', 'RatingSuggestion.interrupt'),
      frozen: ctx.apply('i18n.t', 'RatingSuggestion.frozen'),
      blur: ctx.apply('i18n.t', 'RatingSuggestion.blur'),
      poorColor: ctx.apply('i18n.t', 'RatingSuggestion.poorColor'),
      underexposed: ctx.apply('i18n.t', 'RatingSuggestion.underexposed'),
      lag: ctx.apply('i18n.t', 'RatingSuggestion.lag'),
      notShowing: ctx.apply('i18n.t', 'RatingSuggestion.notShowing'),
    };
    const RATING_SUGGESTION = {
      1: {
        label: ctx.apply('i18n.t', 'RatingSuggestion.label1'),
        title: _.get(CONTENT_SUGGESTION, 'title1'),
      },
      2: {
        label: ctx.apply('i18n.t', 'RatingSuggestion.label2'),
        title: _.get(CONTENT_SUGGESTION, 'title2'),
      },
      3: {
        label: ctx.apply('i18n.t', 'RatingSuggestion.label3'),
        title: _.get(CONTENT_SUGGESTION, 'title3'),
      },
      4: {
        label: ctx.apply('i18n.t', 'RatingSuggestion.label4'),
        title: _.get(CONTENT_SUGGESTION, 'title4'),
      },
      5: {
        label: ctx.apply('i18n.t', 'RatingSuggestion.label5'),
        title: _.get(CONTENT_SUGGESTION, 'title5'),
      },
    };
    return _.get(RATING_SUGGESTION, star, {});
  },
  score: (ctx) => (itemData) => {
    const score = _.get(itemData, 'score', 0);
    if (!score) return 0;

    return normalizeNumber(score) / 2;
  },

  packageName: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'price.per_amount', 0);
    if (!per_amount) return '';
    const kind = _.get(itemData, 'service.kind', '');
    if (!kind) return '';
    return `${ctx.apply('i18n.t', `HomeAdvisor.kindCallNotification${_.capitalize(kind)}`)} - ${
      normalizeNumber(per_amount) / 60
    } ${ctx.apply('i18n.t', 'time.minute')}`;
  },

  advisorName: (ctx) => (itemData) => {
    const display_name = _.get(itemData, '0.profile.display_name', '--');
    if (!display_name) return '--';
    return display_name;
  },

  advisorAvatar: (ctx) => (itemData) => {
    const avatar_url = _.get(itemData, '0.profile.avatar_url', '');
    const display_name = _.get(itemData, '0.profile.display_name', '');
    if (!avatar_url) return `https://ui-avatars.com/api/?name=${display_name}`;
    return avatar_url;
  },

  groundByKind: (ctx) => (itemData) => {
    const services = _.groupBy(itemData, (item) => _.get(item, 'service_pricing.service.service.kind', 'course'));

    if (_.isEmpty(services)) return [];

    return _.keys(services).map((item) => ({
      type: item === 'course' ? 'course' : 'call',
      kind: item,
      totalBalance: formatVND(formatter.avgAmount(ctx)(services[item])),
      numOfAdvisor: services[item].length,
      perMount: formatter.perMount(ctx)(services[item]),
      perTime: formatter.perTime(ctx)(services[item]),
    }));
  },

  groundByHour: (ctx) => (itemData) => {
    const mapOfHour = _.map(itemData, (item) => ({ ...item, hour: moment(_.get(item, 'created_at')).hours() }));

    const services = _.groupBy(mapOfHour, 'hour');

    if (_.isEmpty(services)) return [];

    return _.keys(services).map((item) => ({
      hour: item,
      value: formatter.avgAmount(ctx)(services[item]),
      numOfAdvisor: services[item].length,
      perMount: formatter.perMount(ctx)(services[item]),
      perTime: formatter.perTime(ctx)(services[item]),
    }));
  },

  groundByDay: (ctx) => (itemData) => {
    const mapOfHour = _.map(itemData, (item) => ({ ...item, hour: moment(_.get(item, 'created_at')).day() }));

    const services = _.groupBy(mapOfHour, 'hour');

    if (_.isEmpty(services)) return [];

    return _.keys(services).map((item) => ({
      day: item,
      value: formatter.avgAmount(ctx)(services[item]),
      numOfAdvisor: services[item].length,
      perMount: formatter.perMount(ctx)(services[item]),
      perTime: formatter.perTime(ctx)(services[item]),
    }));
  },

  dateFilter: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'calendar', {});

    if (!arr) return [];
    const weeks = ctx.apply('i18n.t', 'Weeks');

    let weeksKey = Object.keys(weeks);

    const last = weeksKey.pop();

    weeksKey = [last, ...weeksKey];

    const now = new Date();

    const day = new Date(itemData).getDay();

    const diff = dateDiff(itemData, normalizeDate(now));

    let padText = '';
    if (diff === 0) {
      padText = `${ctx.apply('i18n.t', 'AdvisorInCome.chart.today')},`;
    } else if (diff === 1) {
      padText = `${ctx.apply('i18n.t', 'AdvisorInCome.chart.yesterday')},`;
    }

    const dayTxt = moment().locale(i18n.locale).day(day).format('dddd');

    return `${padText} ${_.capitalize(dayTxt)}, ${formatDateDisplay(itemData)}`;
  },

  weekFilter: (ctx) => (itemData) => {
    const startDate = _.get(itemData, 'startDate', '');
    const endDate = _.get(itemData, 'endDate', '');
    return ctx.apply('i18n.t', 'AdvisorInCome.fromDay', {
      start: moment(startDate).format('DD/MM'),
      end: formatDateDisplay(endDate),
    });
  },
  seenAtText: (ctx) => (review) => {
    const updated_at = _.get(review, 'updated_at');
    if (!updated_at) return '';
    return formatDateDisplay(updated_at);
  },
  assistants: (ctx) => (itemData) => {
    return _.get(itemData, 'assistants', []);
  },
  notes: (ctx) => (itemData) => {
    return _.get(itemData, 'notes', []);
  },
  buttons: (ctx) => (itemData) => {
    return JSON.stringify(_.get(itemData, 'buttons', []));
  },

  flattenServices: (ctx) => (itemData) => {
    const rtn = _.reduce(itemData, (arr, item) => {
      const newArr = _.merge(arr, item);
      return newArr;
    });
    return rtn;
  },
};

export default formatter;
