import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import VideoCallModel from '@uz/unitz-models/RoomAttendWebModel';
import VideoCallModelHandler from '@uz/unitz-models/RoomHostWebModel/handler';
import B2BCourseRoomModel from '@uz/unitz-models/B2BCourseRoomModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useRoute from '@vl/hooks/useGbRouteDe';
import moment from 'moment';
import { message } from 'antd';
import { TIMESTAMPTZ_FORMAT } from '@vl/mod-utils/datetime';
import useLoadingModel from '@vl/hooks/useLoadingModel';

const CONTROL_BAR_STATUS = ['hide', 'half', 'full'];

const bindData = bindings({
  videoPage: {
    rules: [
      [
        'data',
        {
          data: {
            loadingValidModel: hook(useLoadingModel(() => ({ fetchValidRoom: true }))),
            loadingRoomModel: hook(useLoadingModel(() => ({ fetchRoom: true }))),
            '@params': hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const advisor_id = ctx.apply('authModel.getUserId');
              const user_id = ctx.apply('authModel.getUserId');
              const preview = _.get(routeParams, 'preview');
              const redirect_code = _.get(routeParams, 'redirect_code');
              const room_id = _.get(routeParams, 'id');
              const callParams = {
                advisor_id,
                user_id,
                room_id,
                session_id: room_id,
                preview,
                manual_handle_error: true,
                redirect_code,
              };
              return callParams;
            }),
            loungeRoomModel: hook((ctx) => {
              const route = useRoute();
              const [res, $res] = React.useState(null);
              const navigateWithMessage = async (to, messageConfig) => {
                const locale_url = route.toLocale(to);
                if (!!messageConfig) {
                  await message.info(
                    {
                      ...messageConfig,
                      style: {
                        marginTop: '10vh',
                      },
                    },
                    1.5
                  );
                }
                route.navigateExternal(locale_url);
              };
              const manualHandler = {
                invalid_room_time: (error) => {
                  const redirectUrl = _.get(error, 'params.redirect');
                  redirectUrl &&
                    navigateWithMessage(redirectUrl, {
                      content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_time'),
                    });
                },
                invalid_room_completed: (error) => {
                  const redirectUrl = _.get(error, 'params.redirect');
                  redirectUrl &&
                    navigateWithMessage(redirectUrl, {
                      content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_completed'),
                    });
                },
              };
              return {
                res,
                setResponse: (res) => {
                  const { error, errorHandler } = res;
                  const code = _.get(error, 'code');
                  if (!_.has(manualHandler, code) && _.isFunction(errorHandler)) {
                    errorHandler(error);
                  }
                  $res(res);
                  ctx.apply('loadingValidModel.clearLoading', 'fetchValidRoom');
                },
                apply: () => {
                  if (res && res.error) {
                    const { error } = res;
                    const code = _.get(error, 'code');
                    if (_.has(manualHandler, code)) {
                      manualHandler[code](error);
                    }
                  } else {
                    const params = {
                      step: 'service',
                    };
                    ctx.apply('stepModel.setStep', params.step);
                  }
                },
              };
            }),
            errorHandler: hook((ctx) => VideoCallModelHandler.roomError(ctx)),

            '@item': hook((ctx) => {
              const { room_id } = ctx.get('@params');

              const room = usePromiseSource(
                async () => {
                  try {
                    const condition = `where: { id: {_eq:"${room_id}"} }`;
                    const nowtz = moment()
                      .utc()
                      .format(TIMESTAMPTZ_FORMAT);
                    const room = await B2BCourseRoomModel.find(
                      condition,
                      `
                        id
                        name
                        status
                        start_at
                        end_at
                        join_url
                        course {
                          id
                          name
                          session_duration
                          next_rooms: course_rooms(
                            where: {end_at: {_gte: "${nowtz}"}},
                            limit: 1, order_by: {end_at: asc_nulls_last}
                          ) {
                            id
                            name
                            status
                            start_at
                            end_at
                            join_url
                          }
                        }
                      `
                    );
                    return _.first(room.toObject());
                  } catch (err) {}
                },
                null,
                [room_id]
              );
              if (!_.isEmpty(room)) ctx.apply('loadingRoomModel.clearLoading', 'fetchRoom');
              return room;
            }),
            isCalling: hook((ctx) => {
              return ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallOnProgress);
            }),
            '@sectionActions': hook((ctx) => {
              const [visibleDropdown, $visibleDropdown] = React.useState(false);
              return {
                visibleDropdown,
                $visibleDropdown: (visible) => {
                  $visibleDropdown(visible);
                },
              };
            }),
            smartGesture: hook((ctx) => {
              const [controlBarStatus, $controlBarStatus] = React.useState('half');
              const [swap, $swap] = React.useState(false);
              const [dragging, $dragging] = React.useState(false);
              return {
                controlBarStatus,
                swap,
                dragging,
                slideUp: () => {},
                $touchScreen: () => {
                  if (!dragging) {
                    const index = _.findIndex(CONTROL_BAR_STATUS, (item) => controlBarStatus === item);
                    if (CONTROL_BAR_STATUS[index + 1]) {
                      $controlBarStatus(CONTROL_BAR_STATUS[index + 1]);
                    } else {
                      $controlBarStatus(CONTROL_BAR_STATUS[0]);
                    }
                  }
                },
                $touchSmallScreen: () => {
                  if (!dragging) {
                    $swap(!swap);
                  }
                },
                onDrag: () => {
                  $dragging(true);
                },
                onStopDrag: () => {
                  setTimeout(() => {
                    $dragging(false);
                  }, 200);
                },
              };
            }),
            '@page': hook((ctx) => {
              const route = useRoute();
              return {
                onCompleted: (status) => {
                  const videoCallModel = ctx.get('videoCallModel');
                  const session_id = videoCallModel.getSectionId();
                  const { purchase_id, advisor_id } = ctx.get('@params');
                  const params = {
                    advisor_id,
                    purchase_id,
                    session_id,
                    step: 'completed',
                    status: status || 'completed',
                  };
                  // Change URL
                  const toUrl = route.redirectUrl(params, { origin: false });
                  route.navigate(toUrl, {
                    relace: true,
                    state: { purchase_id },
                  });
                  // Change Step
                  route.setRouteState(params);
                  ctx.apply('stepModel.setStep', params.step);
                },
              };
            }),
          },
        },
      ],
    ],
  },
  serviceStep: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('stepModel.currentStep') === 'service' || !!ctx.get('@params.redirect_code');
          }),
        },
      ],
    ],
  },
  sectionVideoActions: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return (
              true ||
              ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallConnecting) ||
              ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallOnProgress)
            );
          }),
        },
      ],
    ],
  },
  videoCallFinish: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('videoCallModel.hasCallState', VideoCallModel.CALL_STATES.videoCallFinish)),
        },
      ],
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingRoomModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingRoomModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
