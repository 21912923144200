import React from 'react';
import _ from 'lodash';
import CourseRoomModelFormatter from '@uz/unitz-models/CourseRoomModel/formatter';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const SelectContainer = styled.div`
  width: 100%;
  .ant-select {
    min-width: 80%;
    .ant-select-selector {
      border-color: transparent !important;
      padding: 0px !important;
      .ant-select-selection-item {
        font-weight: 600;
        font-size: 16px;
      }
    }
    .ant-select-arrow {
      font-weight: 600;
    }
  }
`;

const RoomSelect = ({ items, ...rest }) => {
  return (
    <DIV>
      <SelectContainer>
        <Select style={{ width: 220 }} {...rest}>
          {_.map(items, (item) => {
            return (
              <Option key={item.id} value={item.id}>
                {CourseFormatter.replaceThg()(
                  CourseFormatter.replaceSACH()(CourseRoomModelFormatter.courseDay(ctx)(item))
                )}
              </Option>
            );
          })}
        </Select>
      </SelectContainer>
    </DIV>
  );
};

export default RoomSelect;
