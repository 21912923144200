import React from 'react';
import { bindings, hook } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';
import PurchaseModel from '@uz/unitz-models/PurchaseModel';
import TransactionModel from '@uz/unitz-models/TransactionModel';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';
import useSubjectSource from '@vl/hooks/useSubjectSource';
// import useSubjectState from '@vl/hooks/useSubjectState';
import AdvisorFormatter from '@uz/unitz-models/AdvisorModel/formatter';
import TransactionFormatter from '@uz/unitz-models/TransactionModel/formatter';
import CFAdvisorProfileFormatter from '@uz/unitz-models/CFAdvisorProfileModel/formatter';
import PurchaseFormatter from '@uz/unitz-models/PurchaseModel/formatter';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useObservableSource from '@vl/hooks/useObservableSource';
import { formatVND } from '@vl/mod-utils/currencyFormatter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AdvisorReviewModel from '@uz/unitz-models/AdvisorReviewModel';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            transaction: hook((ctx) => {
              const session_id = _.get(useRoute(), 'params.session_id', '');
              const items = useObservableSource(() => TransactionModel.find(
                `where: {session_id: {_eq: "${session_id}"}}`,
                `
                    service_pricing {
                      detail {
                        per_amount
                        per_unit
                        price_amount
                        price_currency
                        duration_amount: per_amount
                        duration_unit: per_unit
                      }
                    }
                    session_detail {
                      duration_unit
                      duration_amount
                    }
                    status_latest {
                      status
                    }
                  `
              ));
              return items;
            }),
            transactionData: hook((ctx) => {
              const transactions = useSubjectSource(ctx.get('transaction'));
              const transaction = _.get(transactions, '0');
              const statusLatest = TransactionFormatter.statusLatest(ctx)(transaction);
              const callPackageTimeMinute = AdvisorFormatter.callPackageTimeMinute(ctx)(transaction);
              return {
                callPackage: AdvisorFormatter.callPackage(ctx)(transaction),
                callPackageTime: AdvisorFormatter.callPackageTime(ctx)(transaction),
                callPackagePrice: AdvisorFormatter.callPackagePrice(ctx)(transaction),
                callPackageTotal: AdvisorFormatter.callPackageTotal(ctx)(transaction),
                callPackageTimeMinute,
                statusLatest,
              };
            }),
            purchaseData: hook((ctx) => {
              const purchase_id = _.get(useRoute(), 'params.purchase_id', '');
              const purchaseData = usePromiseSource(
                async () => {
                  if (!purchase_id) return null;
                  try {
                    const purchase = await PurchaseModel.find(
                      `where: {id: {_eq: "${purchase_id}"}}`,
                      `
                        id
                        payments {
                          amount
                          type
                        }
                        services {
                          id
                          price_amount
                          advisor_service {
                            id
                            service_id
                            advisor {
                              id
                              profile {
                                display_name
                                avatar_url
                              }
                              review_agg {
                                avg_score
                              }
                              reviews_aggregate {
                                aggregate { count }
                              }
                            }
                            service {
                              name
                              kind
                              id
                            }
                            price {
                              price_amount
                              price_currency
                              per_unit
                              per_amount
                            }
                            discounts {
                              percentage
                              currency
                              id
                              amount
                              type
                            }  
                          }
                          
                        }
                        status {
                          status
                        }
                        status_latest {
                          status
                        }
                      `
                    );
                    return _.first(purchase.toObject());
                  } catch (err) {}
                },
                null,
                [purchase_id]
              );

              const advisorService = _.get(purchaseData, 'services.0.advisor_service');
              const advisor = _.get(advisorService, 'advisor');
              const service = _.get(advisorService, 'service');
              const advisorProfile = _.get(advisor, 'profile');
              const reviewAggregate = _.get(advisor, 'reviews_aggregate.aggregate');
              const bill = CFAdvisorProfileFormatter.amountPayment(ctx)(advisorService);

              const rtn = {
                advisorProfile: {
                  displayName: AdvisorProfileFormatter.displayName(ctx)(advisorProfile),
                  avatarUrl: AdvisorProfileFormatter.avatarUrl(ctx)(advisorProfile),
                  rating: {
                    ratingText: AdvisorProfileFormatter.reviewText(ctx)(advisor),
                    ratingScore: AdvisorProfileFormatter.reviewScore(ctx)(advisor),
                  },
                  reviewCount: AdvisorProfileFormatter.reviewCount(ctx)(reviewAggregate),
                },
                status: PurchaseFormatter.getStatus(ctx)(purchaseData),
                bill: {
                  totalAfter: _.get(bill, 'totalAfter') ? formatVND(_.get(bill, 'totalAfter')) : 0,
                  totalBefore: _.get(bill, 'totalBefore') ? formatVND(_.get(bill, 'totalBefore')) : 0,
                  discount: _.get(bill, 'discount') ? formatVND(_.get(bill, 'discount')) : 0,
                },
                service,
              };
              return rtn;
            }),
            form: hook((ctx) => {
              const advisor_id = _.get(useRoute(), 'params.id', '');
              const user_id = ctx.apply('authModel.getUserId');
              // const userId = ctx.apply('authModel.getUserId');
              const form = useFormik({
                initialValues: {
                  rating: 5,
                  note: '',
                },
                onSubmit: async (values) => {
                  try {
                    await AdvisorReviewModel.create({
                      target_id: advisor_id,
                      review_text: _.get(values, 'note', ''),
                      score: _.get(values, 'rating', 0) * 2,
                      created_by: user_id,
                    });
                    await ctx.apply('stepModel.handlers.callFinish');
                  } catch (error) {
                    console.log(error);
                  }
                },
                validationSchema: Yup.object().shape({
                  rating: Yup.number().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });
              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
            page: hook((ctx) => {
              const [rating, $rating] = React.useState(false);
              React.useEffect(() => {
                if (ctx.get('transactionData.callPackageTimeMinute') >= 3 && !rating) {
                  $rating(true);
                }
              }, [ctx.get('transactionData.callPackageTimeMinute')]);
              return {
                rating,
                $rating,
                handlers: {
                  ratingFinish: () => {
                    $rating(false);
                  },
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  completedSuccess: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            const completeStatus = _.get(useRoute(), 'params.status');
            return (
              ctx.get('transactionData.statusLatest') !== 'incomplete'
              && (!completeStatus || completeStatus === 'completed')
              && !ctx.get('page.rating')
            );
          }),
        },
      ],
    ],
  },
  completedMissed: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => _.get(useRoute(), 'params.status') === 'missed'),
        },
      ],
    ],
  },
  completedRejected: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => _.get(useRoute(), 'params.status') === 'rejected'),
        },
      ],
    ],
  },
  completedEarlyEnd: {
    rules: [
      [
        'display',
        {
          display: hook(
            (ctx) => _.get(useRoute(), 'params.status') === 'completed'
              && ctx.get('transactionData.statusLatest') === 'incomplete'
          ),
        },
      ],
    ],
  },
  completedRating: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.get('page.rating')),
        },
      ],
    ],
  },
});

export default bindData;
