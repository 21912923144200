import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { START_TIME_WITHOUT_DAY_OF_WEEK } from '@vl/mod-utils/datetime';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import i18n from 'i18n-js';
// import { Divider } from 'antd';

export const MessageStartDay = React.memo(({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const locale = i18n.locale;
  return (
    <div style={{ color: '#99AFC2' }} className="py-4 text-center text-xs">
      {moment(message.created_at).format(_.get(START_TIME_WITHOUT_DAY_OF_WEEK, locale, 'de'))}
    </div>
  );
});

export default MessageStartDay;
