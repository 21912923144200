import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { Input, Rate } from 'antd';
// import { Avatar } from '@uz/unitz-components-web/Avatar';
import { Button } from '@uz/unitz-components-web/Button';
import { UnAuthRedirect } from '@uz/unitz-components-web/AppRedirect';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import _ from 'lodash';
// const { Text } = Typography;
const FinishLayout = styled.div`
  padding: 0 16px;
  textarea.ant-input {
    font-size: 16px;
    background-color: transparent;
  }
  textarea.ant-input::placeholder {
    font-size: 16px;
  }
  .ant-input {
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    --tw-border-opacity: 1;
    border-color: rgba(191, 205, 217, var(--tw-border-opacity));
  }
`;
const Triagle = styled.div.attrs((props) => ({
  halfSize: props.size ? `${props.size / 2}px` : '5px',
  size: props.size ? `${props.size}px` : '10px',
  color: props.color ? props.color : gstyles.colors.border,
}))`
  position: relative;
  overflow: hidden;
  transform: translateY(50%) rotate(45deg) skewY(45deg) scaleX(0.5);
  pointer-events: none;
  & {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }

  &::before,
  &::after {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    background: ${(props) => props.color};
    pointer-events: auto;
  }
  &::before {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
  }
  &::after {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
  }

  border-radius: 20%;
  &::before {
    border-radius: 20% 20% 20% 55%;
  }

  &::after {
    border-radius: 20% 20% 55% 20%;
  }
  textarea::-webkit-resizer {
    display: none;
  }
`;
const Index = () => {
  return (
    <DIV className="ratingFlatformPage">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        {/* <Modal
          className="pb-0 overflow-hidden rounded-xl"
          // style={{ borderRadius: 12, backgroundColor: 'transparent' }}
          bodyStyle={{ padding: 0 }}
          width={600}
          footer={null}
          visible={true}
          closable={false}
          mask={false}
          transparent
        > */}
        <FinishLayout>
          <section className="py-0 bg-white opacity-0 min-lg:w-150 max-w-150 animate-opacity rounded-xl">
            <div className="flex flex-col items-center px-6 py-6 pt-6 pb-4">
              <div className="mb-1 text-main">{ctx.apply('i18n.t', 'AdvisorVideoCall.title')}</div>
              <div className="flex items-center mt-1">
                {gstyles.icons({
                  name: 'videocam',
                  fill: gstyles.colors.brandb500,
                  size: 26,
                  className: 'mr-2',
                })}
                <div className="mb-0 text-2xl font-bold text-main">{ctx.get('transactionData.callPackageTime')}</div>
              </div>
              <div className="mt-4 mb-3 font-bold text-center min-lg:text-xl max-lg:text-lg text-main">
                {ctx.apply('i18n.t', 'AdvisorVideoCall.ratingText')}
              </div>
              <Rate
                count={5}
                value={ctx.get('form.values.rating')}
                onChange={(number) => ctx.apply('form.handleChange', 'rating')(number.toString())}
              />
              <label className="mt-3 text-base text-main">{ctx.get('form.values.label')}</label>
            </div>
            <div className="flex flex-col px-6 pb-0">
              <label className="mb-4 text-center text-main">{ctx.get('form.values.title')}</label>
              <div className="flex flex-wrap">
                {ctx.get('form.values.suggestionRender').map((item, index) => (
                  <mark
                    key={`suggest_${index}_${ctx.get('form.values.rating')}`}
                    className={cx(
                      'cursor-pointer px-3 py-1 mb-3 mr-2 rounded-10 ',
                      {
                        'bg-brandb text-white': _.get(item, 'selected', false),
                      },
                      {
                        'bg-background2 text-sub': !_.get(item, 'selected', false),
                      }
                    )}
                    onClick={() => ctx.apply('form.onChangeSuggestion', item)}
                  >
                    {item.text}
                  </mark>
                ))}
              </div>
              <div className="relative">
                <Input.TextArea
                  rows={3}
                  value={ctx.get('form.values.note')}
                  placeholder={ctx.apply('i18n.t', 'AdvisorVideoCall.finishNote')}
                  onChange={(e) => ctx.apply('form.handleChange', 'note')(_.get(e, 'target.value', ''))}
                ></Input.TextArea>
                <div className="absolute p-1 bg-white rounded-lg pointer-events-none right-1px bottom-1px">
                  <Triagle className="" size={10} color={gstyles.colors.border} />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between p-6 space-x-4">
              <Button
                onClick={ctx.get('stepModel.handlers.callAgain')}
                icon={gstyles.icons({
                  name: 'videocam',
                  size: 22,
                  className: 'inline-block mr-2',
                })}
                size="large"
                block
                name="ink"
              >
                {ctx.apply('i18n.t', 'AdvisorVideoCall.reCall')}
              </Button>
              <Button
                onClick={ctx.get('form.submitForm')}
                disabled={!ctx.get('canSubmit')}
                loading={ctx.get('form.isSubmitting')}
                size="large"
                type="primary"
                block
              >
                {ctx.apply('i18n.t', 'AdvisorVideoCall.finishSend')}
              </Button>
            </div>
          </section>
        </FinishLayout>
        {/* </Modal> */}
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
