import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Button, Tooltip, Upload } from 'antd';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

const StyledSelect = styled.div`
  .ant-btn.ant-btn-sm {
    background-color: ${gstyles.colors.sub};
    border: none;
    border-radius: 0;
  }
`;

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });
  const forceUpdate = useForceUpdate();

  const applicableTarget = (() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    return true;
  })();

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Tooltip
        title={
          <div className="space-x-2">
            <span>{i18n.t('Blackboard.import')}</span>
          </div>
        }
      >
        <Upload
          key="leftButton"
          {...{
            onProgress: ({ percent }, file) => {},
            beforeUpload: (file) => {
              const reader = new window.FileReader();
              reader.onload = function() {
                const arrayBuffer = this.result;
                const array = new Uint8Array(arrayBuffer);
                const binaryString = String.fromCharCode.apply(null, array);
                try {
                  const payload = JSON.parse(atob(binaryString));
                  canvas.importCanvasPayload(payload);
                } catch (err) {
                  console.log(err);
                }
              };
              reader.readAsArrayBuffer(file);
              forceUpdate();
              return false;
            },
            multiple: false,
          }}
          showUploadList={false}
          accept="text/json"
          // onChange={(file) => {
          // }}
        >
          <Button size="small" {...rest}>
            {gstyles.icons({ name: 'attachment', size: 20, fill: gstyles.colors.white500 })}
          </Button>
        </Upload>
      </Tooltip>
    </StyledSelect>
  );
};

export default displayName(Index);
