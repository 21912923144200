import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import Combokeys from 'combokeys';

import ColorInput from '../Inputs/Color';
import TypographyInput from '../Inputs/Typography';
import FontSizeInput from '../Inputs/FontSize';
import TextAlignInput from '../Inputs/TextAlign';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 2px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const { fabric } = window;
    const target = new fabric.ToolbarText('', {});
    return target;
  });
  const ref = React.useRef({ disposers: [] });

  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return !!ref.current.target.isTextMode;
  }, [ref.current.target.isTextMode]);

  React.useEffect(() => {
    // shortcut key bindings
    disposerCleaner(
      (() => {
        const combokeys = new Combokeys(window);
        combokeys.bind('esc', (evt) => {
          if (canvas.isTextMode) {
            evt.stopPropagation();
            evt.preventDefault();
            ref.current.target.set({
              isTextMode: false,
            });
            canvas.isTextMode = false;
            canvas.defaultCursor = 'default';
            canvas && canvas.updateObject(ref.current.target);
            canvas.fire('mode:changed', {
              mode: 'text',
              value: !!canvas.isTextMode,
              source: 'text',
            });
            forceUpdate();
            return false;
          }
          return false;
        });
        return () => {
          combokeys && combokeys.detach();
        };
      })()
    );
  }, []);

  React.useEffect(() => {
    const { fabric } = window;

    disposerCleaner(
      canvas.$on('object:modified', ({ target }) => {
        const { type } = target;
        if (_.get(target, 'isToolbar')) {
          if (type === 'toolbar-text') {
            forceUpdate();
          }
        }
      })
    );

    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        const { mode } = event;
        if (mode !== 'text' && canvas.isTextMode) {
          canvas.isTextMode = false;
        }
        forceUpdate();
      })
    );

    disposerCleaner(
      canvas.$on('mouse:up', (evt) => {
        const left = _.get(evt, 'absolutePointer.x');
        const top = _.get(evt, 'absolutePointer.y');
        if (canvas.isTextMode) {
          // const itext = new fabric.IText(i18n.t('Whiteboard.toolbar.textPlaceholder'), {
          const itext = new fabric.IText('', {
            id: canvas.genObjectId(),
            text: '',
            owner_id: canvas.getOwnerId(),
            left,
            top,
            fill: ref.current.target.fill,
            stroke: ref.current.target.stroke,
            strokeWidth: ref.current.target.strokeWidth,
            charSpacing: ref.current.target.charSpacing,
            fontSize: ref.current.target.fontSize,
            width: 40,
          });
          canvas.isTextMode = false;
          setTimeout(() => {
            canvas.isTextMode = false;
          }, 13);
          canvas.defaultCursor = 'default';
          canvas.add(itext);
          canvas.setActiveObject(itext);
          itext.enterEditing().selectAll();
          canvas.fire('mode:changed', { mode: 'text', source: 'text', value: false });
          forceUpdate();
        }
      })
    );
  }, []);

  React.useEffect(() => {
    if (canvas.isTextMode) {
      gstyles.Icons.getCursor({
        name: 'cursor-type',
        // fill: gstyles.colors.black500,
        fill: '#000000',
        size: 24,
      }).then((cursor) => {
        const textCursor = `url(${cursor}) 8 8, pointer`;
        canvas.defaultCursor = textCursor;
      });
    }
  }, [canvas.isTextMode]);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  const overlay = React.useMemo(() => {
    return (
      <div className="flex flex-row bg-sub rounded-lg w-full">
        <div>
          <ColorInput target={ref.current.target} canvas={canvas} />
        </div>
        <div>
          <TypographyInput target={ref.current.target} canvas={canvas} />
        </div>
        <div>
          <FontSizeInput target={ref.current.target} canvas={canvas} />
        </div>
        <div>
          <TextAlignInput target={ref.current.target} canvas={canvas} />
        </div>
      </div>
    );
  }, [canvas, ref.current.target]);
  return (
    <MenuItem
      type={canvas.isTextMode ? 'primary' : 'text'}
      getPopupContainer={(node) => (ref.current.eleRef = node && node.parentElement)}
      visible={canvas.isTextMode}
      trigger={['click']}
      onClick={() => {
        ref.current.target.set({
          isTextMode: !ref.current.target.isTextMode,
        });
        canvas.isTextMode = !canvas.isTextMode;
        canvas.fire('mode:changed', {
          mode: 'text',
          source: 'text',
          value: !!canvas.isTextMode,
        });
      }}
      overlay={overlay}
      buttonsRender={([leftButton, rightButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.textTooltip')}</span>
              <span className="">T</span>
            </div>
          }
          key="leftButton"
        >
          {leftButton}
        </Tooltip>,
        rightButton,
      ]}
      icon={gstyles.icons({ name: 'arrow-down', size: 16, fill: gstyles.colors.white500 })}
      placement="topLeft"
    >
      <span>{gstyles.icons({ name: 'text-type', size: 20, fill: gstyles.colors.white500 })}</span>
    </MenuItem>
  );
};

export default displayName(Index);
