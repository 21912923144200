import _ from 'lodash';

const whiteListTest = [_.isString, _.isArray, _.isBoolean, _.isDate, _.isNumber, _.isNull];
const serializeDoc = (doc) => {
  const rtn = _.isArray(doc) ? [] : {};
  _.map(doc, (val, key) => {
    // accept val type
    if (_.isPlainObject(val)) {
      rtn[key] = serializeDoc(val);
    } else if (_.some(whiteListTest, (test) => test(val))) {
      rtn[key] = val;
    }
  });
  return rtn;
};

const toObject = (target) => {
  const rtn = {};
  for (const prop in target) {
    if (`${prop}`.charAt(0) === '_') continue;
    const val = target[prop];
    if (_.isFunction(val)) continue;
    rtn[prop] = val;
  }
  return rtn;
};

const maskingAssign = (target, obj) => {
  const targetMask = { ...target };
  const objKeys = Object.keys(obj);
  for (const key of objKeys) {
    target[key] = obj[key];
    delete targetMask[key];
  }
  for (const key of Object.keys(targetMask)) {
    if (`${key}`.charAt(0) === '_') continue;
    delete target[key];
  }
};

const connectFirestore = (ctx, store) => (target, { docId, collectionName, mode = 'rw' }) => {
  const disposers = [];
  const changeLogs = [];
  const ref = {
    localState: {},
    remoteState: null,
  };
  const hasState = (type) => !!ref[type];
  const triggerChangeLocal = () => {
    if (target && _.isFunction(target.emit)) {
      target.emit('onChange');
    }
  };
  const user_id = ctx.apply('authModel.getUserId');

  const isEchoState = (state) => {
    const lastLog = _.last(changeLogs);
    return _.isEqual(lastLog, _.pick(state, ['updated_at', 'updated_by']));
  };

  const docRef = ctx
    .get('authModel.dbh')
    .collection(collectionName)
    .doc(docId);
  // downstream sync
  if (`${mode}`.includes('r')) {
    disposers.push(
      docRef.onSnapshot((doc) => {
        const newState = doc.data();
        if (isEchoState(newState)) {
          return;
        }
        const isLocalChange = doc.metadata.hasPendingWrites;
        if (isLocalChange) {
          ref.remoteState = newState;
          // no need to update
          return;
        }

        const storeState = toObject(store);
        ref.localState = storeState;
        ref.remoteState = newState;
        // check echo remoteState

        if (!_.isEqual(ref.remoteState, ref.localState)) {
          // update local state
          const mergeState = {
            // ..._.cloneDeep(ref.localState),
            ..._.cloneDeep(ref.remoteState),
          };

          maskingAssign(store, mergeState);
          triggerChangeLocal();
        }
      })
    );
  }

  // upstream sync
  if (`${mode}`.includes('w')) {
    disposers.push(
      target.on('onChange', () => {
        const storeState = toObject(store);
        ref.localState = storeState;
        if (!hasState('remoteState')) {
          ref.remoteState = {};
        }
        if (!_.isEqual(ref.localState, ref.remoteState)) {
          const mergeState = {
            // ..._.cloneDeep(ref.remoteState),
            ..._.cloneDeep(ref.localState),
          };
          const newState = serializeDoc(mergeState);
          const updated_at = ctx.apply('authModel.firebase.firestore.FieldValue.serverTimestamp');
          const updated_by = user_id;
          const writeLog = { updated_by, updated_at };
          changeLogs.push(writeLog);
          changeLogs.length > 10 && changeLogs.splice(0, 1);
          docRef.set(
            {
              ...newState,
              // updated_at: ctx.get('authModel.firebase.database.ServerValue.TIMESTAMP'),
              ...writeLog,
            },
            { merge: true }
          );
        }
      })
    );
  }

  return () => {
    disposers.map((dis) => dis());
    disposers.splice(0, disposers.length);
    _.map(ref, (val, key) => delete ref[key]);
  };
};

export default connectFirestore;
