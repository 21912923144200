import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRoute';
import { message } from 'antd';

export const handler = {
  roomError: (ctx) => {
    const route = useRoute();
    const navigateWithMessage = async (to, messageConfig) => {
      const locale_url = route.toLocale(to);
      await message.info(
        {
          ...messageConfig,
          style: {
            marginTop: '10vh',
          },
        },
        5
      );
      // route.navigate(to);
      route.navigateExternal(locale_url);
    };
    const errorHandlers = {
      invalid_room_attendee: (error) => {
        const redirectUrl = _.get(error, 'params.redirect');
        redirectUrl &&
          navigateWithMessage(redirectUrl, {
            content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_attendee'),
          });
      },
      invalid_room_purchase: (error) => {
        const redirectUrl = _.get(error, 'params.redirect');
        redirectUrl &&
          navigateWithMessage(redirectUrl, {
            content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_purchase'),
          });
      },
      invalid_room_time: (error) => {
        const redirectUrl = _.get(error, 'params.redirect');
        redirectUrl &&
          navigateWithMessage(redirectUrl, {
            content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_time'),
          });
      },
      invalid_room_completed: (error) => {
        const redirectUrl = _.get(error, 'params.redirect');
        redirectUrl &&
          navigateWithMessage(redirectUrl, {
            content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_completed'),
          });
      },
      invalid_room_host: (error) => {
        const redirectUrl = _.get(error, 'params.redirect');
        redirectUrl &&
          navigateWithMessage(redirectUrl, {
            content: ctx.apply('i18n.t', 'CourseRoom.validation.invalid_room_host'),
          });
      },
    };
    return (error) => {
      const code = _.get(error, 'code');
      const handler = _.get(errorHandlers, code);
      if (_.isFunction(handler)) {
        handler(error);
      }
    };
  },
};

export default handler;
