import _ from 'lodash';
import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetMessage from 'unitz-gql/message/GetMessage.gql';

graphme.GqlBuilder.loadDocument({
  GetMessage,
});

class MessageModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Message',
    schema: {
      id: String,
      is_active: Boolean,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetMessage',

    GQL_ACTIONS: {
      FIND: 'message',
      GET: 'message_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }

  static async create(payload) {
    try {
      const res = await hasuraClient.getClient().request(
        hasuraClient.gql`
        mutation insert_message_one($payload: message_insert_input!) {
          insert_message_one(
            object: $payload
            on_conflict: {
              constraint: message_pkey,
              update_columns: [message, message_jsonb],
            }
          ) {
            id
          }
        }
        `,
        { payload }
      );
      return _.get(res, 'insert_message_one');
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async remove(message_id) {
    try {
      const res = await hasuraClient.getClient().request(
        hasuraClient.gql`
        mutation update_message_by_pk($message_id: String!) {
          update_message_by_pk(
            pk_columns: { id: $message_id }
            _set: { deleted: 1 }
          ) {
            id
          }
        }
        `,
        {
          message_id,
        }
      );
      return _.get(res, 'update_message_by_pk');
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

graphme.model({ MessageModel });

export default MessageModel;
