import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import MessageInput from '@uz/unitz-tool-components/Message/Input';
import MessageContainer from '@uz/unitz-tool-components/Message/Container';
import LayoutWidgetMessage from '@uz/unitz-layout-web/LayoutWidgetMessage';

const Index = () => {
  return (
    <DIV className="componentContainer">
      <LayoutWidgetMessage>
        <LayoutWidgetMessage.POS name="message-container">
          <MessageContainer messages={ctx.get('messageModel.items')} />
        </LayoutWidgetMessage.POS>
        <LayoutWidgetMessage.POS name="message-input">
          <MessageInput
            onChange={() => {
              const messageContainerRef = ctx.get('messageContainerRef');
              const followScrollPosition = _.get(messageContainerRef, 'current.followScrollPosition');
              if (_.isFunction(followScrollPosition)) {
                followScrollPosition();
              }
            }}
          />
        </LayoutWidgetMessage.POS>
      </LayoutWidgetMessage>
    </DIV>
  );
};

export default displayName(Index);
