import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { useRoom } from '@uz/unitz-components-web/RoomWebComponents/OTSession';
import { promoteTrack, getStreamId } from '@vl/mod-utils/deviceInfoWeb';
import StyledSlider from '@uz/unitz-components-web/RoomWebComponents/StyledSlider';
import gstyles from '@vl/gstyles';
import ActionButtonLarge from '@uz/unitz-components-web/RoomWebComponents/StyledActionButtonLarge';
import elementResizeDetectorMaker from 'element-resize-detector';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import styled from 'styled-components';
import Draggable from 'react-draggable'; // Both at the same time
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import useReactiveStore from '@vl/hooks/useReactiveStore';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';

// import ZoomInOutlined from '@ant-design/icons/ZoomInOutlined';
// import ZoomOutOutlined from '@ant-design/icons/ZoomOutOutlined';

import Glightbox from '@uz/unitz-components-web/Glightbox';

const DraggableHandle = styled.div`
  cursor: move;
`;

const BASE_SLIDE_WIDTH = 192;
const BASE_SLIDE_HEIGHT = 112;
const MIN_ZOOM = 0.7;
const MAX_ZOOM = 2.5;

const StreamSliders = React.memo(
  ({ sliderEle, sliderProps }) => {
    const slideCount = _.get(_.values(sliderEle), 'length');

    const [store] = useReactiveStore(`streamSliderLayout_${slideCount}`);

    const containerStyle = store.reduce((store) => {
      let containerStyle;

      const {
        isHideMode,
        vertical,
        slideWidth,
        paddingX,
        slideHeight,
        padding,
        paddingY,
        slideCount,
        containerRect,
        dragState,
      } = store.get();
      const TOP_PADDING = paddingY * 4;
      const LEFT_PADDING = paddingX * 2;
      const HANDLE_HEIGHT = paddingY * 2;

      if (isHideMode) {
        containerStyle = {
          width: 0,
          height: 0,
          maxWidth: 0,
          maxHeight: 0,
          marginLeft: 'auto',
          overflow: 'hidden',
        };
      } else {
        containerStyle = vertical
          ? {
              width: slideWidth + paddingX * 2,
              height: slideHeight * slideCount + padding,
              maxWidth: _.get(containerRect, 'width', slideWidth * 2) - paddingX * 2,
              maxHeight:
                _.get(containerRect, 'height', slideHeight * 2) - paddingY * 2 - _.get(dragState, 'y', 0) - TOP_PADDING,
              marginLeft: 'auto',
              overflow: 'hidden',
            }
          : {
              width: slideWidth * _.get(_.values(sliderEle), 'length') + paddingX * 2,
              height: slideHeight,
              maxWidth: _.get(containerRect, 'width', slideWidth * 2) - paddingX * 2,
              maxHeight: _.get(containerRect, 'height', slideHeight * 2) - paddingY * 2,
              marginLeft: 'auto',
              overflow: 'hidden',
            };
      }
      return containerStyle;
    });

    const slideProps = store.reduce((store) => {
      const {
        isHideMode,
        vertical,
        slideWidth,
        paddingX,
        slideHeight,
        padding,
        paddingY,
        slideCount,
        containerRect,
        dragState,
      } = store.get();
      const TOP_PADDING = paddingY * 4;

      let slideProps;
      if (vertical) {
        const maxHeight =
          _.get(containerRect, 'height', slideHeight * 2) - paddingY * 2 - _.get(dragState, 'y', 0) - TOP_PADDING;
        const slidesToShow = Math.min(slideCount, _.round(maxHeight / slideHeight));

        slideProps = {
          slidesToScroll: 1,
          slidesToShow,
          variableWidth: false,
        };
      } else {
        const maxWidth = _.get(containerRect, 'width', slideWidth * 2) - paddingX * 2;
        const slidesToShow = Math.min(slideCount, _.round(maxWidth / slideWidth));
        slideProps = {
          slidesToScroll: slidesToShow,
          slidesToShow,
          variableWidth: true,
        };
      }
      return slideProps;
    });

    if (!_.get(_.values(sliderEle), 'length')) return null;

    return (
      <div style={containerStyle}>
        <StyledSlider {...sliderProps} {...slideProps} containerStyle={containerStyle}>
          {_.map(sliderEle, (item, key) => {
            return <React.Fragment key={key}>{item}</React.Fragment>;
          })}
        </StyledSlider>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(prevProps.sliderProps, nextProps.sliderProps) &&
      _.isEqual(_.keys(prevProps.sliderEle), _.keys(nextProps.sliderEle))
    );
  }
);

export const OTStreamsSlider = ({ ele }) => {
  const ref = React.useRef({
    erd: elementResizeDetectorMaker({}),
  });
  const forceUpdate = useForceUpdate(1000);
  const disposerCleaner = useDisposerCleaner();

  const [vertical, $vertical] = React.useState(true);
  const [isHideMode, $isHideMode] = React.useState(false);
  // const [zoom, $zoom] = React.useState(1);

  const [zoom, $zoom] = useLocalStorage('@UZ::OTStreamsSlider::zoom', 1);

  const [posState, $posState] = useLocalStorage('@UZ::OTStreamsSlider::MainToolbarPos', {
    x: 0,
    y: 0,
  });

  React.useEffect(() => {
    const eleRef = document.querySelector('#room-call-content-body');
    ref.current.erd.listenTo(eleRef, (element) => {
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      const containerRect = { width, height };
      if (!_.isEqual(containerRect, ref.current.containerRect)) {
        ref.current.containerRect = containerRect;
      }
      forceUpdate();
    });

    disposerCleaner(
      Glightbox.on('open', () => {
        _.set(window, 'lightboxOpen', true);
        forceUpdate();
      })
    );
    disposerCleaner(
      Glightbox.on('close', () => {
        _.set(window, 'lightboxOpen', false);
        forceUpdate();
      })
    );
  }, []);

  _.assign(ref.current, {
    vertical,
    $vertical,
    isHideMode,
    $isHideMode,
    zoom,
    $zoom,
  });
  const room = useRoom();

  if (!ele) return null;

  const sliderEle = { ...ele };

  // for (let si = 0; si < 2; si++) {
  //   _.map(ele, (slide, key) => {
  //     sliderEle[`${key}&key=${si}`] = slide;
  //   });
  // }
  const slideCount = _.get(_.values(sliderEle), 'length');

  const [store] = useReactiveStore(`streamSliderLayout_${slideCount}`);

  const slideWidth = Math.floor(BASE_SLIDE_WIDTH * ref.current.zoom);
  const slideHeight = Math.floor(BASE_SLIDE_HEIGHT * ref.current.zoom);
  const padding = 8;
  const paddingX = 16;
  const paddingY = 16;

  store.set({
    slideWidth,
    slideHeight,
    slideCount,
    padding,
    paddingX,
    paddingY,
    isHideMode,
    vertical,
    containerRect: ref.current.containerRect,
  });

  const TOP_PADDING = paddingY * 4;
  const LEFT_PADDING = paddingX * 2;
  const HANDLE_HEIGHT = paddingY * 2;
  const bounds = {
    left: -(_.get(ref.current.containerRect, 'width', BASE_SLIDE_WIDTH) - BASE_SLIDE_WIDTH - LEFT_PADDING),
    right: paddingX,
    top: -TOP_PADDING,
    bottom:
      _.get(ref.current.containerRect, 'height', BASE_SLIDE_WIDTH) - BASE_SLIDE_HEIGHT - TOP_PADDING - HANDLE_HEIGHT,
  };

  const captionStyle = {
    width: BASE_SLIDE_WIDTH,
  };

  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ref.current.ctx = ctx;
        if (room) {
          const [subStreams] = promoteTrack(room, ctx);
          _.map(subStreams, (stream) => {
            const stream_id = getStreamId(stream, ctx);
            _.unset(sliderEle, stream_id);
            return stream_id;
          });
        } else {
          _.map(ele, (val, stream_id) => {
            if (ctx.apply('videoCallModel.isPinStream', stream_id)) {
              _.unset(sliderEle, stream_id);
            }
          });
        }
      })}
      <Draggable
        axis="both"
        handle=".drag-handle"
        // defaultPosition={posState}
        defaultPosition={{ x: 0, y: 0 }}
        position={null}
        grid={[8, 8]}
        scale={1}
        bounds={bounds}
        onStop={(evt, data) => {
          // $posState(_.pick(data, ['x', 'y']));
        }}
        onDrag={(evt, data) => {
          store.set('dragState', _.pick(data, ['x', 'y']));
        }}
      >
        <div>
          <div className="flex justify-end">
            <div className="flex items-center space-x-1" style={captionStyle}>
              {!!_.get(_.values(sliderEle), 'length') && (
                <ActionButtonLarge
                  icon={
                    <DraggableHandle className={'cursor-move drag-handle'}>
                      {gstyles.icons({
                        name: 'drag-indicator',
                        fill: gstyles.colors.white500,
                        size: 20,
                      })}
                    </DraggableHandle>
                  }
                  name="overlay"
                  type="primary"
                />
              )}
              {!!_.get(_.values(sliderEle), 'length') && (
                <ActionButtonLarge
                  icon={gstyles.icons({
                    name: !!ref.current.vertical ? 'horizontal' : 'vertical',
                    fill: gstyles.colors.white500,
                    size: 20,
                  })}
                  onClick={() => {
                    ref.current.$vertical(!ref.current.vertical);
                  }}
                  name="overlay"
                  type="primary"
                />
              )}
              {!!_.get(_.values(sliderEle), 'length') && (
                <ActionButtonLarge
                  icon={gstyles.icons({
                    name: isHideMode ? 'eye-invisible-filled' : 'eye-filled',
                    fill: gstyles.colors.white500,
                    size: 20,
                  })}
                  onClick={() => {
                    ref.current.$isHideMode(!ref.current.isHideMode);
                  }}
                  name="overlay"
                  type="primary"
                />
              )}
              {!!_.get(_.values(sliderEle), 'length') && (
                <ActionButtonLarge
                  onClick={() => {
                    ref.current.$zoom(Math.min(MAX_ZOOM, ref.current.zoom + 0.1));
                  }}
                  name="overlay"
                  type="primary"
                  disabled={ref.current.zoom === MAX_ZOOM}
                >
                  <div className="flex w-full h-full">
                    {gstyles.icons({
                      name: 'zoom-in',
                      size: 20,
                      fill: gstyles.colors.main,
                    })}
                  </div>
                </ActionButtonLarge>
              )}
              {!!_.get(_.values(sliderEle), 'length') && (
                <ActionButtonLarge
                  onClick={() => {
                    ref.current.$zoom(Math.max(MIN_ZOOM, ref.current.zoom - 0.1));
                  }}
                  name="overlay"
                  type="primary"
                  disabled={ref.current.zoom === MIN_ZOOM}
                >
                  <div className="flex w-full h-full">
                    {gstyles.icons({
                      name: 'zoom-out',
                      size: 20,
                      fill: gstyles.colors.main,
                    })}
                  </div>
                </ActionButtonLarge>
              )}
            </div>
          </div>

          <StreamSliders
            sliderEle={sliderEle}
            sliderProps={{
              dots: false,
              infinite: false,
              variableWidth: true,
              swipeToSlide: true,
              vertical,
              verticalSwiping: true,
              slideInfo: {
                width: slideWidth,
                height: slideHeight,
                paddingX,
                paddingY,
              },
            }}
          />
        </div>
      </Draggable>
    </DIV>
  );
};

export default OTStreamsSlider;
