import React from 'react';
import Video from 'twilio-video';
import _ from 'lodash';
import { getREF } from '@uz/unitz-providers/RefProvider';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { useEventTriggers } from '@vl/hooks/useEventTriggers';

export const OTSessionContext = React.createContext(null);

export const OTSession = ({ children, token, sessionId, eventHandlers }) => {
  const [isConnecting, $isConnecting] = React.useState(false);
  const eventTrigger = useEventTriggers(eventHandlers);

  const ref = React.useRef({});
  _.assign(ref.current, { isConnecting, $isConnecting });

  const participantConnected = React.useCallback((participant) => {
    console.log('Participant "%s" connected', participant.identity);
    // play notification sound
    require('@vl/mod-clients/sound')
      .default({
        src: ['notification-start.wav'],
        volume: 0.5,
      })
      .play();
  }, []);

  const participantDisconnected = React.useCallback((participant) => {
    console.log('Participant "%s" disconnected', participant.identity);
    require('@vl/mod-clients/sound')
      .default({
        src: ['notification-remove.wav'],
        volume: 0.5,
      })
      .play();
  }, []);

  const disconnect = React.useCallback(() => ref.current.room && ref.current.room.disconnect(), []);

  const connect = async () => {
    console.log('Connecting to Room "%s"', sessionId);
    try {
      // return null;
      const room = await Video.connect(token, {
        name: sessionId,
        networkQuality: true,
        audio: false,
        video: false,
      });
      eventTrigger.emitEvent('onConnected', room);
      console.log('Connected to Room "%s"', room);
      ref.current.room = room;
      ref.current.room.setMaxListeners(20);

      // setup events
      room.participants.forEach(participantConnected);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.once('disconnected', () => {
        room.participants.forEach(participantDisconnected);
        // Reset the room only after all other `disconnected` listeners have been called.
        ref.current.room = null;
        window.removeEventListener('beforeunload', disconnect);
      });
      const events = [
        'disconnected',
        'dominantSpeakerChanged',
        'participantConnected',
        'participantDisconnected',
        'participantReconnected',
        'participantReconnecting',
        'reconnected',
        'reconnecting',
        'recordingStarted',
        'recordingStopped',
        'trackDimensionsChanged',
        'trackDisabled',
        'trackEnabled',
        'trackMessage',
        'trackPublished',
        'trackPublishPriorityChanged',
        'trackStarted',
        'trackSubscribed',
        'trackSubscriptionFailed',
        'trackSwitchedOff',
        'trackSwitchedOn',
        'trackUnpublished',
        'trackUnsubscribed',
      ];
      _.map(events, (event) => {
        room.on(event, (...args) => {
          console.log('event triggered', event);
        });
      });

      ref.current.$isConnecting(false);
      // Add a listener to disconnect from the room when a user closes their browser
      window.addEventListener('beforeunload', disconnect);
    } catch (err) {
      console.log('connect room error', err);
    }
  };

  React.useEffect(() => {
    ref.current.$isConnecting(true);
    connect();
    return () => {
      disconnect();
      ref.current = {};
    };
  }, []);

  if (!ref.current.room) return null;
  return (
    <DIV>
      {ctx.debug(() => {
        ctx.apply('REF.setRef', 'roomCallRef', ref);
      })}
      {children}
    </DIV>
  );
};

OTSession.Context = OTSessionContext;

export const useRoom = () => {
  const roomCallRef = getREF().hookRef('roomCallRef');
  const room = _.get(roomCallRef, 'current.room');
  return room;
};

OTSession.useRoom = useRoom;

export default OTSession;
