import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import Button from '@uz/unitz-components-web/Button';
import AudioSettingsMenu from '@uz/unitz-components-web/AudioSettingsMenu';
import VideoSettingsMenu from '@uz/unitz-components-web/VideoSettingsMenu';
import _ from 'lodash';
import styled from 'styled-components';
import Link from '@uz/unitz-components-web/Link';
import { Dropdown } from 'antd';

const ControlButton = ({ className, icon, text, horizontal, disable, onClick, ...rest }) => {
  return (
    <div
      {...rest}
      onClick={disable ? null : onClick}
      className={cx(
        `flex items-center justify-center cursor-pointer text-white500 ${className}`,
        { 'flex-col': !horizontal },
        { 'opacity-50': disable }
      )}
    >
      {icon}
      {!!text && <div className="mb-0 font-light text-normal">{text}</div>}
    </div>
  );
};

const ButtonGroups = styled.div`
  border-radius: 24px;
  background: ${gstyles.colors.black400};
  button {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn-text {
    padding-right: 2px;
  }
  .ant-dropdown-trigger {
    padding-left: 2px;
    padding-right: 2px;
    width: 30px;
  }
  > div.share-screen {
    button {
      padding-right: 12px;
      &.ant-btn {
        svg {
          filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(158deg) brightness(92%) contrast(119%) !important;
        }
      }
    }
  }
  > div.leave-room {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
  }
`;

const View8 = () => {
  return (
    <DIV className="videoCallConnecting">
      <section className={cx('h-full', 'z-10 flex justify-between items-center w-full px-4 py-4 relative')}>
        <div className="flex items-center justify-center p-0 space-x-2 absolute inset-0">
          <ButtonGroups className="my-4 flex items-center justify-center divide-x divide-gray-300">
            <div className="px-1">
              <Dropdown.Button
                overlay={() => <AudioSettingsMenu />}
                placement="topCenter"
                trigger="click"
                onClick={() => {
                  ctx.apply('videoCallModel.toggleMic');
                }}
                getPopupContainer={() => document.getElementById('room-call-content-body') || document}
                icon={gstyles.icons({
                  name: 'arrow-up',
                  fill: gstyles.colors.white,
                  size: 24,
                })}
                type="text"
              >
                {gstyles.icons({
                  name: ctx.apply('videoCallModel.useState', 'myState.micMute') ? 'micro-off' : 'micro',
                  fill: gstyles.colors.white,
                  size: 24,
                })}
              </Dropdown.Button>
            </div>
            <div className="px-1">
              <Dropdown.Button
                overlay={() => <VideoSettingsMenu />}
                placement="topCenter"
                trigger="click"
                onClick={() => {
                  ctx.apply('videoCallModel.toggleVideo');
                }}
                getPopupContainer={() => document.getElementById('room-call-content-body') || document}
                icon={gstyles.icons({
                  name: 'arrow-up',
                  fill: gstyles.colors.white,
                  size: 24,
                })}
                type="text"
              >
                {gstyles.icons({
                  name: ctx.apply('videoCallModel.useState', 'myState.videoMute') ? 'videocam-off' : 'videocam',
                  fill: gstyles.colors.white,
                  size: 24,
                })}
              </Dropdown.Button>
            </div>
            <div className="share-screen px-1">
              <Button
                onClick={() => {
                  ctx.apply('videoCallModel.toggleShareScreen');
                }}
                type="text"
                loading={false}
              >
                {gstyles.icons({
                  name: ctx.apply('videoCallModel.useState', 'shareScreen') ? 'stop-share' : 'share-screen',
                  fill: gstyles.colors.white,
                  size: 24,
                })}
              </Button>
            </div>
            {/* <div className="leave-room px-1">
              <Link to={'/'}>
                <div className="rounded-full flex justify-center items-center w-8 h-8 bg-red500">
                  {gstyles.icons({
                    name: 'logout',
                    fill: gstyles.colors.white500,
                    size: 16,
                  })}
                </div>
              </Link>
            </div> */}
          </ButtonGroups>
        </div>
        <div className="flex items-center h-full justify-center absolute inset-y left-0 pl-4">
          <ControlButton
            icon={gstyles.icons({
              name: 'message',
              fill: gstyles.colors.white500,
              size: 24,
              className: 'mb-2',
            })}
            onClick={() => {
              const messageControlModelRef = ctx.apply('REF.getRef', 'messageControlModelRef');
              if (messageControlModelRef) {
                messageControlModelRef.toggle();
              }
            }}
            text={ctx.apply('i18n.t', 'VideoCallPage.SectionAction.message')}
          />
        </div>
        <div className="flex items-center h-full justify-center absolute inset-y right-0 pr-4"></div>
      </section>
    </DIV>
  );
};

export default displayName(View8);
