import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import MessageStore from '@uz/unitz-tool-components/Message/Store';

export const Edited = ({ message_id }) => {
  const message = MessageStore.get(message_id);
  if (_.get(message, 'created_at') !== _.get(message, 'updated_at')) {
    return (
      <DIV>
        <span className="mx-1 text-gray-400">{ctx.apply('i18n.t', 'Message.messageStatus.edited')}</span>
      </DIV>
    );
  }
  return null;
};

export const Removed = () => {
  return (
    <DIV>
      <span className="mx-1 text-gray-400">{ctx.apply('i18n.t', 'Message.messageStatus.removed')}</span>
    </DIV>
  );
};

export const MessageStatus = { Edited, Removed };
export default MessageStatus;
