import React from 'react';
import cx from 'classnames';

const ControlButton = ({ className, icon, text, horizontal, disable, onClick, ...rest }) => {
  return (
    <div
      {...rest}
      onClick={disable ? null : onClick}
      className={cx(
        className,
        'flex items-center justify-center cursor-pointer text-white500 w-11',
        { 'flex-col': !horizontal },
        { 'opacity-50': disable }
      )}
    >
      {icon}
      {/* {!!text && <div className="mb-0 font-light text-normal text-xs">{text}</div>} */}
    </div>
  );
};

export default ControlButton;
