import React from 'react';
import _ from 'lodash';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import Draggable from 'react-draggable'; // Both at the same time
import styled from 'styled-components';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import elementResizeDetectorMaker from 'element-resize-detector';
import MarkerItem from './Items/Marker';
import EraserItem from './Items/Eraser';
import TextItem from './Items/Text';
import StickyNoteItem from './Items/StickyNote';
import PictureItem from './Items/Picture';
import DragItem from './Items/Drag';
import MoveItem from './Items/Move';
import MoreItem from './Items/More';
import UndoItem from './Items/Undo';
import RedoItem from './Items/Redo';
import useForceUpdate from '@vl/hooks/useForceUpdate';

const DraggableHandle = styled.div``;

const BASE_SLIDE_WIDTH = 192;
const paddingX = 16;
const paddingY = 16;
const TOP_PADDING = paddingY * 4;
const HANDLE_HEIGHT = paddingY * 2;
const HANDLE_WIDTH = paddingX * 4;

const MenuContainer = styled.div`
  & > .ant-btn-group {
    border: 1px solid ${gstyles.colors.white200} !important;
    border-radius: 8px;
    background-color: ${gstyles.colors.black300};
  }
  .tool-group {
    border-color: ${gstyles.colors.white200} !important;
    background-color: ${gstyles.colors.black200};
    height: 32px;
    & > .ant-btn-group {
      &:not(:first-child):not(:last-child) {
        & > .ant-btn {
          border-radius: 0 !important;
          border: none;
        }
      }
      &:last-child {
        & > .ant-btn:not(:last-child) {
          border-radius: 0 8px 8px 0 !important;
        }
        & > .ant-btn:last-child {
          display: none;
        }
      }
      &:first-child {
        & > .ant-btn:not(:last-child) {
          border-radius: 8px 0 0 8px !important;
        }
      }
      &:not(:first-child) {
        border-left: 1px solid ${gstyles.colors.white200};
      }
      &:not(:last-child) {
        border-right: 1px solid ${gstyles.colors.white200};
      }
    }
  }
`;

const Index = ({ canvas, name }) => {
  const ref = React.useRef();
  const [posState, $posState] = useLocalStorage(`@UZ::Whiteboard::MainToolbarPos::${name}`, {
    x: 0,
    y: 0,
  });

  const refData = React.useRef({
    erd: elementResizeDetectorMaker({}),
  });

  const forceUpdate = useForceUpdate(1000);

  React.useEffect(() => {
    if (ref.current && canvas.htmlEle) {
      ref.current.parentElement.style.left = '50%';
      ref.current.parentElement.style.top = '100%';
      const isPdfViewer = ref.current.parentElement?.parentElement?.classList?.contains('gslide-external');
      if (name === 'main_room-stream-slides' || isPdfViewer) {
        ref.current.parentElement.style.transform = 'translate(-50%, -120%)';
      } else {
        ref.current.parentElement.style.transform = 'translate(-50%, 2%)';
      }
    }
  }, []);

  React.useEffect(() => {
    const eleRef = document.querySelector('#room-call-content-body');
    _.get(refData, 'current', {}).erd.listenTo(eleRef || [], (element) => {
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      const containerRect = { width, height };
      if (!refData.current.containerRect || !_.isEqual(containerRect, refData.current.containerRect)) {
        refData.current.containerRect = containerRect;
        forceUpdate();
      }
    });
  });

  const bounds = {
    left: -(_.get(refData.current.containerRect, 'width', BASE_SLIDE_WIDTH) - BASE_SLIDE_WIDTH - HANDLE_WIDTH) / 2,
    right: (_.get(refData.current.containerRect, 'width', BASE_SLIDE_WIDTH) - BASE_SLIDE_WIDTH - HANDLE_WIDTH) / 2,
    top: -(_.get(refData.current.containerRect, 'height', BASE_SLIDE_WIDTH) - HANDLE_HEIGHT - TOP_PADDING),
    bottom: -1,
  };

  return (
    <Draggable
      axis="both"
      handle=".drag-handle"
      // defaultPosition={posState}
      positionOffset={{ x: 0, y: 0 }}
      position={null}
      bounds={bounds}
      grid={[8, 8]}
      scale={1}
      onStop={(evt, data) => {
        // console.log(data);
        $posState(_.pick(data, ['x', 'y']));
      }}
    >
      <MenuContainer
        className="flex flex-row space-x-4 justify-center bg-black300 p-2 rounded-xl items-center"
        ref={ref}
      >
        <DraggableHandle className="drag-handle flex justify-center items-center cursor-move">
          {gstyles.icons({ name: 'drag-indicator', size: 20, fill: gstyles.colors.white500 })}
        </DraggableHandle>
        <div className="tool-group flex flex-row border border-white200 rounded-lg">
          <TextItem canvas={canvas} />
          <MarkerItem canvas={canvas} />
          <EraserItem canvas={canvas} />
          {!canvas.isOverlay && <DragItem canvas={canvas} />}
          {canvas.isOverlay && <MoveItem canvas={canvas} />}
        </div>
        <StickyNoteItem canvas={canvas} />
        <PictureItem canvas={canvas} />
        <MoreItem canvas={canvas} />
        <div className="tool-group flex flex-row border border-white200 rounded-lg">
          <UndoItem canvas={canvas} />
          <RedoItem canvas={canvas} />
        </div>
      </MenuContainer>
    </Draggable>
  );
};

export default displayName(Index);
