import React from 'react';
import _ from 'lodash';

import UserProfileModelFormatter from '@uz/unitz-models/UserProfileModel/formatter';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import { Avatar } from '@uz/unitz-components-web/Avatar';

export const MessageHeader = ({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const author = _.get(message, 'author');
  if (!author) return null;

  return (
    <div className="flex justify-start items-center">
      <Avatar
        className="object-cover"
        size={32}
        alt={`mess_${message_id}`}
        src={UserProfileModelFormatter.avatarUrl()({ profile: author })}
        shape="circle"
      />
    </div>
  );
};

export default MessageHeader;
