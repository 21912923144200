import _ from 'lodash';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';

let __canvas = null;

const config = (fabric) => {
  fabric.Picture = fabric.util.createClass(fabric.Rect, {
    type: 'Picture',
    text: '',
    imgSrc: '',
    paddingX: 0,
    paddingY: 0,
    lockScalingX: false,
    lockScalingY: false,
    showAuthor: true,
    _prevObjectStacking: null,
    _prevAngle: 0,

    recalcTextPosition() {
      // const fill = _.get(backgroundColorMap, _.get(this, 'colorType'), _.get(this, 'colorType')) || '#F5BFB1';
      // const fill = 'transparent';
      const fill = 'black';
      const sin = Math.sin(fabric.util.degreesToRadians(this.angle));
      const cos = Math.cos(fabric.util.degreesToRadians(this.angle));
      const newLeft = cos * this.paddingX - sin * this.paddingY;
      const rectLeftTop = this.getPointByOrigin('left', 'top');
      const rectHeight = this.height * this.scaleY;
      const rectWidth = this.width * this.scaleX;

      const containerProps = {
        fill,
        stroke: fill,
      };
      this.set(containerProps);
      this.setCoords();

      const authorText = this.getAuthorText();
      if (authorText) {
        const authorTextHeight = 12;
        const authorTextProps = {
          left: rectLeftTop.x + newLeft,
          top:
            rectLeftTop.y +
            sin * (rectWidth - this.paddingX - authorTextHeight) +
            cos * (rectHeight - this.paddingY - authorTextHeight),
          ...(this.showAuthor ? { opacity: 1 } : { opacity: 0 }),
        };
        authorText.set(authorTextProps);
        authorText.setCoords();
      }

      if (this.width && this.height && this.picture) {
        this.fitPicture();
      }
    },

    setSrc(src) {
      const picture = this.getPicture();
      if (picture && picture.imgSrc !== src) {
        this.imgSrc = src;
        picture.imgSrc = src;
        if (picture) {
          picture.setSrc(src, (myImg) => {
            const _orgWidth = myImg.width;
            const _orgHeight = myImg.height;
            picture.set({
              _orgWidth,
              _orgHeight,
            });
            this.fitPicture();
            const canvas = this.getCanvas();
            canvas && canvas.fire('object:modified', { target: this });
          });
        }
      }
    },

    fitPicture() {
      const picture = this.getPicture();
      if (picture) {
        const _orgWidth = picture._orgWidth;
        const _orgHeight = picture._orgHeight;
        if (!_orgHeight || !_orgHeight) {
          return;
        }
        const scaleX = (this.width * this.scaleX) / _orgWidth;
        const scaleY = (this.height * this.scaleY) / _orgHeight;
        const attr = {
          left: this.left,
          top: this.top,
          angle: this.angle,
          scaleX,
          scaleY,
        };
        picture.set(attr);
        picture.setCoords();
        const canvas = this.getCanvas();
        canvas && canvas.renderAll();
      }
    },

    getCanvas(evt) {
      const canvas = (() => {
        let rtn;
        rtn = _.get(evt, 'target.canvas');
        if (rtn) return rtn;
        if (this.canvas) {
          return this.canvas;
        }
        if (__canvas) {
          return __canvas;
        }
      })();
      __canvas = canvas;
      return canvas;
    },
    getPicture() {
      const canvas = this.getCanvas();
      let picture = this.picture;
      if (canvas) {
        picture = _.find(canvas.getObjects(), { id: `${this.id}_3` }) || picture;
        this.picture = picture;
      }
      return picture;
    },
    getAuthorText() {
      return null;
      const canvas = this.getCanvas();
      let authorText = this.authorText;
      if (canvas) {
        authorText = _.find(canvas.getObjects(), { id: `${this.id}_2` }) || authorText;
        this.authorText = authorText;
      }
      return authorText;
    },
    getInstance(options) {
      const canvas = this.getCanvas();
      let instance;
      if (canvas) {
        instance = _.find(canvas.getObjects(), { id: `${options.id}` });
      }
      return instance;
    },
    bringToFront() {
      const canvas = this.getCanvas();
      if (canvas) {
        // const picture = this.getPicture();
        // const authorText = this.getAuthorText();
        // canvas?.bringToFront?.(this);
        // picture && canvas?.bringToFront?.(picture);
        // authorText && canvas?.bringToFront?.(authorText);
      }
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        ..._.pick(this, [
          'paddingX',
          'paddingY',
          'strokeWidth',
          'charSpacing',
          'fontSize',
          'colorType',
          'textAlign',
          'showAuthor',
          'imgSrc',
        ]),
      });
    },

    initialize(options, object) {
      options = options || object || {};
      this.callSuper('initialize', options);
      if (!this.id) {
        this.id = _.uniqueId(`${this.owner_id || ''}${Date.now()}`);
      }
      // this.lockUniScaling = true;
      // this.setControlsVisibility({
      //   mt: false,
      //   mb: false,
      //   ml: false,
      //   mr: false,
      //   bl: false,
      //   br: false,
      //   tl: false,
      //   tr: false,
      // });
      const canvas = this.getCanvas();

      // const authorText = '';
      // const authorColor = '#7F7F7F';
      // this.authorText = new fabric.Text(`${authorText}`, {
      //   fontSize: 12,
      //   charSpacing: 10,
      //   strokeWidth: 1,
      //   text: authorText,
      //   fill: authorColor,
      //   stroke: authorColor,
      //   selectable: false,
      //   evented: false,
      //   isLocal: true,
      //   id: `${this.id}_2`,
      // });
      const picture = this.getPicture();
      const instance = this.getInstance(options);
      if (this.width && this.height && !picture && !instance) {
        const imgSrc = this.imgSrc || 'https://via.placeholder.com/300.png';
        // const imgSrc = this.imgSrc;
        fabric.Image.fromURL(imgSrc, (myImg) => {
          const _orgWidth = this._orgWidth || myImg.width;
          const _orgHeight = this._orgHeight || myImg.height;
          const img1 = myImg.set({
            _orgWidth,
            _orgHeight,
            selectable: false,
            evented: false,
            isLocal: true,
            id: `${this.id}_3`,
            imgSrc,
          });
          this.picture = img1;
          const canvas = this.getCanvas();
          canvas && canvas.add(this.picture);
          this.fitPicture();
          // if (imgSrc !== this.imgSrc) {
          //   this.picture.setSrc(this.imgSrc);
          // }
        });
      }
      if (picture?.imgSrc !== this.imgSrc) {
        this.setSrc(this.imgSrc);
      }

      UserIdentity.getDisplayName(this.owner_id).then((val) => {
        const authorText = this.getAuthorText();
        authorText && authorText.set({
          text: `${val}`,
        });
      });

      this.recalcTextPosition();
      this.on('moving', () => {
        this.recalcTextPosition();
      });
      this.on('rotating', () => {
        const authorText = this.getAuthorText();
        authorText && authorText.rotate(authorText.angle + this.angle - this._prevAngle);
        this.recalcTextPosition();
        this._prevAngle = this.angle;
      });
      this.on('scaling', () => {
        this.recalcTextPosition();
      });
      this.on('added', function(evt) {
        const canvas = this.getCanvas(evt);
        if (!canvas) return;
        const authorText = this.getAuthorText();
        authorText && canvas.add(authorText);
      });
      this.on('removed', function(evt) {
        const canvas = this.getCanvas(evt);
        const picture = this.getPicture();
        const authorText = this.getAuthorText();
        if (!canvas) return;
        authorText && canvas.remove(authorText);
        picture && canvas.remove(picture);
      });
      this.on('mousedown:before', function(evt) {
        const canvas = this.getCanvas(evt);
        if (!canvas) return;
        this._prevObjectStacking = canvas.preserveObjectStacking;
        canvas.preserveObjectStacking = true;
      });
      // this.on('deselected', function(evt) {
      //   const canvas = getCanvas(evt);
      //   if (canvas) {
      //     // canvas.preserveObjectStacking = this._prevObjectStacking;
      //   }
      // });
      this.on('selected', function() {
        this.bringToFront();
        this.recalcTextPosition();
      });

      this.on('modified', function() {
        this.recalcTextPosition();
      });
      this.on('object:modified', function() {
        this.recalcTextPosition();
      });
    },
  });

  fabric.Picture.fromObject = function(object, callback) {
    return fabric.Object._fromObject('Picture', object, callback, {});
  };
};

export default config;
