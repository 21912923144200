import { BaseModel } from '@uz/unitz-models/BaseModel';
import _ from 'lodash';
import { GqlBuilder } from '@unitz/gqlbuilder';
import moment from 'moment';

const TIMESTAMPTZ_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS+00:00';
const WINDOW_CACHE_IN_SECOND = 60;

// eslint-disable-next-line import/prefer-default-export
export default class PaginationCalendarModel extends BaseModel {
  state = {
    items: [],
    page: 0,
    isLoading: false,
    isLoadMore: false,
    hasLoadMore: true,
    total: 0,
  };

  constructor(...args) {
    super(...args);
    this.constructor.initState(this);
    this.totalPage = 0;
    this.dataLoader = null;
    this.config = {};
    this.windows = {};
  }

  static fromConfig(config = {}) {
    let { dataQuery, Model, varsList, getVars } = config;
    const $now = moment().utc();
    const instance = new PaginationCalendarModel();
    const buildPageQuery = _.memoize(() => {
      const pageQuery = GqlBuilder.from(`
        query PaginationQuery($window_start_at: timestamptz, $window_end_at: timestamptz ${
          varsList ? `,${varsList}` : ''
        }) {
          ${dataQuery}
        }
      `);
      pageQuery.update({
        alias: 'items',
      });
      return pageQuery;
    });

    instance.dataLoader = async (opts) => {
      instance.config = config;
      instance.$baseDate = $now.clone().startOf('day');

      const page = _.get(opts, 'page');
      const windowKey = page || instance.mapDateToWindowKey();
      const [window_start_at, window_end_at] = _.split(windowKey, ';');
      const windowData = instance.ensureWindowKey(windowKey);

      if (config.subscription && !windowData.subscription) {
        instance.setState({ isLoading: true });
        const pageQuery = buildPageQuery();
        const client = await Model.getClient();
        const vars = _.isFunction(getVars) ? getVars(opts) : {};
        try {
          windowData.subscription = await new Promise((res) => {
            const ref = {};
            ref.subs = client
              .subscribe(pageQuery.setOperation('subscription').toString(), {
                ...vars,
                window_start_at,
                window_end_at,
              })
              .subscribe({
                next({ data }) {
                  if (!ref.inited) {
                    res(ref.subs);
                    ref.inited = true;
                  }
                  if (data) {
                    windowData.items = _.get(data, 'items');
                    windowData.itemsCount = _.get(data, 'items_agg.aggregate.count') || 0;
                    windowData.$last_fetch_at = moment();
                    const items = instance.getWindowItems();
                    instance.setState({
                      items,
                      total: items.length,
                      page,
                    });
                  }
                },
                error(err) {
                  console.log(`err: ${pageQuery.toString()}`, err);
                },
              });
          });
        } catch (err) {
          console.log(`err: ${pageQuery.toString()}`, err);
        }
        instance.setState({ isLoading: false });
      } else if (moment().diff(windowData.$last_fetch_at, 'second', true) > WINDOW_CACHE_IN_SECOND) {
        instance.setState({ isLoading: true });
        const pageQuery = buildPageQuery();
        const client = await Model.getClient();
        const vars = _.isFunction(getVars) ? getVars(opts) : {};
        try {
          const queryRes = await client.request(pageQuery.toString(), {
            ...vars,
            window_start_at,
            window_end_at,
          });

          windowData.items = _.get(queryRes, 'items', []);
          windowData.itemsCount = _.get(queryRes, 'items_agg.aggregate.count') || 0;
          windowData.$last_fetch_at = moment();
        } catch (err) {
          console.log(`err: ${pageQuery.toString()}`, err);
        }
        instance.setState({ isLoading: false });
      }

      const items = instance.getWindowItems();
      return {
        items,
        total: items.length,
        page,
      };
    };
    return instance;
  }

  async onRefresh() {
    await this.onLoadPage(0);
  }

  async onLoadMore() {
    try {
      const hasNextPage = this.useState('hasNextPage');
      if (!hasNextPage) return;

      const items = this.getState('items');
      const page = this.getState('page');
      const newPage = page + 1;
      const res = await this.dataLoader({ page: newPage });

      this.setState({
        ...res,
        items: _.uniqBy([...items, ...res.items], (item) => _.get(item, 'id')),
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      this.setState({
        isLoadMore: false,
      });
    }
  }

  async onLoadPage(page) {
    try {
      const isLoading = this.useState('isLoading');
      if (isLoading) {
        return;
      }

      const res = await this.dataLoader({ page });
      this.setState({
        ...res,
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  onLoadPageHandler(newPage) {
    this.onLoadPage(newPage);
  }

  onNavigate(toDate) {
    const windowKey = this.mapDateToWindowKey(toDate);
    this.onLoadPage(windowKey);
  }

  mapDateToWindowKey(date) {
    const config = this.config;
    const $baseDate = this.$baseDate.clone();
    const [size_amount, size_unit] = config.windowSize;
    const $date = moment(date)
      .utc()
      .clone()
      .startOf('day')
      .add(1, 'minute');
    const diff = $date.diff($baseDate, size_unit, true);
    let windowStartDiff = _.floor(diff / size_amount) * size_amount;
    if (!windowStartDiff) {
      windowStartDiff = 0 - size_amount;
    }
    let windowEndDiff = _.ceil(diff / size_amount) * size_amount;
    if (!windowEndDiff) {
      windowEndDiff = size_amount;
    }

    const $windowStart = $baseDate.clone().add(windowStartDiff, size_unit);
    const $windowEnd = $baseDate.clone().add(windowEndDiff, size_unit);
    const windowKey = `${$windowStart.format(TIMESTAMPTZ_FORMAT)};${$windowEnd.format(TIMESTAMPTZ_FORMAT)}`;
    return windowKey;
  }

  ensureWindowKey(key) {
    if (!_.has(this.windows, [key])) {
      _.set(this.windows, [key], {
        items: [],
        $last_fetch_at: moment()
          .subtract(1, 'day')
          .utc(),
      });
    }
    return _.get(this.windows, [key]);
  }

  getWindowItems() {
    let rtn = [];
    for (let windowKey in this.windows) {
      rtn = rtn.concat(_.get(this.windows[windowKey], 'items', []));
    }
    return rtn;
  }
}
