import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Select } from 'antd';
import displayName from '@vl/redata/displayName.macro';
import { useDeferCloseSelect } from '../hooks';

const StyledSelect = styled.div`
  .rc-virtual-list-holder-inner {
    flex-direction: row !important;
    justify-content: center;
    .ant-select-item-option {
      width: 50%;
      padding: 4px;
    }
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .option-label {
      width: 16px;
      height: 16px;
    }
  }
  .ant-select-dropdown {
    padding: 0px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${gstyles.colors.white200};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${gstyles.colors.white400};
  }
`;

const listToIconMap = {
  bullet: 'list-bullet',
  number: 'list-bullet',
};

const createOption = (value) => {
  return {
    value,
    label: (
      <div className="flex justify-center items-center w-8 h-6 option-label">
        {gstyles.icons({
          name: _.get(listToIconMap, value),
          size: 18,
          fill: gstyles.colors.black400,
        })}
      </div>
    ),
  };
};

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });

  const getValue = React.useCallback(() => {
    const type = _.get(ref.current.target, 'type');
    if (['list'].includes(type)) {
      return ref.current.target.get('textAlign');
    }
  }, [ref.current.target]);

  const applicableTarget = React.useMemo(() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    const type = _.get(ref.current.target, 'type');
    if (['list'].includes(type)) {
      return true;
    }
  }, [ref.current.target]);

  const [value, $value] = React.useState(getValue);

  _.assign(ref.current, { value, $value });

  const onChange = React.useCallback(
    (val) => {
      ref.current.$value && ref.current.$value(val);
      _onChange && _onChange(value);

      const type = _.get(ref.current.target, 'type');
      if (['list'].includes(type)) {
        ref.current.target.set({
          textAlign: val,
        });
        canvas && canvas.updateObject(ref.current.target);
      }
    },
    [ref.current.target]
  );

  React.useEffect(() => {
    const val = getValue();
    if (ref.current.value !== val) {
      ref.current.$value(val);
    }
  }, [ref.current.target]);
  const openProps = useDeferCloseSelect();

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Select
        dropdownAlign={{
          offset: [-40, -68],
        }}
        getPopupContainer={(node) => node}
        size="small"
        dropdownMatchSelectWidth={false}
        dropdownClassName="flex w-20"
        options={[createOption('bullet'), createOption('number')]}
        placeholder={_.get(createOption('bullet'), 'label', null)}
        value={value}
        onChange={onChange}
        {...rest}
        {...openProps}
      />
    </StyledSelect>
  );
};

export default displayName(Index);
