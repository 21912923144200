import _ from 'lodash';
import GundbClient from '@vl/mod-clients/gundb';
import { getLeadId } from '@uz/unitz-tool-components/Collab/Events/Lead';

import {
  getOwnerId,
  toCanvasPayload,
  addObjectsToCanvas,
  fromCanvasPayload,
  syncObjectsToCanvas,
  syncCanvasPayload,
  toObjectPayload,
  fromObjectPayload,
  ensureHtmlCanvas,
  configFitZoom,
} from './utils';


export const trackKey = (canvas, userId) => {
  const { sessionId } = canvas;
  const getCanvas = () => canvas;
  _.update(canvas, `trackMap.${userId}`, (node) => {
    if (node) return node;
    const docRefKey = `whiteboard_${sessionId}_${userId}`;
    const eventRefKey = `whiteboard_event_date_${sessionId}_${userId}`;
    const docRef = GundbClient.getClient().getDoc(docRefKey);
    const eventRef = GundbClient.getClient().getDateTree(eventRefKey);

    console.log({ docRefKey, eventRefKey });

    let loadedDoc = false;
    const canvasDataRef = { item: null };
    const requestRenderCanvas = _.throttle(() => {
      const item = _.get(canvasDataRef, 'item');
      item && canvas && syncCanvasPayload(canvas, _.get(item, 'payload'), userId);
    }, 1000);

    if (docRef) {
      docRef.on((item) => {
        _.set(canvasDataRef, 'item', item);
        if (!loadedDoc) {
          canvas && fromCanvasPayload(canvas, _.get(item, 'payload'));
          loadedDoc = true;
        }
        requestRenderCanvas();

        const id = _.get(item, 'id');
        // const action = _.get(item, 'action');
        if (id === getLeadId()) {
          // console.log('action', action);
        }
      });
    }

    if (eventRef) {
      eventRef.onEvent((item) => {
        const id = _.get(item, 'id');
        const action = _.get(item, 'action');
        if (!loadedDoc) return;

        if (['text:changed', 'object:moving'].includes(action)) {
          const obj = fromObjectPayload(item.payload);
          const objectId = _.get(obj, 'id');
          const canvas = getCanvas();
          if (canvas && objectId) {
            canvas.getObjects().forEach((item) => {
              if (item.id === objectId) {
                item.set(obj);
                // item.setCoords();
                item.fire('object:modified');
                canvas.renderAll();
              }
            });
          }
        }

        if (['marker:down'].includes(action)) {
          const obj = fromObjectPayload(item.payload);
          const canvas = getCanvas().collab;
          const marker = new window.fabric.Polyline(_.get(obj, '_points', []), {
            stroke: obj.color,
            strokeWidth: obj.width,
            fill: '',
            objectCaching: false,
          });
          marker.set({ id: obj.id });
          canvas.add(marker);
        }
        if (['marker:up'].includes(action)) {
          const obj = fromObjectPayload(item.payload);
          const canvas = getCanvas().collab;
          const marker = canvas.findObject(obj);
          if (marker) {
            canvas.remove(marker);
          }
        }
        if (['marker:move'].includes(action)) {
          const obj = fromObjectPayload(item.payload);
          const canvas = getCanvas().collab;
          const marker = canvas.findObject(obj);
          if (marker) {
            marker.set({ points: _.get(obj, '_points', []) });
            marker.setCoords();
            canvas.renderAll();
          }
        }

        if (action === 'object:modified') {
          const obj = fromObjectPayload(item.payload);
          const objectId = _.get(obj, 'id');
          const canvas = getCanvas();
          if (canvas && objectId) {
            canvas.getObjects().forEach((item) => {
              if (item.id === objectId) {
                item.set(obj);
                item.setCoords();
                item.fire('object:modified');
                canvas.renderAll();
              }
            });
          }
        }
        if (action === 'object:added') {
          const obj = fromObjectPayload(item.payload);
          const objectId = _.get(obj, 'id');
          const canvas = getCanvas();

          if (canvas && objectId) {
            let found = false;
            canvas.getObjects().forEach((item) => {
              if (item.id === objectId) {
                found = item;
              }
            });
            if (found) {
              found.set(obj);
              found.setCoords();
              found.fire('object:added');
              canvas.renderAll();
            } else {
              addObjectsToCanvas(canvas, [obj]);
            }
          }
        }
        if (action === 'object:removed') {
          const obj = fromObjectPayload(item.payload);
          const objectId = _.get(obj, 'id');
          const canvas = getCanvas();
          if (canvas && objectId) {
            canvas.getObjects().forEach((item) => {
              if (item.id === objectId) {
                canvas.remove(item);
                canvas.renderAll();
              }
            });
          }
        }
        if (id === getLeadId()) {
          // console.log('action', action);
        }
      });
    }
    return () => {
      // dispoer to untrack key?
    };
  });
};
