import _ from 'lodash';

const config = (fabric) => {
  fabric.StaticCanvas.prototype.findById = function(id) {
    const items = this.getObjects();
    const found = _.find(items, { id });
    return found;
  };

  fabric.StaticCanvas.prototype.findObject = function(target) {
    const items = this.getObjects();
    const found = _.find(items, { id: target.id });
    return found;
  };
  fabric.StaticCanvas.prototype.removeAll = function() {
    this.remove(...this.getObjects());
    this.renderAll();
    this.clear();
  };
};

export default config;
