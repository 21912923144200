import React from 'react';
import _ from 'lodash';

import MessageModelFormatter from '@uz/unitz-models/MessageModel/formatter';
import MessageStore from '@uz/unitz-tool-components/Message/Store';

const MessageTimer = ({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const [ver, $ver] = React.useState(0);
  const ref = React.useRef({});
  _.assign(ref.current, { ver, $ver });
  React.useEffect(() => {
    ref.current.timer = setInterval(() => {
      ref.current.$ver && ref.current.$ver(ref.current.ver + 1);
    }, 1000 * 60);
    return () => {
      clearInterval(ref.current.timer);
      ref.current = {};
    };
  }, []);
  const created_at = _.get(message, 'created_at');
  if (!created_at) return null;
  return <div className="mx-2 text-xs font-normal text-sub">{MessageModelFormatter.formatCreatedAt()(message)}</div>;
};

export default MessageTimer;
