import styled from 'styled-components';
import Button from '@uz/unitz-components-web/Button';

const ActionButton = styled(Button)`
  &.ant-btn {
    padding: 2px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
  }
`;

export default ActionButton;
