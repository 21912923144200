import React from 'react';
import _ from 'lodash';

import MessageModelRenderer from '@uz/unitz-models/MessageModel/renderer';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import MessageHeader from '@uz/unitz-tool-components/Message/Components/MessageHeader';
import MessageStatus from '@uz/unitz-tool-components/Message/Components/MessageStatus';
import MessageReaction from '@uz/unitz-tool-components/Message/Components/MessageReaction';
import MessageActions from '@uz/unitz-tool-components/Message/Components/MessageActions';
import MessageTimer from '@uz/unitz-tool-components/Message/Components/MessageTimer';
import MessageAuthor from '@uz/unitz-tool-components/Message/Components/MessageAuthor';
import { Popover } from 'antd';

export const MessageReplyItem = React.memo(({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const containerRef = React.useRef();
  return (
    <Popover
      content={<MessageActions message_id={message_id} />}
      arrowContent={null}
      align={{
        points: ['br', 'tr'], // align dropdown bottom-left to top-left of input element
        offset: [-24, 32], // align offset
        overflow: {
          adjustX: 0,
          adjustY: 0, // do not auto flip in y-axis
        },
      }}
      trigger={['click', 'hover']}
      overlayClassName="message-item-tooltip"
      mouseEnterDelay={0.4}
      getPopupContainer={() => {
        if (containerRef.current) return containerRef.current;
        return document.body;
      }}
    >
      <div ref={containerRef} className="py-1 hover:bg-brandb-lightest relative">
        <div className="table-cell align-top">
          <MessageHeader message_id={message_id} />
        </div>
        <div className="table-cell align-top pl-3">
          <div className="flex items-center">
            <MessageAuthor message_id={message_id} />
            <MessageTimer message_id={message_id} />
          </div>

          <div>
            {_.get(message, 'deleted') ? (
              <MessageStatus.Removed />
            ) : (
              <>
                {MessageModelRenderer.renderMessage()(message)}
                <MessageStatus.Edited message_id={message_id} />
              </>
            )}
          </div>
          <div>
            <MessageReaction message_id={message_id} />
          </div>
        </div>
      </div>
    </Popover>
  );
});

export default MessageReplyItem;
