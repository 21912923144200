import _ from 'lodash';
import modConfig from '@vl/mod-config';
import { Howl } from 'howler';

const ensureSrc = (config) => {
  _.update(config, 'src', (src) => {
    const rtn = _.compact(_.castArray(src)).map((name) => {
      if (_.isString(name)) {
        return `https://unitz-host-asset.web.app/audio/${name}`;
      }
    });
    return rtn;
  });
  return config;
};

export const getClient = (config) => {
  config = ensureSrc(config);
  const sound = new Howl(config);
  return sound;
};

export default getClient;
