import React from 'react';
import _ from 'lodash';
import useForceUpdate from '@vl/hooks/useForceUpdate';

const STORE_MAP = new Map();

const useTravelStore = (name, config) => {
  if (!STORE_MAP.has(name)) {
    const store = (() => {
      const state = {
        stack: [],
        pointer: -1,
        isTraveling: false,
      };
      const listeners = new Set();
      const store = {
        set: (...args) => {
          if (args.length === 1) {
            _.merge(state, args[0]);
          } else {
            _.set(state, ...args);
          }
          // side effect on state change
          store.emit('changed');
        },
        get: (...args) => {
          if (args.length === 0) {
            return state;
          }
          return _.get(state, ...args);
        },
        reduce: (fn) => {
          const value = fn(store);
          const ref = React.useRef({});
          const forceUpdate = useForceUpdate(13);
          _.assign(ref.current, { value });
          if (ref.current.disposer) {
            ref.current.disposer();
            ref.current.disposer = null;
          }
          // auto bind listener
          if (!ref.current.disposer) {
            ref.current.disposer = store.on('changed', () => {
              const newValue = fn(store);
              const oldValue = ref.current.value;
              if (!_.isEqual(newValue, oldValue)) {
                ref.current.value = newValue;
                forceUpdate();
              }
            });
          }
          React.useEffect(() => {
            return () => {
              ref.current.disposer();
            };
          }, []);
          return ref.current.value;
        },
        emit: (evt) => {
          for (const listener of Array.from(listeners)) {
            listener(evt, store);
          }
        },
        on: (evt, listener) => {
          listeners.add(listener);
          return () => {
            listeners.delete(listener);
          };
        },
        do: (evt) => {
          if (!state.isTraveling) {
            state.pointer++;
            state.stack[state.pointer] = evt;
            const size = state.pointer + 1;
            const stackSize = state.stack.length;
            state.stack.splice(state.pointer + 1, stackSize - size);
            // side effect on state change
          }
          store.emit('changed');
        },
        undo: async (count = 1) => {
          for (let step = 0; step < count; step++) {
            state.isTraveling = true;
            const evt = state.stack[state.pointer];
            // mute do event while processing undo
            // process undo evt
            const { action } = evt;
            const handler = _.get(config, ['mappings', 'undo', action]);
            if (_.isFunction(handler)) {
              await handler(evt);
            }
            state.pointer = Math.max(-1, --state.pointer);
          }
          state.isTraveling = false;
          // side effect on state change
          store.emit('changed');
        },
        redo: async (count = 1) => {
          for (let step = 0; step < count; step++) {
            state.isTraveling = true;
            state.pointer = Math.min(state.stack.length - 1, ++state.pointer);
            const evt = state.stack[state.pointer];
            // process redo evt
            const { action } = evt;
            const handler = _.get(config, ['mappings', 'redo', action]);
            if (_.isFunction(handler)) {
              await handler(evt);
            }
          }
          state.isTraveling = false;
          // side effect on state change
          store.emit('changed');
        },
        getUndoCount: () => {
          return state.pointer + 1;
        },
        getRedoCount: () => {
          const stackSize = state.stack.length;
          const size = state.pointer + 1;
          return stackSize - size;
        },
      };
      return store;
    })();
    STORE_MAP.set(name, store);
  }
  const store = STORE_MAP.get(name);

  return [store];
};

export default useTravelStore;
