import _ from 'lodash';
import GundbClient from '@vl/mod-clients/gundb';

import {
  getOwnerId,
  toCanvasPayload,
  exportCanvasPayload,
  addObjectsToCanvas,
  fromCanvasPayload,
  syncObjectsToCanvas,
  syncCanvasPayload,
  toObjectPayload,
  fromObjectPayload,
  ensureHtmlCanvas,
  configFitZoom,
} from './utils';

import {
  trackKey
} from './track';

const pointerEventMap = {
  none: false,
  auto: true,
};

const pointerEventMap2 = {
  false: 'none',
  true: 'auto',
};

export const extendCanvas = (canvas) => {
  canvas.removeItem = (target) => {
    canvas.getObjects().forEach((item) => {
      if (item.id === _.get(target, 'id')) {
        canvas.remove(item);
        canvas.renderAll();
      }
    });
  };
  canvas.addItem = (target) => {
    addObjectsToCanvas(canvas, [target]);
  };

  canvas.updateItem = (target) => {
    const obj = target;
    const objectId = _.get(obj, 'id');
    if (canvas && objectId) {
      canvas.getObjects().forEach((item) => {
        if (item.id === objectId) {
          item.set(obj);
          item.setCoords();
          canvas.renderAll();
        }
      });
    }
  };

  canvas.canEditItem = (target) => {
    if (_.has(target, 'owner_id')) {
      const owner_id = getOwnerId();
      return [owner_id].includes(target.owner_id);
    }
    return true;
  };

  canvas.removeItems = (...targets) => {
    const items = _.filter(targets, canvas.canEditItem);
    // check permission
    canvas.remove(...items);
  };

  canvas.toggleOverlay = (...args) => {
    let newVal;
    const parentElement = _.get(canvas, 'htmlEle.parentElement');
    if (!parentElement) return;
    if (args.length) {
      newVal = !!args[0];
    } else {
      const pointerEvent = canvas.htmlEle.parentElement.style['pointer-events'];
      const oldVal = _.get(pointerEventMap, pointerEvent);
      newVal = !oldVal;
    }
    canvas.htmlEle.parentElement.style['pointer-events'] = _.get(pointerEventMap2, `${!newVal}`);
  };

  canvas.setMoveMode = (...args) => {
    let newVal;
    const parentElement = _.get(canvas, 'htmlEle.parentElement');
    if (!parentElement) return;
    if (args.length) {
      newVal = !!args[0];
    } else {
      const pointerEvent = canvas.htmlEle.parentElement.style['pointer-events'];
      const oldVal = _.get(pointerEventMap, pointerEvent);
      newVal = !oldVal;
    }
    canvas.isMoveMode = newVal;
    canvas.htmlEle.parentElement.style['pointer-events'] = _.get(pointerEventMap2, `${newVal}`);
  };

  canvas.toggleMoveMode = (...args) => {
    canvas.setMoveMode(...args);
    canvas.fire('mode:changed', {
      mode: 'move',
      source: 'move',
      value: canvas.isMoveMode,
    });
  };

  canvas.toggleEraserMode = (...args) => {
    let newVal;
    if (args.length) {
      newVal = !!args[0];
    } else {
      newVal = !canvas.isEraserMode;
    }
    canvas.isEraserMode = newVal;
  };
};

export const extendCanvasData = (canvas) => {
  canvas.saveCanvasPayload = (() => {
    const fns = {};
    const userId = getOwnerId();
    return (delay) => {
      delay = delay || 0;
      if (!fns[delay]) {
        fns[delay] = _.debounce(() => {
          canvas.docRef.put({
            id: userId,
            action: 'sync',
            payload: toCanvasPayload(canvas),
          });
        }, delay);
      }
      const fn = fns[delay];
      return fn();
    };
  })();

  canvas.exportCanvasPayload = () => {
    const payload = exportCanvasPayload(canvas);
    return payload;
  };

  canvas.importCanvasPayload = (payload) => {
    const userId = getOwnerId();
    const newPayload = {};
    _.map(payload, (item, key) => {
      const { owner_id, id } = item;
      if (owner_id && id) {
        const newKey = _.replace(key, owner_id, userId);
        const newId = _.replace(id, owner_id, userId);
        newPayload[newKey] = {
          ...item,
          id: newId,
          owner_id: userId,
        };
      }
    });
    fromCanvasPayload(canvas, newPayload);
    return payload;
  };

  canvas.ensureTarget = (target) => {
    if (!target.id) {
      target.set({
        id: canvas.genObjectId(),
      });
    }
    if (!_.has(target, 'owner_id')) {
      target.set({
        owner_id: getOwnerId(),
      });
    }
  };
};

export const extendCanvasRef = (canvas) => {
  const { sessionId } = canvas;
  const userId = getOwnerId();
  const docRefKey = `whiteboard_${sessionId}_${userId}`;
  const eventRefKey = `whiteboard_event_date_${sessionId}_${userId}`;

  const docRef = GundbClient.getClient().getDoc(docRefKey);
  const eventRef = GundbClient.getClient().getDateTree(eventRefKey);

  // setup listener
  const sessionRefKey = `sessions_${sessionId}`;
  const sessionRef = GundbClient.getClient().getDoc(sessionRefKey);
  sessionRef.map().on((item) => {
    const id = _.get(item, 'id');
    if (id === userId) return;
    trackKey(canvas, id);
  });
  if (userId) {
    sessionRef.get(userId).put({ id: userId, value: userId });
  }

  canvas.docRef = docRef;
  canvas.docRefKey = docRefKey;
  canvas.eventRef = eventRef;
  canvas.eventRefKey = eventRefKey;
  canvas.sessionRef = sessionRef;
};
