import React from 'react';

import { Progress } from 'antd';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const CountDownRecTooltip = ({ className, seconds, onTimer }) => {
  const [counter, $counter] = React.useState(seconds);
  const ref = React.useRef({ percent: 0 });
  _.assign(ref.current, { counter, $counter });

  React.useEffect(() => {
    ref.current.timer = setInterval(() => {
      ref.current.$counter(ref.current.counter - 1);
      if (ref.current.counter <= 0) {
        _.isFunction(onTimer) && onTimer();
      }
    }, 1000);
    return () => {
      clearInterval(ref.current.timer);
      ref.current = {};
    };
  }, [seconds]);
  return (
    <div className="flex items-center justify-center">
      {gstyles.icons({
        name: 'record-2',
        fill: gstyles.colors.red500,
        size: 16,
      })}
      <span className="mx-1">
        {`REC ${_.padStart(Math.floor(ref.current.counter / 60), 2, '0')}:${_.padStart(
          ref.current.counter % 60,
          2,
          '0'
        )}`}
      </span>
    </div>
  );
};

export default CountDownRecTooltip;
