import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import Combokeys from 'combokeys';
import { isActivateMode, isDeactivateMode } from '@uz/unitz-components-web/Fabric/helper';

import ColorInput from '../Inputs/Color';
import LineStyleInput from '../Inputs/LineStyle';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 2px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const { fabric } = window;
    const target = new fabric.Marker('', {});
    return target;
  });
  const ref = React.useRef({});
  _.assign(ref.current, {
    target,
    $target,
    canvas,
  });

  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return !!canvas.isDrawingMode;
  }, [canvas.isDrawingMode]);

  React.useEffect(() => {
    const updateMarkerState = () => {
      forceUpdate();
    };

    disposerCleaner(
      (() => {
        const combokeys = new Combokeys(window);

        combokeys.bind('esc', () => {
          if (canvas.isDrawingMode) {
            canvas.isDrawingMode = false;
            updateMarkerState();
            canvas.fire('mode:changed', {
              mode: 'marker',
              value: !!canvas.isDrawingMode,
              source: 'marker',
            });
          }
          return false;
        });
        return () => {
          combokeys.detach();
        };
      })()
    );

    disposerCleaner(
      canvas.$on('object:modified', ({ target }) => {
        const { type } = target;
        if (_.get(target, 'isToolbar')) {
          if (type === 'marker') {
            forceUpdate();
          }
        }
      })
    );

    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        if (isActivateMode(event, 'marker')) {
          canvas.isDrawingMode = true;
          ref.current.target.set({
            isDrawingMode: true,
            isToolbarActive: true,
          });
        } else if (isDeactivateMode(event, 'marker')) {
          canvas.isDrawingMode = false;
          ref.current.target.set({
            isDrawingMode: false,
            isToolbarActive: false,
          });
        }
        forceUpdate();
      })
    );
  }, []);

  React.useEffect(() => {
    if (canvas.isDrawingMode) {
      canvas.freeDrawingBrush.color = ref.current.target.stroke;
      canvas.freeDrawingBrush.width = ref.current.target.strokeWidth;
      const cursorSize = 16 + ref.current.target.strokeWidth * 1.5;
      gstyles.Icons.getCursor({
        name: 'cursor-pen',
        fill: ref.current.target.stroke,
        size: cursorSize,
      }).then((cursor) => {
        canvas.freeDrawingCursor = `url(${cursor}) 4 ${_.round(cursorSize)}, crosshair`;
      });
    }
  }, [canvas.isDrawingMode, ref.current.target.stroke, ref.current.target.strokeWidth]);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  return (
    <MenuItem
      type={canvas.isDrawingMode ? 'primary' : 'text'}
      getPopupContainer={(node) => node && node.parentElement}
      visible={ref.current.visible}
      trigger={['click']}
      onClick={() => {
        canvas.isDrawingMode = !canvas.isDrawingMode;
        canvas.fire('mode:changed', {
          mode: 'marker',
          source: 'marker',
          value: !!canvas.isDrawingMode,
        });
      }}
      overlay={() => {
        return (
          <div className="flex flex-row bg-white w-full">
            <div>
              <ColorInput
                target={ref.current.target}
                canvas={canvas}
                open
                dropdownAlign={{
                  offset: [-17, -62],
                }}
              />
            </div>
            <div>
              <LineStyleInput
                target={ref.current.target}
                canvas={canvas}
                open
                dropdownAlign={{
                  offset: [60, -62],
                }}
              />
            </div>
          </div>
        );
      }}
      buttonsRender={([leftButton, rightButton]) => [
        <Tooltip
          title={
            <div className="space-x-2">
              <span>{i18n.t('Whiteboard.toolbar.markerTooltip')}</span>
              <span className="">M</span>
            </div>
          }
          key="leftButton"
        >
          {leftButton}
        </Tooltip>,
        rightButton,
      ]}
      icon={gstyles.icons({ name: 'arrow-down', size: 16, fill: gstyles.colors.black50 })}
      placement="topLeft"
    >
      <span className="flex justify-center items-center">
        {gstyles.icons({
          name: 'draw',
          size: 16 + ref.current.target.strokeWidth * 1.5,
          fill: ref.current.target.stroke,
        })}
      </span>
    </MenuItem>
  );
};

export default displayName(Index);
