import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import useLocalTracks from '@vl/hooks/useLocalTracks';
import { ElementTrackMan, getStreamId } from '@vl/mod-utils/deviceInfoWeb';
import StreamLayout from '@uz/unitz-layout-web/LayoutRoomStream';

import OTPublisherActions from '@uz/unitz-components-web/RoomWebComponents/OTLocalPublisherActions';
import OTPublisherHeader from '@uz/unitz-components-web/RoomWebComponents/OTLocalPublisherHeader';

export const OTPublisher = ({ properties }) => {
  const [ver, $ver] = React.useState(0);
  const localTracks = useLocalTracks();
  const eleRef = React.useRef();
  const ref = React.useRef({});
  const videoInputDeviceIndex = _.get(properties, 'videoInputDeviceIndex');
  _.assign(ref.current, { videoInputDeviceIndex, ver, $ver, eleRef });

  const releaseTrack = (track) => {
    try {
      if (track) {
        track.stop();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const attachVideo = () => {
    eleRef.current && ElementTrackMan(eleRef.current).attachVideo(ref.current.videoTrack);
  };

  const startVideo = React.useCallback(async () => {
    const videoTrackPrev = ref.current.videoTrack;
    ref.current.videoTrack = await localTracks.getLocalVideoTrack(ref.current.videoInputDeviceIndex);

    // await setProcessor(ref.current.videoTrack);
    eleRef.current && ElementTrackMan(eleRef.current).attachVideo(ref.current.videoTrack);
    if (videoTrackPrev && videoTrackPrev?.name !== ref.current.videoTrack?.name) {
      releaseTrack(videoTrackPrev);
    }
    if (ref.current.videoTrack !== videoTrackPrev) {
      ref.current.$ver && ref.current.$ver(ref.current.ver + 1);
    }
  }, [ref, eleRef]);

  const stopVideo = React.useCallback(() => {
    releaseTrack(ref.current?.videoTrack);
    eleRef.current && ElementTrackMan(eleRef.current).attachVideo(null);
    try {
      ref.current.videoTrack = null;
    } catch (err) {}
  }, [ref, eleRef]);

  const startAudio = React.useCallback(async () => {
    const audioTrackPrev = ref.current.audioTrack;
    ref.current.audioTrack = await localTracks.getLocalAudioTrack();
    eleRef.current && ElementTrackMan(eleRef.current).attachAudios([ref.current.audioTrack]);
    if (audioTrackPrev && audioTrackPrev?.name !== ref.current.audioTrack?.name) {
      releaseTrack(audioTrackPrev);
    }
  }, [ref, eleRef]);

  const stopAudio = React.useCallback(() => {
    releaseTrack(ref.current?.audioTrack);
    eleRef.current && ElementTrackMan(eleRef.current).attachAudios([]);
    try {
      ref.current.audioTrack = null;
    } catch (err) {}
  }, [ref, eleRef]);

  const publishVideo = _.get(properties, 'publishVideo');
  const publishAudio = _.get(properties, 'publishAudio');

  React.useEffect(() => {
    if (publishVideo) {
      startVideo();
    } else {
      stopVideo();
    }
  }, [publishVideo, videoInputDeviceIndex]);

  React.useEffect(() => {
    if (publishAudio) {
      startAudio();
    } else {
      stopAudio();
    }
  }, [publishAudio, localTracks.audioInputDeviceIndex]);

  // cleanup effect
  React.useEffect(() => {
    return () => {
      stopVideo();
      stopAudio();
      if (ref.current.ctx) {
        ref.current.ctx.apply('REF.setRef', ref.current.stream_id, null);
      }
      ref.current = {};
    };
  }, []);

  const videoRefCb = React.useCallback((vidRef) => {
    eleRef.current = vidRef;
    // ref is mount, notice for update
    attachVideo();
  }, []);

  if (!ref.current.videoTrack) return null;

  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ref.current.stream_id = getStreamId([{ track: _.get(ref.current, 'videoTrack') }, null], ctx);
      })}
      <StreamLayout.POS name={`stream-videos.${ref.current.stream_id}`}>
        <div className="w-full h-full rounded-md">
          <video
            id={`OTPublisherMediaRef-${ref.current.videoInputDeviceIndex}`}
            ref={videoRefCb}
            style={_.pick(properties, ['width', 'height'])}
          />
        </div>
      </StreamLayout.POS>
      <StreamLayout.POS name={`stream-thumbs.${ref.current.stream_id}`}>
        <DIV forceCtx>
          {ctx.debug(() => {
            ref.current.ctx = ctx;
            ctx.set('@item', { stream_id: ref.current.stream_id });
            ctx.apply('REF.setRef', ref.current.stream_id, ref);
          })}
          <div className="OTPublisher">
            <div className="w-48 h-28 stream-item bg-transparent flex-shrink-0 p-1 rounded-md overflow-hidden">
              <div className="OTPublisher relative w-full h-full bg-gray-500 rounded-md overflow-hidden">
                <StreamLayout.RenderPOS name={`stream-videos.${ref.current.stream_id}`} />
                <OTPublisherHeader />
                <OTPublisherActions />
              </div>
            </div>
          </div>
        </DIV>
      </StreamLayout.POS>
    </DIV>
  );
};

export default OTPublisher;
