import Slider from 'react-slick';
import styled from 'styled-components';
import _ from 'lodash';

const StyledSlider = styled(Slider)`
  .slick-arrow.slick-next {
    position: absolute;
    right: 0px;
    ${(props) => `top: ${Math.floor(_.get(props, 'slideInfo.height') / 2)}px;`}
  }
  .slick-arrow.slick-prev {
    left: 0px;
    ${(props) => `top: ${Math.floor(_.get(props, 'slideInfo.height') / 2)}px;`}
  }
  .slick-list {
    ${(props) => {
      if (props.vertical) {
        return `
          margin: 0 auto;
          width: ${_.get(props, 'slideInfo.width')}px;
          height: ${_.get(props, 'containerStyle.height')}px !important;
        `;
      }
      return `
          margin: 0 ${_.get(props, 'slideInfo.paddingX')}px;
          height: ${_.get(props, 'slideInfo.height')}px;
          width: ${_.get(props, 'containerStyle.width')}px !important;
        `;
    }}
  }
  .slick-track {
    ${(props) => {
      if (props.vertical) {
        return `
          width: 100%;
        `;
      }
      return `
          height: 100%;
        `;
    }}
    .slick-slide {
      ${(props) => {
        if (props.vertical) {
          return `
            width: 100%;
          `;
        }
        return `
            height: 100%;
          `;
      }}
      .stream-item {
        ${(props) => {
          return `
            width: ${_.get(props, 'slideInfo.width')}px;
            height: ${_.get(props, 'slideInfo.height')}px;
          `;
        }}
      }
    }
  }
`;

export default StyledSlider;
