import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Select } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { useDeferCloseSelect } from '../hooks';
import i18n from 'i18n-js';

const StyledSelect = styled.div`
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: ${gstyles.colors.sub};
      border-color: ${gstyles.colors.sub};
      border-right: 1px solid ${gstyles.colors.white50};
    }
  }
  .rc-virtual-list-holder-inner {
    background-color: ${gstyles.colors.sub};
    .ant-select-item {
      &:hover {
        .ant-select-item-option,
        .ant-select-item-option-selected {
          .ant-select-item-option-content {
            .option-label {
              &:hover {
                color: ${gstyles.colors.sub};
              }
            }
          }
        }
      }

      &.ant-select-item-option-active,
      &.ant-select-item-option-selected {
        .ant-select-item-option-content {
          .option-label {
            color: ${gstyles.colors.sub};
          }
        }
      }
    }
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .option-label {
      font-size: 12px;
    }
  }
  .ant-select-dropdown {
    padding: 0px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${gstyles.colors.white200};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${gstyles.colors.white400};
  }
`;

// const sizeToTextMap = {
//   16: 'normal',
//   22: 'subHeading',
//   28: 'heading',
//   32: 'title',
// };

const createOption = (value) => {
  return {
    value,
    label: (
      <DIV>
        <div className="flex items-center text-white500 option-label" style={{ fontSize: value * 0.75 }}>
          {i18n.t(`Blackboard.FontSize.${value}`)}
        </div>
      </DIV>
    ),
  };
};

const Index = ({ target, canvas, onChange: _onChange, ...rest }) => {
  const ref = React.useRef({});
  _.assign(ref.current, { target });

  const getValue = React.useCallback(() => {
    const type = _.get(ref.current.target, 'type');
    if (['i-text', 'toolbar-text', 'StickyNote'].includes(type)) {
      return ref.current.target.get('fontSize');
    }
  }, [ref.current.target]);

  const applicableTarget = React.useMemo(() => {
    if (_.get(ref.current.target, 'viewOnly')) return false;
    const type = _.get(ref.current.target, 'type');
    if (['i-text', 'toolbar-text', 'StickyNote'].includes(type)) {
      return true;
    }
  }, [ref.current.target]);

  const [value, $value] = React.useState(getValue);

  _.assign(ref.current, { value, $value });

  const onChange = React.useCallback(
    (val) => {
      ref.current.$value && ref.current.$value(val);
      _onChange && _onChange(value);

      const type = _.get(ref.current.target, 'type');
      if (['i-text', 'toolbar-text', 'StickyNote'].includes(type)) {
        ref.current.target.set({
          fontSize: val,
        });
        canvas && canvas.updateObject(ref.current.target);
      }
    },
    [ref.current.target]
  );
  const openProps = useDeferCloseSelect();

  if (!applicableTarget) return null;

  return (
    <StyledSelect>
      <Select
        getPopupContainer={(node) => node}
        size="small"
        dropdownMatchSelectWidth={false}
        className="w-32"
        options={[createOption(16), createOption(22), createOption(28), createOption(32)]}
        suffixIcon={gstyles.icons({ name: 'arrow-down', size: 20, className: '-mt-1', fill: gstyles.colors.white500 })}
        value={value}
        onChange={onChange}
        {...rest}
        {...openProps}
      />
    </StyledSelect>
  );
};

export default displayName(Index);
