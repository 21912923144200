import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import MessageModel from '@uz/unitz-models/MessageModel';
import React from 'react';

import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const messageControlModel = ctx.apply('REF.getRef', 'messageControlModelRef');

              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  message: '',
                  message_jsonb: {},
                  created_by: ctx.apply('authModel.getUserId'),
                  target_type: _.get(messageControlModel, 'target_type', 'course_room'),
                  target_id: _.get(messageControlModel, 'target_id'),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      ...values,
                      message: 'message',
                    };

                    await MessageModel.create(payload);
                    actions.resetForm();
                    form.values.message = '';
                    require('@vl/mod-clients/sound')
                      .default({
                        src: ['message-pop.wav'],
                        volume: 0.7,
                      })
                      .play();
                  } catch (error) {
                    console.log('error', error);
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({}),
              });
              _.assign(form, {
                canSubmit: !!_.get(form.values, 'message.length'),
                getUploaderProps: (fieldName) => {
                  return {
                    name: 'file',
                    multiple: true,
                    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                    onChange(info) {
                      const { status } = info.file;
                      if (status !== 'uploading') {
                        console.log(info.file, info.fileList);
                      }
                      if (status === 'done') {
                        // message.success(`${info.file.name} file uploaded successfully.`);
                        form.setFieldValue(fieldName, info.file.name);
                      } else if (status === 'error') {
                        // message.error(`${info.file.name} file upload failed.`);
                      }
                    },
                    onDrop(e) {
                      console.log('Dropped files', e.dataTransfer.files);
                    },
                  };
                },
                handlers: {
                  onInputKeyPress: (evt) => {
                    if (evt.key === 'Enter' && evt.shiftKey === false) {
                      evt.preventDefault();
                      form.submitForm();
                    }
                  },
                  onMessageChange: (value) => {
                    form.setFieldValue('message', value);
                  },
                },
              });

              // effect for room id change
              React.useEffect(() => {
                form.setFieldValue('target_id', _.get(messageControlModel, 'target_id'));
              }, [_.get(messageControlModel, 'target_id')]);
              React.useEffect(() => {
                const messageInputRef = ctx.apply('REF.getRef', 'messageInputRef');
                const { contents } = messageControlModel;
                if (messageInputRef && contents) {
                  const quill = messageInputRef.current.getQuillRef();
                  console.log(contents);
                  if (quill) {
                    quill.setContents(contents);
                  }
                }
              }, [messageControlModel.fullInput, ctx.apply('REF.getRef', 'messageInputRef')]);
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
