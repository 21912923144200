import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
// import Loadable from '@loadable/component';
import LayoutRoomCall from '@uz/unitz-layout-web/LayoutRoomCall';
import LayoutRoomStream from '@uz/unitz-layout-web/LayoutRoomStream';
import { isSSR } from '@vl/mod-utils/platform';
import OTStreams from '@uz/unitz-components-web/RoomWebComponents/OTLocalStreams';
import OTSubscriber from '@uz/unitz-components-web/RoomWebComponents/OTLocalSubscriber';

function areEqual(prevProps, nextProps) {
  return _.isEqual(prevProps.layout, nextProps.layout);
}

const CallSession = React.memo(({ videoCallModel }) => {
  if (isSSR()) return null;
  return (
    <DIV forceCtx>
      {/* register streams */}
      <OTStreams />
      <LayoutRoomStream.POS name="stream-slides">
        <OTSubscriber
          videoCallModel={videoCallModel}
          className="w-full h-full bg-transparent"
          properties={{
            showControls: false,
            height: '100%',
            width: '100%',
          }}
        />
      </LayoutRoomStream.POS>
    </DIV>
  );
}, areEqual);

const View8 = () => {
  return (
    <DIV className="callSession">
      {ctx.apply('LAYOUT.useLayout', { layout: 'AdvisorVideoCallStatus' }, ({ layout }) => {
        if (!layout) return null;
        const videoCallModel = ctx.get('videoCallModel');

        return <CallSession videoCallModel={videoCallModel} layout={layout} />;
      })}
    </DIV>
  );
};

export default displayName(View8);
