import _ from 'lodash';
import Combokeys from 'combokeys';

export const bindKeyboardEvents = (canvas) => {
  const combokeys = new Combokeys(window);

  combokeys.bind(['del', 'backspace'], () => {
    const activeObject = canvas.getActiveObject();
    // group remove
    if (_.get(activeObject, '_objects')) {
      for (const obj of activeObject._objects) {
        canvas.remove(obj);
      }
    }
    if (activeObject) {
      canvas.remove(activeObject);
    }
    canvas.discardActiveObject();
    return false;
  });

  const wb = document.getElementById('room-call-content-wrapper');
  if (wb) {
    combokeys.bind(['alt', 'command'], (evt, kb) => {
      canvas.toggleDragMode(true);
      canvas.fire('mode:changed', {
        mode: 'drag',
        source: 'drag',
        value: !!canvas.isDragMode,
      });
    }, 'keydown');
    combokeys.bind(['alt', 'command'], (evt, kb) => {
      canvas.toggleDragMode(false);
      canvas.fire('mode:changed', {
        mode: 'drag',
        source: 'drag',
        value: !!canvas.isDragMode,
      });
    }, 'keyup');
  }
};
