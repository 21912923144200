const _ = require('lodash');

exports.createHTMLNode = (htmlStr) => {
  // const div = document.createElement('div');
  // div.innerHTML = htmlStr.trim();

  // // Change this to div.childNodes to support multiple top-level nodes
  // return div.firstChild;
  const template = document.createElement('template');
  template.innerHTML = htmlStr.trim();
  return template.content.firstChild;
};

const getInt = (item, prop) => {
  let rtn = parseInt(_.get(item, prop)) || 0;
  return rtn;
};

const ensureViewport = (viewportEle, targetEle, coords) => {
  const viewportRect = viewportEle && viewportEle.getBoundingClientRect();
  const targetRect = targetEle && targetEle.getBoundingClientRect();
  let leftCoord = _.get(coords, 'x') || _.get(coords, 'left') || 0;
  let topCoord = _.get(coords, 'y') || _.get(coords, 'top') || 0;
  const xRange = [0, getInt(viewportRect, 'width') - getInt(targetRect, 'width')];
  const yRange = [0, getInt(viewportRect, 'height') - getInt(targetRect, 'height')];
  leftCoord = _.clamp(leftCoord, ...xRange);
  topCoord = _.clamp(topCoord, ...yRange);
  return {
    x: leftCoord,
    left: leftCoord,
    y: topCoord,
    top: topCoord,
  };
};

exports.ensureViewport = ensureViewport;
