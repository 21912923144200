import _ from 'lodash';
import { parseUnicode } from '@vl/mod-utils/emoji';

const EmojiBlot = {
  createHTML(value) {
    const unicode = _.get(value, 'unified') || _.get(value, 'unicode');
    const name = _.get(value, 'id') || _.get(value, 'name');
    return `<span class="ap ap-${name}">${parseUnicode(unicode)}</span>`;
  },

  renderCustomWith(customOp) {
    const { value } = customOp.insert;
    if (!value) return '';
    return EmojiBlot.createHTML(value);
  },

  register(registerRender) {
    registerRender(EmojiBlot.blotName, EmojiBlot.renderCustomWith);
  },
};

EmojiBlot.blotName = 'emoji';
EmojiBlot.className = 'ql-emojiblot';
EmojiBlot.tagName = 'span';
EmojiBlot.emojiClass = 'ap';

export default EmojiBlot;
